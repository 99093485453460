import React from "react";
import { useParams } from "react-router";
import ImageCarousel from "../components/ImageCarousel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { itemPropType } from "../utils/sharedPropTypes";
import ToolBar from "../components/ToolBar";
import Description from "../components/ui/Description";
import Equipment from "../components/ui/Equipment";
import Map from "../components/ui/Map";
import Title from "../components/ui/Title";
import Price from "../components/ui/Price";
import PrimaryInfo from "../components/ui/PrimaryInfo";
import EuControl from "../components/ui/EuControl";
import IdentityOwenership from "../components/ui/IdentityOwenership";
import Boat from "../components/ui/spesific/Boat";
import Body from "../components/ui/Body";
import { carSample } from "../services/carSample";
import { boatSample } from "../services/boadSample";
import { PropTypes } from "prop-types";
import Responsive3To2Col from "../components/Responsive3To2Col";
import SellerSelfDeclaration from "../components/ui/SellerSelfDeclaration";

const ShowVehicle = (prop) => {
  const { id } = useParams();
  return (
    <React.Fragment>
      <Container>
        <Grid container direction="column">
          <Grid item style={{ height: "60vh" }}>
            <ImageCarousel
              images={carSample.images}
              imageRollHeight={100}
              imageRollWidth={600}
              imageRollImgMargin={6}
              foregroundImgWidthMargin={96}
              foregroundImgHeightMargin={24}
              maxImagesPerRoll={7}
            />
          </Grid>

          <Grid item>
            <ToolBar
              id={id}
              itemId={carSample._id}
              itemTitle={"Some title! NB Feed proper title later when vehicle i sin place"}
              itemImage={carSample.images[0]}
            />
          </Grid>

          <Title make={carSample.make} model={carSample.model} modelSpecification={carSample.modelSpecification} />
          <Price
            price={carSample.cost.price}
            reRegistrationFee={carSample.cost.reRegistrationFee}
            totalPrice={carSample.cost.totalPrice}
          />
          <PrimaryInfo
            makeYear={carSample.makeYear}
            mileage={carSample.mileage}
            fuelType={carSample.engine.fuelType}
            gearbox={carSample.engine.gearbox}
            gearboxDesignation={carSample.gearboxDesignation}
          />

          <Description description={carSample.description} />

          <Boat
            registrationNumber={boatSample.registrationNumber}
            boatType={boatSample.boatType}
            makeYear={boatSample.makeYear}
            boatBrand={boatSample.boatBrand}
            boatModel={boatSample.boatModel}
            enginIncluded={boatSample.enginIncluded}
            engineType={boatSample.engineType}
            engineBrand={boatSample.engineBrand}
            enginPorwre={boatSample.enginPorwre}
            maxSpeed={boatSample.maxSpeed}
            fuel={boatSample.fuel}
            length={boatSample.length}
            width={boatSample.width}
            depth={boatSample.depth}
            weight={boatSample.weight}
            material={boatSample.material}
            color={boatSample.color}
            numberOfSeats={boatSample.numberOfSeats}
            numberOfBeds={boatSample.numberOfBeds}
            lightNumbers={boatSample.lightNumbers}
            equipment={boatSample.equipment}
          />

          <EuControl euControlLast={carSample.euControlLast} euControlNext={carSample.euControlNext} />

          <IdentityOwenership plateNumber={carSample.plateNumber} vin={carSample.vin} />

          <Body
            bodyType={carSample.body.bodyType}
            taxClass={carSample.body.taxClass}
            seatsNumber={carSample.body.seatsNumber}
            doorNumber={carSample.body.doorNumber}
            trunkVolume={carSample.body.trunkVolume}
            netWeight={carSample.body.netWeight}
            maxTrailerWeight={carSample.body.maxTrailerWeight}
            color={carSample.body.color}
            colorDescription={carSample.body.colorDescription}
            interiorColor={carSample.body.interiorColor}
          />

          <Responsive3To2Col title={"Condition Warranty"} data={carSample.conditionWarranty} />

          <Responsive3To2Col title={"Engine"} data={carSample.engine} />
          <Responsive3To2Col title={"Leasing Info"} data={carSample.leasing} />

          <Equipment equipment={carSample.equipment} />

          <Grid item container spacing={1}></Grid>
        </Grid>
        {carSample.sellerSelfDeclaration ? (
          <SellerSelfDeclaration sellerSelfDeclaration={carSample.sellerSelfDeclaration} />
        ) : null}
        <Map
          lng={carSample.location.coordinates[0]}
          lat={carSample.location.coordinates[1]}
          adress={carSample.contact.address}
          postcode={carSample.contact.postcode}
          city={carSample.contact.city}
          showAdress={true}
        />
      </Container>
    </React.Fragment>
  );
};

export default ShowVehicle;
ShowVehicle.propTypes = {
  vehileType: PropTypes.oneOf(["car", "", ""]),
  item: itemPropType,
};
