export const debounce = (func, wait, immediate) => {
  let timeout;
  let didntGetToRunImmediate = false;
  return (...args) => {
    const later = () => {
      timeout = null;
      if (!immediate || didntGetToRunImmediate) func(...args);
    };
    if (immediate && !timeout) {
      didntGetToRunImmediate = false;
      func(...args);
    } else {
      didntGetToRunImmediate = true;
    }
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
