import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { here_api_key } from "../staticVars";
import makeStyles from "@mui/styles/makeStyles";
import { renderToStaticMarkup } from "react-dom/server";
import RoomIcon from "@mui/icons-material/Room";
const useStyles = makeStyles(() => ({
  mapContainer: {
    borderRadius: 5,
    // border: "5px solid blue",
    width: "100%",
    height: "20rem",
  },
}));
const HMap = ({ lng, lat }) => {
  const classes = useStyles();
  const mapRef = useRef();

  useEffect(() => {
    if (!mapRef.current) return;
    let mapObj, behavior, ui;
    import("@here/maps-api-for-javascript").then(({ default: H }) => {
      const platform = new H.service.Platform({
        apikey: here_api_key,
      });
      const defaultLayers = platform.createDefaultLayers();
      mapObj = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
        pixelRatio: window.devicePixelRatio,
        zoom: 17,
        center: { lng, lat },
      });

      behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(mapObj));
      ui = H.ui.UI.createDefault(mapObj, defaultLayers);

      const html = renderToStaticMarkup(
        <div>
          <RoomIcon style={{ transform: "translate(-50%, -100%)" }} fontSize="large" />
        </div>
      );
      const icon = new H.map.DomIcon(html);
      const marker = new H.map.DomMarker({ lng, lat }, { icon });
      mapObj.addObject(marker);
    });
    return () => {
      mapObj.dispose();
      behavior.dispose();
      ui.dispose();
    };
  }, [mapRef, lng, lat]);

  return <div ref={mapRef} className={classes.mapContainer}></div>;
};
HMap.propTypes = {
  lng: PropTypes.number,
  lat: PropTypes.number,
};

export default HMap;
