import { strIsDateTime } from "../utils/datetime";

const err = (text) => ({ error: true, helperText: text });
const ok = { error: false, helperText: "" };

export const isRequired = () => (value) => {
  if (!value) {
    return err("required");
  }
  return ok;
};
export const isString = () => (value) => {
  if (typeof value === "string") {
    return ok;
  }
  return err("value is not a string");
};

export const isStringBetween = (min, max) => (value) => {
  const isValidString = isString()(value);
  if (isValidString.error) {
    return isValidString;
  }
  if (value.length === undefined) {
    return err("value does not have length");
  }
  if (value.length < min) {
    return err(`text must be atleast ${min} characters`);
  }
  if (value.length > max) {
    return err(`text must be less than ${max} characters`);
  }
  return ok;
};

export const isNumberBetween = (min, max) => (value) => {
  if (value < min) {
    return err(`number must be atleast ${min}`);
  }
  if (value > max) {
    return err(`number must be less than ${max}`);
  }
  return ok;
};

export const isValidDate = () => (value) => {
  if (!strIsDateTime(value)) {
    return err(`${value} is not a valid date`);
  }
  return ok;
};

export const isValidEmail = (value) => {
  if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return err(`${value} is Invalid email`);
  }
  return ok;
};
export const combine = (validators) => (value) => validators.reduce((acc, curr) => ({ ...acc, ...curr(value) }));
