import React, { useMemo } from "react";
import emptyImage from "../../assets/images/emptyImage.svg";
import { ObjectID } from "bson";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useFind_OneShot } from "../../db/hooks";
import { useAuth } from "../../providers/AuthProvider";

export default function FavouriteList({ addFavoriteListName, closeModal }) {
  const { customData } = useAuth();
  const favLists = useMemo(() => customData?.favoriteLists || {}, [customData]);

  const [items] = useFind_OneShot(
    "items",
    useMemo(
      () => ({
        _id: {
          $in: Object.values(favLists)
            .reduce((acc, favList) => acc.concat(favList[0]), [])
            .map((id) => new ObjectID(id)),
        },
      }),
      [favLists]
    )
  );
  return (
    <MenuList>
      {Object.entries(favLists)
        .reverse()
        .map(([listName, listValues]) => {
          const listImage = items.find((it) => it._id.equals(listValues[0]))?.images[0] || emptyImage;
          return (
            listName !== "defaultList" && (
              <MenuItem
                key={listName}
                onClick={(e) => {
                  e.preventDefault();
                  addFavoriteListName(listName);
                  closeModal();
                }}
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <img
                      src={listImage}
                      height={50}
                      width={50}
                      alt="promoImage"
                      style={{ objectFit: "cover", borderRadius: 5 }}
                    />
                  </Grid>
                  <Grid item> {listName}</Grid>
                </Grid>
              </MenuItem>
            )
          );
        })}
    </MenuList>
  );
}
FavouriteList.propTypes = {
  addFavoriteListName: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
