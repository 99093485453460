import React from "react";
import emptyImage from "../assets/images/emptyImage.svg";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Link from "./Link";
import { convertToThumbnailLink } from "../utils/imageTools.js";

export default function ItemCard({ item, width, height }) {
  return (
    <React.Fragment>
      <Link underline="none" color="inherit" to={`/item/${item._id}`}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            //bgcolor: "primary.main",

            borderRadius: 3,
            width: { xs: "7em", sm: "8.5em", md: "10.5em", lg: "12.5em" },
            "&:hover": {
              boxShadow: 3,
            },
          }}
        >
          <Box
            sx={{
              //  flexGrow: 1,
              mt: 0.5,
              width: { xs: "7em", sm: "8em", md: "10em", lg: "12em" },
              height: { xs: "7em", sm: "8em", md: "10em", lg: "12em" },
              overflow: "hidden",
              position: "relative",
              borderRadius: 3,
            }}
          >
            <Box
              component="img"
              sx={{
                // maxHeight: { xs: 233, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
                visibility: "visible",
                overflowX: "hidden",
                overflowY: "hidden",
                height: { xs: "7em", sm: "8em", md: "10em", lg: "12em" },
                width: "100%",
                objectFit: "cover",

                //fontFamily: "system-ui" "-apple-system" "system-ui" ".SFNSText-Regular" "sans-serif",
              }}
              alt="The house from the offer."
              src={convertToThumbnailLink(item.images[0], 160, 160) || emptyImage}
              width={1300}
              height={1300}
            />
          </Box>

          <Box
            sx={{
              color: "common.gray1",
              alignSelf: "flex-start",
              fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
              ml: 1,
              fontWeight: 500,
            }}
          >
            {item.price} &nbsp;&nbsp;kr
          </Box>
          <Box
            sx={{
              fontSize: { xs: ".75rem", sm: "1rem", md: "1.5rem" },
              alignSelf: "flex-start",
              ml: 1,
              textOverflow: "ellipsis",
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "nowrap",
              fontWeight: 400,
            }}
          >
            {item.title.slice(0, 22)}
          </Box>
          <Box
            color="common.gray3"
            sx={{ fontSize: { xs: "0.5rem", sm: "0.75rem", md: "1rem" }, mb: 1, ml: 1, alignSelf: "flex-start" }}
          >
            {item.city}
          </Box>
        </Box>
      </Link>
    </React.Fragment>
  );
}
ItemCard.propTypes = {
  item: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
};
