import React, { useEffect } from "react";
import { useState } from "react";
import { google_api_key } from "../staticVars";

const GeoLocationFromIpContext = React.createContext();
const geoLocateUrl = `https://www.googleapis.com/geolocation/v1/geolocate?key=${google_api_key}`;

const GeoLocationFromIpProvider = (props) => {
  const [geoLocation, setGeoLocation] = useState(null);
  useEffect(() => {
    fetch(geoLocateUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: "{}",
    })
      .then((r) => r.json())
      .then(setGeoLocation);
  }, []);

  return <GeoLocationFromIpContext.Provider value={geoLocation} {...props} />;
};

const useGeoLocationFromIp = () => React.useContext(GeoLocationFromIpContext);
export { GeoLocationFromIpProvider, useGeoLocationFromIp };
