import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function AskToLoginModal({ display, closeModal, message }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog fullScreen={fullScreen} open={display} onClose={closeModal} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{message}</DialogTitle>
      {/* <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent> */}
      <DialogActions>
        <Link color="inherit" component={RouterLink} to="/signin">
          <Button onClick={closeModal}>Login</Button>
        </Link>
        <Button onClick={closeModal}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
AskToLoginModal.propTypes = {
  display: PropTypes.bool,
  closeModal: PropTypes.func,
  message: PropTypes.string,
};
