import { useState, useEffect, useReducer, useCallback, useRef, useMemo } from "react";
import { useAuth } from "../providers/AuthProvider";
import { ObjectId } from "bson";
import { useDb } from "../providers/DbProvider";
import { useSetLoading } from "../providers/LoadingProvider";
import { saveObjToLocalStorage, getObjFromLocalStorage } from "../utils/collectionCache";
import { replaceOrAdd } from "../utils/array";

//helper hooks for working on collections

export const useWatch = (name, expression) => {
  // this does not ,, take in account that documents can be edited, not just inserted, but whatever for now
  const db = useDb();
  const watching = useRef({});
  const [documents, appendFullDocument] = useReducer((existingDocuments, e) => {
    switch (e.operationType) {
      case "reset": {
        return [];
      }
      case "insert": {
        const { fullDocument } = e;
        return replaceOrAdd(existingDocuments, fullDocument);
      }
      case "update": {
        const { fullDocument } = e;
        return replaceOrAdd(existingDocuments, fullDocument);
      }
      case "replace": {
        const { fullDocument } = e;
        return replaceOrAdd(existingDocuments, fullDocument);
      }
      case "delete": {
        // const { documentKey } = e;
        // TODO: this is not implemented yet!!!!!
        break;
      }
      default: {
        throw new Error(`Unknown operation ${e.operationType}`);
      }
    }
  }, []);
  const watchCollection = useCallback(async () => {
    const collection = db.collection(name);
    const key = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    watching.current = { [key]: true };
    for await (const change of collection.watch(expression)) {
      if (!watching.current[key]) break;
      appendFullDocument({ ...change, key });
    }
  }, [db, expression, name]);
  useEffect(() => {
    watchCollection();
    return () => {
      // we need to break the loop above whenunmount/cleanup
      watching.current = {};
      appendFullDocument({ operationType: "reset" });
    };
  }, [watchCollection]);
  return documents;
};

const useDbAction = (name, action, actionName, initialLoading = false) => {
  const db = useDb();
  const { setIsLoading, isLoading } = useSetLoading(actionName, initialLoading);
  const dbAction = useCallback(
    (...expression) => {
      setIsLoading(true);
      return db
        .collection(name)
        [action](...expression)
        .then((r) => {
          //ensure loading is not set to false before consumer has got dbaction return
          setTimeout(() => setIsLoading(false));
          return r;
        })
        .catch((e) => {
          setIsLoading(false);
          throw e;
        });
    },
    [db, name, action, setIsLoading]
  );
  return [dbAction, isLoading];
};

export const useFind_OneShot = (name, expression, actionName) => {
  const [documents, setDocuments] = useState([]);
  const [find, isLoading] = useDbAction(name, "find", actionName, true);
  useEffect(() => find(expression).then(setDocuments), [name, expression, find]);
  return [documents, isLoading];
};

export const useAggregate = (name, actionName) => {
  return useDbAction(name, "aggregate", actionName);
};

export const useCachedAggregate = (name, actionName) => {
  const [aggregate, isLoading] = useAggregate(name, actionName);
  const cachedAggregate = useCallback(
    async (aggregateQuery) => {
      const key = `${name}: ${JSON.stringify(aggregateQuery)}`;
      const storedResult = getObjFromLocalStorage(key);
      const now = new Date().getTime();
      if (storedResult) {
        if (storedResult.savedTime + 300000 < now) {
          localStorage.removeItem(key);
        } else {
          return storedResult.payload;
        }
      }
      const items = await aggregate(aggregateQuery);
      const itemToStore = { savedTime: now, payload: items };
      saveObjToLocalStorage(key, itemToStore);
      return getObjFromLocalStorage(key).payload;
    },
    [aggregate, name]
  );
  return [cachedAggregate, isLoading];
};

export const useFindOne_OneShot = (name, expression, actionName) => {
  const [document, setDocument] = useState(null);
  const [findOne, isLoading] = useDbAction(name, "findOne", actionName, true);
  useEffect(() => findOne(expression).then(setDocument), [name, expression, findOne]);
  return [document, isLoading];
};

export const useUpdateMany = (name, actionName) => useDbAction(name, "updateMany", actionName);
export const useUpdateOne = (name, actionName) => useDbAction(name, "updateOne", actionName);

export const useInsertOne = (name, actionName) => useDbAction(name, "insertOne", actionName);

export const useCollectionFetcher = (name) => {
  const db = useDb();
  const [documents, appendDocuments] = useReducer(
    (existingDocuments, newDocuments) => existingDocuments.concat(newDocuments),
    []
  );
  const fetchItems = useCallback(
    (expression) => {
      db.collection(name).find(expression).asArray().then(appendDocuments);
    },
    [db, name]
  );
  return [documents, fetchItems];
};

export const useUsersFetcher = () => {
  const [users, fetchUsers] = useCollectionFetcher("userprofiles");
  const fetchedIds = useRef([]);
  const fetchUserIds = useCallback(
    (ids) => {
      ids = [...new Set(ids)].filter((id) => !fetchedIds.current.includes(id));
      if (!ids.length) return;
      fetchUsers({
        $or: ids.map((id) => ({ id })),
      });
      fetchedIds.current = fetchedIds.current.concat(ids);
    },
    [fetchUsers]
  );
  return { users, fetchUserIds };
};

export const useGetFavourites = () => {};
export const useFavouriteStatus = () => {};
export const useSetFavourite = () => {};

const findAndSplice = (arr, findQuery) => {
  const idx = arr.findIndex(findQuery);
  return idx !== -1 ? arr.splice(idx, 1) : null;
};
export const useOptimisticDocuments = (collection, actionName, documentQuery) => {
  const [documents, loading] = useFind_OneShot(collection, documentQuery, `${actionName}-useFind_OneShot`);
  const [insertOne] = useInsertOne(collection, `${actionName}-insertOne`);
  const liveDocuments = useWatch(collection, documentQuery);
  const [unsentDocuments, appendUnsent] = useReducer((state, action) => state.concat(action), []);
  const sendDocument = useCallback(
    (document) => {
      const documentWithLocalId = {
        ...document,
        localId: new ObjectId(),
      };
      appendUnsent(documentWithLocalId);
      insertOne(documentWithLocalId);
    },
    [insertOne]
  );
  const allDocuments = useMemo(() => {
    const splicedLiveDocuments = [...liveDocuments];
    return documents
      .reduce(
        (newDocuments, document) =>
          newDocuments.concat(findAndSplice(splicedLiveDocuments, (ld) => ld._id.equals(document._id)) || document),
        []
      )
      .concat(
        unsentDocuments.reduce(
          (newUnsentDocuments, unsentDocument) =>
            newUnsentDocuments.concat(
              findAndSplice(splicedLiveDocuments, (ld) => ld.localId.equals(unsentDocument.localId)) || unsentDocument
            ),
          []
        )
      )
      .concat(splicedLiveDocuments)
      .sort((a, b) => (a._id || a.localId).generationTime - (b._id || b.localId).generationTime);
  }, [documents, liveDocuments, unsentDocuments]);
  return [allDocuments, sendDocument, loading];
};

export const useUpdateUserCustomData = (actionName, mergeUpdate = false) => {
  const { user, refreshCustomData } = useAuth();
  const [updateOne, loading] = useUpdateOne("userProfiles", `updateUserCustomData-updateOne-${actionName}`);
  const updateUserCustomData = (updateQuery) =>
    updateOne(
      { user_id: user.id }, // Query for the user object of the logged in user
      mergeUpdate ? merge(updateQuery) : updateQuery,
      { upsert: true }
    ).then((res) => refreshCustomData());
  // loading is not precise, only measures the update part, no tthe refresh part
  return [updateUserCustomData, loading];
};

const merge = (query) => ({ $set: query });
