import React from "react";

const ModalContext = React.createContext();

const ModalProvider = (props) => {
  const [modalList, doModalAction] = React.useReducer((state, newModalAction) => {
    if (newModalAction.type === "append") {
      return state.concat(newModalAction.payload);
    }
    if (newModalAction.type === "shift") {
      return state.slice(1);
    }
  }, []);

  return (
    <ModalContext.Provider
      value={{
        modalList,
        doModalAction,
      }}
      {...props}
    />
  );
};

const useModalProvider = () => React.useContext(ModalContext);
export { ModalProvider, useModalProvider };
