import { captureException } from "@sentry/react";
export const convertToThumbnailLink = (_url, width, height, operation = "c_fill") => {
  if (!_url || !_url.includes("cloudinary")) {
    return _url;
  }
  try {
    const url = new URL(_url);
    const urlPathParts = url.pathname.split("/");
    const widthStr = width ? `w_${Math.round(width)}` : null;
    const heightStr = height ? `h_${Math.round(height)}` : null;
    urlPathParts.splice(4, 1, [widthStr, heightStr, operation].filter((x) => !!x).join(","));
    url.pathname = urlPathParts.join("/");
    return url.toString();
  } catch (e) {
    captureException(e);
    // eslint-disable-next-line no-console
    console.error(e);
    return _url;
  }
};
