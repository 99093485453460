import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { convertToThumbnailLink } from "../utils/imageTools.js";

const genCarouselKey = (() => {
  let lastActiveIndex = 0;
  let currentActiveIndex = 0;
  let offset = 0;
  const activeIndexDidChange = (activeIndex) => {
    lastActiveIndex = currentActiveIndex;
    currentActiveIndex = activeIndex;
    const changeInterval = activeIndex - lastActiveIndex;
    if (Math.abs(changeInterval) > 1) {
      offset += changeInterval > 0 ? changeInterval - 1 : changeInterval + 1;
    }
  };
  const innerMakeKey = (activeIndex, index) => {
    if (activeIndex !== currentActiveIndex) {
      activeIndexDidChange(activeIndex);
    }
    return index - offset;
  };
  return innerMakeKey;
})();

const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      maxHeight: "100%",
      width: "100%",
      maxWidth: "100%",
      position: "relative",
      overflow: "hidden",
    },
    backdrop: {
      filter: "blur(18px) brightness(30%)",
      objectFit: "fill",
      transition: "all 1s ease-in-out",
    },
  })),
  ImageRollImage = ({ margin, img, onClick, isActive, visible, width }) => {
    const onClickInternal = (e, ...args) => {
      e.target.blur();
      onClick(e, ...args);
    };
    const theme = useTheme();
    return (
      <button
        onClick={onClickInternal}
        style={{
          ...(isActive && { border: `3px solid ${theme.palette.secondary}` }),
          backgroundImage: `url('${img}')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: `calc(100% - ${margin * 2}px)`,
          borderRadius: 10,
          width: visible ? width - margin * 2 : 0,
          transition: "border 0.1s, width 1s",
          // objectFit: "cover",
          marginLeft: visible ? margin : 0,
          marginRight: visible ? margin : 0,
          marginBottom: visible ? margin : 0,
        }}
      />
    );
  };
ImageRollImage.propTypes = {
  margin: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
};
const ImageRoll = ({
  maxImagesPerRoll,
  imageRollImgMargin,
  imageRollWidth,
  images,
  activeIndex,
  imageRollHeight,
  changeIndex,
}) => {
  const half = maxImagesPerRoll / 2;
  const minimum = Math.min(activeIndex - half, images.length - maxImagesPerRoll);
  const maximum = Math.max(activeIndex + half, maxImagesPerRoll);
  const shouldShow = (i) => {
    return minimum <= i && i < maximum;
  };
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translate(-50%, 0%)",
        height: imageRollHeight,
        zIndex: 1000,
        width: imageRollWidth,
      }}
    >
      {images.map((img, i) => (
        <ImageRollImage
          margin={imageRollImgMargin}
          key={img}
          img={convertToThumbnailLink(img, imageRollWidth / images.length, imageRollHeight)}
          onClick={() => changeIndex({ action: "absolute", value: i })}
          width={imageRollWidth / maxImagesPerRoll}
          visible={shouldShow(i)}
          isActive={i === activeIndex}
        />
      ))}
    </div>
  );
};
ImageRoll.propTypes = {
  maxImagesPerRoll: PropTypes.number.isRequired,
  imageRollImgMargin: PropTypes.number.isRequired,
  imageRollWidth: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeIndex: PropTypes.number.isRequired,
  imageRollHeight: PropTypes.number.isRequired,
  changeIndex: PropTypes.func.isRequired,
};
const ImageMain = ({ foregroundImgWidthMargin, foregroundImgHeightMargin, images, activeIndex, imageRollHeight }) => {
  const imgToTheLeft = images[activeIndex - 1];
  const imgInTheCenter = images[activeIndex];
  const imgToTheRight = images[activeIndex + 1];
  const classes = useStyles();
  return (
    <>
      {imgToTheLeft && (
        <img
          key={genCarouselKey(activeIndex, activeIndex - 1)}
          src={imgToTheLeft}
          alt="imgToTheLeft"
          style={{
            top: `calc(50% - ${imageRollHeight / 2}px)`,
            height: `calc(100% - ${imageRollHeight + foregroundImgHeightMargin}px)`,
            position: "absolute",
            width: `calc(100% - ${foregroundImgWidthMargin}px)`,
            objectFit: "contain",
            zIndex: 1,
            left: "-50%",
            transform: "translate(-50%, -50%)",
            transition: "all 1s",
          }}
        />
      )}
      {imgInTheCenter && (
        <img
          key={genCarouselKey(activeIndex, activeIndex)}
          src={imgInTheCenter}
          alt="imgInTheCenter"
          style={{
            top: `calc(50% - ${imageRollHeight / 2}px)`,
            height: `calc(100% - ${imageRollHeight + foregroundImgHeightMargin}px)`,
            position: "absolute",
            width: `calc(100% - ${foregroundImgWidthMargin}px)`,
            objectFit: "contain",
            zIndex: 1,
            left: "50%",
            transform: "translate(-50%, -50%)",
            transition: "all 1s",
          }}
        />
      )}
      {imgToTheRight && (
        <img
          key={genCarouselKey(activeIndex, activeIndex + 1)}
          src={imgToTheRight}
          alt="imgToTheRight"
          style={{
            top: `calc(50% - ${imageRollHeight / 2}px)`,
            height: `calc(100% - ${imageRollHeight + foregroundImgHeightMargin}px)`,
            position: "absolute",
            width: `calc(100% - ${foregroundImgWidthMargin}px)`,
            objectFit: "contain",
            zIndex: 1,
            left: "150%",
            transform: "translate(-50%, -50%)",
            transition: "all 1s",
          }}
        />
      )}
      {imgToTheLeft && (
        <img
          key={`bg-${genCarouselKey(activeIndex, activeIndex - 1)}`}
          src={imgToTheLeft}
          alt="pic1"
          className={classes.backdrop}
          style={{
            position: "absolute",
            left: 0,
            opacity: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}
      {imgInTheCenter && (
        <img
          key={`bg-${genCarouselKey(activeIndex, activeIndex)}`}
          src={imgInTheCenter}
          className={classes.backdrop}
          alt="pic1"
          style={{
            position: "absolute",
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}
      {imgToTheRight && (
        <img
          key={`bg-${genCarouselKey(activeIndex, activeIndex + 1)}`}
          src={imgToTheRight}
          alt="pic1"
          className={classes.backdrop}
          style={{
            position: "absolute",
            left: 0,
            opacity: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}
    </>
  );
};
ImageMain.propTypes = {
  foregroundImgWidthMargin: PropTypes.number.isRequired,
  foregroundImgHeightMargin: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeIndex: PropTypes.number.isRequired,
  imageRollHeight: PropTypes.number.isRequired,
};
const ImageCarousel = ({
  images,
  imageRollHeight,
  imageRollWidth,
  imageRollImgMargin,
  foregroundImgWidthMargin,
  foregroundImgHeightMargin,
  maxImagesPerRoll,
}) => {
  const classes = useStyles();
  const maxIndex = images.length - 1;
  maxImagesPerRoll = Math.min(maxImagesPerRoll, images.length);
  const [activeIndex, changeIndex] = useReducer((currentIndex, action) => {
    if (action.action === "modify") {
      const modification = action.value;
      currentIndex += modification;
      if (currentIndex < 0) currentIndex = 0;
      if (currentIndex > maxIndex) currentIndex = maxIndex;
    } else if (action.action === "absolute") {
      currentIndex = action.value;
    }
    return currentIndex;
  }, 0);
  if (images.length < 2) {
    return (
      <div className={classes.root}>
        <ImageMain
          foregroundImgWidthMargin={foregroundImgWidthMargin}
          foregroundImgHeightMargin={foregroundImgHeightMargin}
          images={images}
          activeIndex={activeIndex}
          imageRollHeight={0}
        />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <IconButton
        style={{
          position: "absolute",
          top: "50%",
          left: 5,
          zIndex: 1000,
          transform: "translate(0%, -50%)",
          backgroundColor: "white",
          opacity: 0.5,
        }}
        disabled={!images[activeIndex - 1]}
        color="secondary"
        onClick={() => changeIndex({ action: "modify", value: -1 })}
        size="large"
      >
        <ArrowBackIcon />
      </IconButton>
      <ImageMain
        foregroundImgWidthMargin={foregroundImgWidthMargin}
        foregroundImgHeightMargin={foregroundImgHeightMargin}
        images={images}
        activeIndex={activeIndex}
        imageRollHeight={imageRollHeight}
      />
      <ImageRoll
        maxImagesPerRoll={maxImagesPerRoll}
        imageRollImgMargin={imageRollImgMargin}
        images={images}
        activeIndex={activeIndex}
        imageRollHeight={imageRollHeight}
        changeIndex={changeIndex}
        imageRollWidth={imageRollWidth}
      />

      <IconButton
        style={{
          position: "absolute",
          top: "50%",
          right: 5,
          zIndex: 1000,
          transform: "translate(0%, -50%)",
          backgroundColor: "white",
          opacity: 0.5,
        }}
        color="secondary"
        disabled={!images[activeIndex + 1]}
        onClick={() => changeIndex({ action: "modify", value: 1 })}
        size="large"
      >
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
};
ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  imageRollHeight: PropTypes.number.isRequired,
  imageRollWidth: PropTypes.number.isRequired,
  imageRollImgMargin: PropTypes.number.isRequired,
  foregroundImgWidthMargin: PropTypes.number.isRequired,
  foregroundImgHeightMargin: PropTypes.number.isRequired,
  maxImagesPerRoll: PropTypes.number.isRequired,
};
export default ImageCarousel;
