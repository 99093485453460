import React from "react";
import withStyles from "@mui/styles/withStyles";
import Like from "./common/Like";
import Grid from "@mui/material/Grid";
import ShareIcon from "@mui/icons-material/Share";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MailTo from "./common/MailTo";
import FacebookShareButton from "./common/FacebookShareButton";
import TwitterShareButton from "./common/TwitterShareButton";
import PinterestShareButton from "./common/PinterestShareButton";
import MoreDotsItem from "./common/MoreDotsItem";
import PropTypes from "prop-types";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: theme.palette.common.white,
  },
}))(Tooltip);
const ToolBar = ({ id, itemTitle, itemImage }) => {
  return (
    <Grid container item justifyContent="flex-end">
      <Grid item>
        <Like itemId={id} promoImageSrc={itemImage} />
      </Grid>
      <Grid item>
        <LightTooltip title="Share" arrow>
          <IconButton size="large">
            <ShareIcon />
          </IconButton>
        </LightTooltip>
      </Grid>
      <Grid item>
        <IconButton size="large">
          <BookmarkIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <MailTo itemID={id} itemTitle={itemTitle} />
      </Grid>
      <Grid item>
        <FacebookShareButton itemID={id} />
      </Grid>
      <Grid item>
        <TwitterShareButton itemID={id} />
      </Grid>
      <Grid item>
        <PinterestShareButton itemID={id} />
      </Grid>

      <Grid item>
        <MoreDotsItem itemID={id} />
      </Grid>
    </Grid>
  );
};
export default ToolBar;
ToolBar.propTypes = {
  id: PropTypes.string.isRequired, //NB <- Make this required
  itemTitle: PropTypes.string.isRequired, ////NB <- Make this required
  itemImage: PropTypes.string.isRequired, //NB <- Make this required
};
