import React from "react";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const LoadingFullscreen = ({ children }) => {
  return <BorderLinearProgress>{children}</BorderLinearProgress>;
};
LoadingFullscreen.propTypes = { children: PropTypes.node };

export default LoadingFullscreen;
