import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router";
import ImageCarousel from "../components/ImageCarousel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Typography from "@mui/material/Typography";
import Bid from "../components/Auction";
import { itemPropType } from "../utils/sharedPropTypes";
import ToolBar from "../components/ToolBar";
import Map from "../components/ui/Map";
const Item = (prop) => {
  const { id } = useParams();
  const theme = useTheme();
  return (
    <React.Fragment>
      <Container>
        <Grid container direction="column">
          <Grid item style={{ height: "60vh" }}>
            <ImageCarousel
              images={prop.item.images}
              imageRollHeight={100}
              imageRollWidth={600}
              imageRollImgMargin={6}
              foregroundImgWidthMargin={96}
              foregroundImgHeightMargin={24}
              maxImagesPerRoll={7}
            />
          </Grid>
          <ToolBar id={id} itemTitle={prop.item.title} itemImage={prop.item.images[0]} />
          <div>
            {prop.item?.user_id === prop.user.id ? (
              <Button component={Link} variant="contained" to={`/edit-item/${prop.item?._id}`}>
                Edit item
              </Button>
            ) : (
              <Button component={Link} variant="contained" to={`/chat/${prop.item?.user_id}/${prop.item?.username}`}>
                Chat with seller
              </Button>
            )}
          </div>
          <Grid item>
            <Typography variant="h6" style={{ color: theme.palette.common.gray2, fontWeight: 500 }}>
              {prop.item.title}
            </Typography>{" "}
          </Grid>
          {prop.item.auctionEnds ? (
            <Grid item>
              <Bid item={prop.item} />
            </Grid>
          ) : (
            <Grid item>
              <Typography variant="h6" style={{ color: theme.palette.common.gray2, fontWeight: 500 }}>
                Kr {prop.item.price}
              </Typography>{" "}
            </Grid>
          )}
          <Grid item>
            <Typography variant="subtitle1" gutterBottom>
              {prop.item.description}
            </Typography>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item>
              <LocationOnIcon style={{ color: "red" }} />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" gutterBottom>
                {prop.item.addressLabel}
              </Typography>
            </Grid>
          </Grid>

          <Map lng={prop.item.location.coordinates[0]} lat={prop.item.location.coordinates[1]} />
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Item;
Item.propTypes = {
  item: itemPropType,
};
