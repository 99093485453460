const categories = {
  Vehicles: ["Boats", "Cars", "Lorries", "Motorcycles", "Motorhomes/ Camper vans", "Powersports", "Trailers"],
  "Property for rent": ["Apartments for rent", "Flats for rent", "Houses for rent", "Townhouses for rent"],
  Classifieds: ["Garage sale", "Miscellaneous"],
  Clothing: [
    "Baby clothing",
    "Bags & luggage",
    "Halloween costumes",
    "Jewellery & accessories",
    "Kids' clothing",
    "Men's",
    "Shoes",
    "Uniforms and work clothing",
    "Women's",
  ],
  Electronics: [
    "Cameras",
    "Car electronics",
    "Computers",
    "Headphones",
    "Home audio and video systems",
    "Home automation systems",
    "Mobile phone accessories",
    "Mobile phones",
    "Portable audio & video",
    "Printers, scanners & fax machines",
    "Security cameras",
    "TVs",
    "Video games &consoles",
  ],
  Entertainment: ["Books, films & music"],
  Family: ["Baby & children's items", "Health & beauty", "Pet supplies", "Toys & games"],
  "Free stuff": [],
  "Garden and outdoors": [
    "Bird & wildlife accessories",
    "Fencing supplies",
    "Garden decor",
    "Garden structures",
    "Gardening supplies",
    "Lawn mowers",
    "Outdoor cooking",
    "Outdoor lighting",
    "Outdoor power equipment",
    "Patio furniture",
    "Pools & spas Power tools",
    "Water features",
  ],
  Hobbies: [
    "Antiques & collectables",
    "Arts & crafts",
    "Bicycles",
    "Car parts",
    "Fishing gear",
    "Musical instruments",
    "Outdoor recreation equipment",
    "Sporting goods",
    "Toys & games",
  ],
  "Home goods": [
    "Appliances",
    "Bath products",
    "Bedding",
    "Cleaning supplies",
    "Furniture",
    "Garden and outdoors",
    "Home decor",
    "Home improvement supplies",
    "Home lighting",
    "Kitchen and dining products",
    "Patio furniture",
    "Storage &organisation",
    "Tools",
  ],
  "Home improvement supplies": [
    "Bathroom dressing tables",
    "Baths",
    "Countertops",
    "Doors",
    "Gutters",
    "Home heating and cooling",
    "Kitchen cabinets",
    "Letter boxes",
    "Showers & shower parts",
    "Tools",
    "Vinyl flooring",
  ],
  "Musical instruments": [
    "Audio equipment",
    "Brass instruments Drum sets",
    "Guitars and basses",
    "Percussion instruments",
    "Pianos and keyboards",
    "Stringed instruments",
    "Wind instruments",
  ],
  "Office supplies": ["Bubble wrap", "Clipboards", "Desk organisers", "Office furniture", "Whiteboards"],
  "Pet supplies": [
    "Bird supplies",
    "Cat supplies",
    "Chicken supplies",
    "Dog supplies",
    "Fish supplies",
    "Pet crates",
    "Pet grooming supplies",
    "Pet kennels",
    "Reptile supplies",
  ],
  "Property for sale": [
    "Sporting goods",
    "Bicycles",
    "Exercise and fitness equipment",
    "Indoor games",
    "Kayaks",
    "Outdoor games",
    "Outdoor recreation equipment",
    "Sports equipment",
  ],
  "Toys & games": [
    "Action figures",
    "Building toys",
    "Doll's houses",
    "Dolls Educational toys",
    "Games",
    "Maths toys",
    "Model kits",
    "Outdoor toys",
    "Pretend play toys",
    "Puzzles",
    "Remote control toys",
    "Robots",
    "Stuffed toys",
    "Toy vehicles",
  ],
  Sport: ["Winter"],
};
export default categories;
