import { ErrorBoundary } from "@sentry/react";
import ErrorView from "../views/ErrorView";
import React from "react";
import PropTypes from "prop-types";

const ErrorBoundaryWrapped = ({ description, children }) => (
  <ErrorBoundary fallback={({ error }) => <ErrorView description={description} error={error} />}>
    {children}
  </ErrorBoundary>
);
ErrorBoundaryWrapped.propTypes = {
  description: PropTypes.string,
  children: PropTypes.node,
};
export default ErrorBoundaryWrapped;
