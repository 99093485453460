import React from "react";
import AsyncButton from "../components/AsyncButton";
import TextField from "@mui/material/TextField";
import { Update } from "@mui/icons-material";
import { isStringBetween } from "../form/validators";
import { useUpdateUserCustomData } from "../db/hooks";
import { useAuth } from "../providers/AuthProvider";
import Grid from "@mui/material/Grid";
import useForm, { formPropsToValues, initialInputValue } from "../form/useForm";
import PropTypes from "prop-types";
import { customDataPropType } from "../utils/sharedPropTypes";
import { useModal } from "../utils/modalHooks";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DangerousIcon from "@mui/icons-material/Dangerous";

const UserProfileContainer = ({ hideDelete, onResolve, onReject }) => {
  const { showError } = useModal();
  const { user, logout, customData } = useAuth();
  const [updateCustomData, isUpdating] = useUpdateUserCustomData("UserProfileContainer", true);
  const deleteUser = () =>
    user.functions
      .deleteUser()
      .then(() => logout())
      .catch((error) => showError("deleteUser", error));
  return (
    <UserProfile
      customData={customData}
      deleteUser={deleteUser}
      updateCustomData={updateCustomData}
      isUpdating={isUpdating}
      onReject={onReject}
      onResolve={onResolve}
      hideDelete={hideDelete}
    />
  );
};
UserProfileContainer.propTypes = {
  hideDelete: PropTypes.bool,
  onResolve: PropTypes.func,
  onReject: PropTypes.func,
};

const UserProfile = ({ hideDelete, customData, deleteUser, updateCustomData, isUpdating, onResolve, onReject }) => {
  const [validateFormPure, validateFormStateful, formProps] = useForm(
    {
      username: initialInputValue(customData?.username || ""),
    },
    {
      username: { onBlur: isStringBetween(2, 100) },
    }
  );
  const formValues = formPropsToValues(formProps);
  const formValidation = validateFormPure();
  const handleSubmit = (e) => {
    e.preventDefault();
    updateCustomData(formValues).then(onResolve).catch(onReject);
  };

  return (
    <React.Fragment>
      <Container>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container>
            <Grid item>
              <Typography variant="h4">User Profile</Typography>
            </Grid>
            <Grid item container>
              <Grid item>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="username"
                  color="secondary"
                  {...formProps.username}
                />
              </Grid>
            </Grid>
            <Grid item>
              <AsyncButton
                disabled={formValidation.error || isUpdating}
                tooltipTitle={formValidation.msg}
                onClickWhileDisabled={validateFormStateful}
                loading={isUpdating}
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<Update />}
                fullWidth
                sx={{ color: "blue", mt: 5 }}
              >
                Update
              </AsyncButton>
            </Grid>
          </Grid>
          <Grid item>
            {!hideDelete && (
              <Button
                variant="contained"
                onClick={deleteUser}
                size="large"
                sx={{
                  color: "white",
                  bgcolor: "red",
                  mt: 10,
                  "&:hover": {
                    bgcolor: "white",
                    color: "red",
                    cursor: "pointer",
                  },
                }}
                startIcon={<DangerousIcon />}
              >
                Delete Account
              </Button>
            )}
          </Grid>
        </form>
      </Container>
    </React.Fragment>
  );
};
UserProfile.propTypes = {
  hideDelete: PropTypes.bool,
  onResolve: PropTypes.func,
  onReject: PropTypes.func,
  customData: customDataPropType,
  deleteUser: PropTypes.func,
  updateCustomData: PropTypes.func,
  isUpdating: PropTypes.bool,
};

export default UserProfileContainer;
