import React, { useMemo } from "react";
import { useAuth } from "../providers/AuthProvider";
import { useFind_OneShot } from "../db/hooks";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import MyItemTile from "../components/MyItemTile";

const useStyles = makeStyles((theme) => ({
  paperItem: {
    margin: 10,
    padding: 10,
    width: 400,
    height: 550,
    "& p": {
      display: "-webkit-box",
      overflow: "hidden",
      boxOrient: "vertical",
      lineClamp: 2,
      textOverflow: "ellipsis",
    },
  },
  fullHeight: {
    height: "100%",
  },
  itemContainer: {
    overflow: "auto",
  },
  searchContainer: {
    padding: 15,
  },
}));

const MyItems = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const [items, isLoading] = useFind_OneShot(
    "items",
    useMemo(
      () => ({
        user_id: user.id,
      }),
      [user.id]
    ),
    "MyItems-useFind_OneShot"
  );

  return (
    <Grid item container justifyContent="center" className={classes.itemContainer} style={{ height: "100%" }}>
      {!isLoading &&
        items.map((myItem) => (
          <Grid style={{ margin: 16 }} item key={myItem._id}>
            <MyItemTile item={myItem} width={223} height={280} />
          </Grid>
        ))}
    </Grid>
  );
};

export default MyItems;
