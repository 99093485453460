import { useState } from "react";
import { strIsDateTime } from "../utils/datetime";
import startCase from "lodash/startCase";

const useFormB = (initialData, validatorsObj, onSubmit) => {
  const [formProps, setFormData] = useState(initialData);

  const onChange = (e) => {
    const { type } = e.target;

    const getValue = () => {
      if (type === "checkbox") {
        return e.target.checked;
      } else if (type === "select-multiple") {
        return Array.from(e.target.selectedOptions).map((o) => o.value);
      }
      return e.target.value;
    };
    const value = getValue();

    setFormData({
      ...formProps,
      [e.target.name]: {
        ...formProps[e.target.name],
        value,
        error: false,
        helperText: "",
      },
    });
  };

  const validateForm = () => {
    let isValid = true;
    for (const [inputName] of Object.entries(validatorsObj)) {
      for (const validator in validatorsObj[inputName]) {
        const validation = validatorsObj[inputName][validator](formProps[inputName].value);
        if (validation.error) {
          setFormData({
            ...formProps,
            [inputName]: {
              ...formProps[inputName],
              error: validation.error,
              helperText: validation.helperText,
            },
          });
          isValid = false;
          break;
        }
      }
      return isValid;
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   //    onSubmit?.(formData);
  // };

  const handelServerError = (field, error) => {
    const errorMessage = startCase(error.errorCode);
    setFormData({
      ...formProps,
      [field]: {
        ...formProps[field],
        error: true,
        helperText: errorMessage,
      },
    });
  };
  return [
    onChange,
    validateForm,
    handelServerError,
    Object.entries(formProps).reduce(
      (obj, [key, value]) => ({
        ...obj,
        [key]: {
          ...value,
          id: key,
          name: key,
        },
      }),
      {}
    ),
  ];
};

export const formPropsToValues = (formProps) =>
  Object.entries(formProps).reduce(
    (obj, [key, props]) => ({ ...obj, [key]: props.type === "number" ? parseFloat(props.value) : props.value }),
    {}
  );
export default useFormB;

export const initiateInputValue = (value) => ({ value, error: false, helperText: "", type: valueToInputType(value) });

const valueToInputType = (value) => {
  if (typeof value === "number") {
    return "number";
  }
  if (strIsDateTime(value)) {
    return "datetime-local";
  }
  return "text";
};
