import React from "react";
import Grid from "@mui/material/Grid";
import { vehiclePricePropType } from "../../utils/sharedPropTypes";
import Typography from "@mui/material/Typography";

const Price = ({ price, reRegistrationFee, totalPrice }) => {
  return (
    <React.Fragment>
      <Grid container direction="column">
        <Grid container>
          <Grid item>
            <Typography variant="h5" style={{ fontWeight: 500 }} color="primary">
              {totalPrice}(Total Price)
            </Typography>
          </Grid>
          <Grid item>{reRegistrationFee}(Registration fee)</Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Price;
Price.propTypes = {
  ...vehiclePricePropType,
};
