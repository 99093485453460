import React, { useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PropTypes from "prop-types";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import { useAuth } from "../../providers/AuthProvider";
import AskToLoginModal from "../modals/AskToLoginModal";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddToCollectionModal from "../modals/AddToCollectionModal";
import { useUpdateUserCustomData } from "../../db/hooks";
import { useModal } from "../../utils/modalHooks";

const useStyles = makeStyles(() => ({
  liked: { fill: "red" },
}));
const Like = ({ itemId }) => {
  const { showError } = useModal();
  const [updateUserCustomData] = useUpdateUserCustomData("Like");
  const { user, isLoggedIn, customData } = useAuth();
  const isLiked = Object.values(customData?.favoriteLists || []).some((flist) => flist.includes(itemId));
  const [liked, setLiked] = useState(isLiked);
  const classes = useStyles();

  const [displayLoginModal, setDisplayLoginModal] = useState(false);
  const [displayAddToCollectionModal, setDisplayAddToCollectionModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const idButton = open ? "add-or-remove-from-favorites" : undefined;

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      setAnchorEl(null);
    }, 3000);
  };

  const likedBy = () => user.functions.likedBy(itemId).catch((error) => showError("likedBy", error));

  const addFavoriteListName = (newListName) =>
    updateUserCustomData({ $addToSet: { [`favoriteLists.${newListName}`]: itemId } });
  const removeItemFromFavourites = () =>
    updateUserCustomData({
      $pull: (Object.keys(customData?.favoriteLists) || []).reduce((acc, curr) => ({
        ...acc,
        [`favoriteLists.${curr}`]: itemId,
      })),
    });
  const toggleLike = (event) => {
    event.preventDefault();
    if (isLoggedIn) {
      if (liked === false) {
        setLiked(true);
        likedBy();
        openPopover(event);
        addFavoriteListName("defaultList");
      } else {
        setLiked(false);
        removeItemFromFavourites();
      }
    } else setDisplayLoginModal(true);
  };
  const closeLoginModal = () => setDisplayLoginModal(false);
  const closeAddToCollectionModal = () => setDisplayAddToCollectionModal(false);
  return (
    <>
      <AskToLoginModal
        display={displayLoginModal}
        closeModal={closeLoginModal}
        message={"Please logg in to add item to your favourite lists"}
      />
      <AddToCollectionModal
        display={displayAddToCollectionModal}
        closeModal={closeAddToCollectionModal}
        addFavoriteListName={addFavoriteListName}
      />
      <Tooltip title={liked ? "Remove from favorites" : "Add to favorites"} arrow>
        <IconButton
          disableRipple
          aria-label={"Add to favorites"}
          aria-describedby={idButton}
          onClick={toggleLike}
          size="large"
        >
          {liked ? <FavoriteIcon className={classes.liked} /> : <FavoriteBorderIcon />}
        </IconButton>
      </Tooltip>
      <Popover
        id={idButton}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <Typography className={classes.typography}>Saved</Typography>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              startIcon={<AddIcon />}
              style={{ backgroundColor: "transparent" }}
              onClick={(event) => {
                event.preventDefault();
                setDisplayAddToCollectionModal(true);
              }}
            >
              {"Save to collection"}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};
Like.propTypes = {
  itemId: PropTypes.string,
};

export default Like;
