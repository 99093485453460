import React from "react";
import Grid from "@mui/material/Grid";
import { PropTypes } from "prop-types";
import Typography from "@mui/material/Typography";

const SellerSelfDeclaration = ({ sellerSelfDeclaration }) => {
  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="row"
        justifyContent="left"
        alignItems="center"
        spacing={5}
        style={{ marginTop: 0 }}
      >
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="h4" style={{ fontWeight: 700 }} color="primary">
              Seller self decleration
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
              Is there a defect in the car? A defect patch shows faults and defects that must be rectified for the car
              to be EU-approved.
            </Typography>
          </Grid>
          <Grid item>{sellerSelfDeclaration.hasDefect}</Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
              Is the car used in business? For example, as a taxi, emergency vehicle, courier or rental car.
            </Typography>
          </Grid>
          <Grid item>{sellerSelfDeclaration.usedInBuisniess}</Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
              Is or has the cars engine been trimmed? For example, chip trimming or another form of electronic change in
              engine performance.
            </Typography>
          </Grid>
          <Grid item>{sellerSelfDeclaration.engineTrimmed}</Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
              Has the car been involved in a major collision? By major collision is meant damage that has occurred in
              traffic - not parking damage, scratches and scuffs.
            </Typography>
          </Grid>
          <Grid item>{sellerSelfDeclaration.majorCollision}</Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default SellerSelfDeclaration;

SellerSelfDeclaration.propTypes = {
  sellerSelfDeclaration: PropTypes.shape({
    hasDefect: PropTypes.oneOf(["yes", "no", "dontKnow"]),
    usedInBuisniess: PropTypes.oneOf(["yes", "no", "dontKnow"]),
    engineTrimmed: PropTypes.oneOf(["yes", "no", "dontKnow"]),
    majorCollision: PropTypes.oneOf(["yes", "no", "dontKnow"]),
  }),
};
