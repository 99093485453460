import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { routeToTabsValue, getRouteValue, getRouteType } from "../utils/navigations";
import CloseIcon from "@mui/icons-material/Close";

const NavbarBottom = ({ routes }) => {
  const [activeSubLinks, setActiveSubLinks] = useState(null);
  const location = useLocation();

  const tabsValue = useMemo(() => routeToTabsValue(location.pathname, routes), [location.pathname, routes]);
  useEffect(() => setActiveSubLinks(null), [location.pathname]);
  const backObj = React.useMemo(
    () => ({
      name: "back",
      icon: <CloseIcon />,
      route: () => setActiveSubLinks(null),
    }),
    []
  );

  const renderBottomNavigation = (routeObj) => {
    const routeValue = getRouteValue(routeObj);
    const interactiveProps = {
      subRoutes: { onClick: () => setActiveSubLinks(routeValue) },
      link: {
        component: Link,
        to: routeObj.route,
      },
      onClick: { onClick: routeObj.route },
    }[getRouteType(routeObj)];
    return (
      <BottomNavigationAction
        key={routeValue}
        value={routeValue}
        showLabel
        label={routeObj.name}
        icon={routeObj.icon}
        {...interactiveProps}
      />
    );
  };

  return (
    <BottomNavigation value={tabsValue} sx={{ overflow: "scroll", justifyContent: "start" }}>
      {(activeSubLinks &&
        routes
          .find((r) => getRouteValue(r) === activeSubLinks)
          ?.route.map(renderBottomNavigation)
          .concat(renderBottomNavigation(backObj))) ||
        routes.map(renderBottomNavigation)}
    </BottomNavigation>
  );
};
NavbarBottom.propTypes = {
  routes: PropTypes.array,
};
export default NavbarBottom;
