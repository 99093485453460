import Modal from "@mui/material/Modal";
import { PropTypes } from "prop-types";
import Paper from "@mui/material/Paper";
import { createModal } from "react-modal-promise";
import React from "react";
import UserProfile from "../../views/UserProfile";

const FillRequiredProfileData = ({ isOpen, onResolve, onReject }) => {
  return (
    <Modal open={isOpen} onClose={() => onReject("closed modal")}>
      <Paper>
        <UserProfile onResolve={onResolve} onReject={onReject} hideDelete />
      </Paper>
    </Modal>
  );
};
FillRequiredProfileData.propTypes = {
  isOpen: PropTypes.bool,
  onResolve: PropTypes.func,
  onReject: PropTypes.func,
};

export default createModal(FillRequiredProfileData);
// <Modal
//   open={open}
//   onClose={handleClose}
//   aria-labelledby="simple-modal-title"
//   aria-describedby="simple-modal-description"
// >
//   {body}
// </Modal>
