import React from "react";
import Grid from "@mui/material/Grid";
import { PropTypes } from "prop-types";
import Typography from "@mui/material/Typography";

const IdentityOwenership = ({ plateNumber, vin }) => {
  return (
    <Grid item container direction="row" justifyContent="left" alignItems="center" spacing={3}>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
            Plate Number
          </Typography>
        </Grid>
        <Grid item>{plateNumber}</Grid>
      </Grid>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
            Chasiss NUmber (VIN){" "}
          </Typography>
        </Grid>
        <Grid item>{vin}</Grid>
      </Grid>
    </Grid>
  );
};
export default IdentityOwenership;

IdentityOwenership.propTypes = {
  plateNumber: PropTypes.string, //Registreringsnummer-v
  plateNumberPersonal: PropTypes.string, //Registreringsnummer-v
  vin: PropTypes.string, // Vehicle identification number
};
