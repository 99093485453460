import React from "react";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Link from "../Link";
import PropTypes from "prop-types";
//import { ObjectID } from "bson";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      // backgroundColor: theme.palette.primary,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.blue,
      },
    },
  },
}))(MenuItem);

export default function MoreDotsItem({ itemID }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="more" aria-controls="more-menu" aria-haspopup="true" onClick={handleClick} size="large">
        <MoreVertIcon />
      </IconButton>

      <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <StyledMenuItem>
          <ListItemIcon>
            <ReportProblemRoundedIcon fontSize="large" />
          </ListItemIcon>
          <Link to={`/reportad/${itemID}`}>
            <ListItemText primary="Report ad!" />
          </Link>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
}
MoreDotsItem.propTypes = {
  itemID: PropTypes.string.isRequired,
};
