import React from "react";
import Grid from "@mui/material/Grid";
import { PropTypes } from "prop-types";
import Typography from "@mui/material/Typography";

const Equipment = ({ equipment }) => {
  return (
    <React.Fragment>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h5" style={{ fontWeight: 500 }} color="primary">
            Equipments
          </Typography>
        </Grid>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {equipment.map((key, i) => (
            <Grid item xs={2} sm={4} md={4} key={i}>
              {key}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Equipment;
Equipment.propTypes = {
  equipment: PropTypes.arrayOf(PropTypes.string),
};
