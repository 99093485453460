import React from "react";
import DateAdapter from "@mui/lab/AdapterDayjs";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";

const DatePickerWrapped = (props) => {
  // TODO: implement the mobile version also, detect here if mobile
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DesktopDatePicker renderInput={(params) => <TextField {...params} />} {...props} />
    </LocalizationProvider>
  );
};
export default DatePickerWrapped;
