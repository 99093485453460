import React from "react";
import * as Realm from "realm-web";
const REALM_APP_ID = "realm-app-dev-bugtp";

const AppContext = React.createContext();
const app = new Realm.App({ id: REALM_APP_ID });

const AppProvider = (props) => {
  return <AppContext.Provider value={app} {...props} />;
};

const useApp = () => React.useContext(AppContext);

export { AppProvider, useApp };
