import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
//import { ObjectID } from "bson";

const MailTo = ({ itemID, itemTitle }) => {
  return (
    <a
      href={
        "mailto:test@example.com?" +
        "subject= Check item : " +
        itemTitle +
        "&body=Hi, look at this item on " +
        "http://localhost:3006/item/" +
        itemID +
        "\n"
      }
    >
      <IconButton size="large">
        <MailOutlineIcon />
      </IconButton>
    </a>
  );
};
MailTo.propTypes = {
  itemID: PropTypes.string.isRequired,
  itemTitle: PropTypes.string.isRequired,
};

export default MailTo;
