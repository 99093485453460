import React, { useCallback, useMemo } from "react";
import { useAuth } from "./AuthProvider";
import { useUpdateMany, useOptimisticDocuments } from "../db/hooks";

const MessageContext = React.createContext();
const MessageProvider = (props) => {
  const { user, ensureUsername } = useAuth();
  const messageQuery = useMemo(() => ({ $or: [{ receiver: user.id }, { sender: user.id }] }), [user.id]);
  const [messages, sendDocument, loading] = useOptimisticDocuments(
    "messages",
    "MessageProvider-Optimistic",
    messageQuery
  );
  const [updateMany] = useUpdateMany("messages", "MessageProvider-updatemany");
  const sendMessage = useCallback(
    async (msg, receiver, receiverUsername) =>
      sendDocument({
        sender: user.id,
        senderUsername: await ensureUsername(),
        receiver,
        receiverUsername,
        msg,
      }),
    [sendDocument, user.id, ensureUsername]
  );
  const setMessagesRead = useCallback(
    (ids) => ids && ids.length && updateMany({ _id: { $in: ids } }, { $set: { read: true } }),
    [updateMany]
  );
  const unreadMessageCount = useMemo(
    () => messages.filter((m) => m.receiver === user.id && !m.read).length,
    [messages, user.id]
  );
  return (
    <MessageContext.Provider
      value={{
        messages,
        unreadMessageCount,
        loadingMessages: loading,
        sendMessage,
        setMessagesRead,
      }}
      {...props}
    />
  );
};

const useMessages = () => React.useContext(MessageContext);
export { MessageProvider, useMessages };
