import React from "react";
import Grid from "@mui/material/Grid";
import { PropTypes } from "prop-types";
import Typography from "@mui/material/Typography";

const PrimaryInfo = (prop) => {
  return (
    <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
            Year Model
          </Typography>
        </Grid>
        <Grid item>{prop.makeYear}</Grid>
      </Grid>
      <Grid item container direction="column">
        <Grid>
          <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
            Mileage
          </Typography>
        </Grid>
        <Grid item>{prop.mileage}</Grid>
      </Grid>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
            FuelType
          </Typography>
        </Grid>
        <Grid item>{prop.fuelType}</Grid>
      </Grid>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
            Gear Type
          </Typography>
        </Grid>
        <Grid item>{prop.gearbox}</Grid>
        {prop.gearboxDesignation ? <Grid item>{prop.gearboxDesignation}</Grid> : null}
        {prop.gearboxOther ? <Grid item>{prop.gearboxOther}</Grid> : null}
      </Grid>
    </Grid>
  );
};
export default PrimaryInfo;

PrimaryInfo.propTypes = {
  makeYear: PropTypes.number.isRequired,
  mileage: PropTypes.number.isRequired,
  fuelType: PropTypes.oneOf([
    "gasoline",
    "diesel",
    "electrical",
    "electricalandgasoline",
    "electricalanddiesel",
    "lpg",
    "lpgandgasoline",
    "lpganddiesel",
    "hydrogen",
  ]).isRequired,
  gearbox: PropTypes.oneOf(["M", "A", "Opticruise", "Optidrive", "other"]).isRequired, // Bus minubus can have Manule, Automatic, Opticruise or Optidrive.
  gearboxOther: PropTypes.string, // use this if girbox  other is chosen
  gearboxDesignation: PropTypes.string, //F.eks: Steptronic, 4-MATIC eller Manuell 6 gir.
};
