import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import TwitterIcon from "@mui/icons-material/Twitter";
import PropTypes from "prop-types";
//import { ObjectID } from "bson";

const useStyles = makeStyles((theme) => ({
  botton: {
    backgroundColor: "transparent",
    "&:hover": {
      color: "#00acee",
      backgroundColor: "transparent",
    },
  },
}));

const TwitterShareButton = ({ itemID }) => {
  const classes = useStyles();

  return (
    // <a href={"https://www.facebook.com/sharer/sharer.php?u=http://localhost:3006/item/" + itemID}>
    <a href={"https://twitter.com/share?url=http://vg.no"}>
      <IconButton style={{ backgroundColor: "transparent" }} size="large">
        <TwitterIcon className={classes.botton} />
      </IconButton>
    </a>
  );
};
TwitterShareButton.propTypes = {
  itemID: PropTypes.string.isRequired,
};

export default TwitterShareButton;
