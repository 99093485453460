import React from "react";

const NavbarContext = React.createContext();
const defaultNavbarContext = { extraNavigations: [] };

const NavbarContextProvider = (props) => {
  const [navbarContext, setNavbarContext] = React.useReducer((state, newState) => {
    return { ...state, ...newState };
  }, defaultNavbarContext);

  const resetNavbarContext = () => setNavbarContext(defaultNavbarContext);
  return (
    <NavbarContext.Provider
      value={{
        navbarContext,
        setNavbarContext,
        resetNavbarContext,
      }}
      {...props}
    />
  );
};

const useNavbarContext = () => React.useContext(NavbarContext);
export { NavbarContextProvider, useNavbarContext };
