import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line import/default, import/namespace
// import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";

import { init, reactRouterV5Instrumentation } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ErrorBoundaryWrapped from "./components/ErrorBoundaryWrapped";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

init({
  dsn: "https://b1b3a7e72e064a6ca6496f3f5c851887@o997118.ingest.sentry.io/5955547",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: reactRouterV5Instrumentation(history),
    }),
  ],
  environment: process.env.NODE_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundaryWrapped description="root">
      <App history={history} />
    </ErrorBoundaryWrapped>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
