import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "../components/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import { useApp } from "../providers/AppProvider";
import { useTranslation } from "react-i18next";
//import { captureException } from "@sentry/react";
import { useHistory } from "react-router-dom";
import AlertDialog from "./AlertDialog";
import useFormB, { initiateInputValue } from "../form/useFormB";
import { isValidEmail, isRequired } from "../form/validators";

export default function LostPassword(props) {
  const app = useApp(); //const [msg, setMsg] = useState("");
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [onChange, validateForm, handelServerError, formProps] = useFormB(
    {
      email: initiateInputValue(""),
    },
    {
      email: [isValidEmail, isRequired],
    }
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (!isValid) {
      return;
    }
    try {
      await app.emailPasswordAuth.sendResetPasswordEmail(formProps.email.value);
      setOpen(true);
    } catch (err) {
      handelServerError("email", err);
    }
  };
  return (
    <React.Fragment>
      <Container component="main" maxWidth="md">
        <Paper
          sx={{
            p: 5,
            mt: 5,
            boxShadow: 5,
          }}
        >
          <Box
            sx={{
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Typography>{t("LostPassword")}</Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email Address"
                onChange={onChange}
                autoComplete="email"
                sx={{}}
                {...formProps.email}
              />

              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Reset password
              </Button>
            </Box>
          </Box>

          <Grid container spacing={4}>
            <Grid item>
              <Link to="/signin" variant="body2">
                Did not recieve email
              </Link>
            </Grid>

            <Grid item>
              <Link to="/signin" variant="body2">
                I remember my password
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <AlertDialog
        contentText={`We have send an email to . Please check your emails for a message with link to reset your password.`}
        buttonText={"OK"}
        open={open}
        handleClose={() => {
          setOpen(false);
          history.push("/signin");
        }}
      ></AlertDialog>
    </React.Fragment>
  );
}
