const description =
  "Lorem \nipsum\n dolor sit amet, consectetur adipiscing elit.\n Maecenas imperdiet mauris ut diam condimentum,\n ut placerat odio ultrices. Etiam sodales tellus vitae mauris volutpat, et dapibus lectus vulputate. Ut dolor mi, interdum et cursus dignissim, porta quis mi. Suspendisse dignissim mauris eget leo ullamcorper ultrices. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras varius, elit in sagittis pulvinar, dui nunc eleifend tellus, eu facilisis dui nunc varius quam. Vestibulum venenatis, augue aliquam pharetra aliquet, erat dui ullamcorper sapien, ut placerat turpis nisi nec turpis.Nulla blandit, turpis ut euismod maximus, mauris libero gravida elit, sit amet hendrerit lorem ipsum a neque. Duis rutrum quis justo eu posuere. Quisque consectetur auctor mauris, a placerat mauris volutpat in. Pellentesque in leo fermentum, lobortis velit a, cursus velit. Donec sed placerat urna. Donec elementum tortor ut odio blandit commodo. Morbi feugiat nisl eget ante laoreet, a pellentesque augue hendrerit. Integer eget massa nec orci venenatis suscipit.Mauris finibus, massa eget rutrum molestie, turpis est volutpat dui, vel ultrices massa metus eu justo. Integer tincidunt suscipit lorem, at elementum sem hendrerit nec. Suspendisse rutrum vulputate lorem et interdum. Sed mollis posuere faucibus. Pellentesque at egestas ex. Fusce facilisis aliquam dui, quis volutpat orci sagittis non. Suspendisse nibh dui, vulputate a urna ut, lacinia condimentum neque. Nulla interdum ullamcorper porttitor.Nam aliquet nulla sed blandit vestibulum. Praesent eu sagittis neque. Nunc fringilla leo lectus, ac tristique ligula scelerisque ac. Ut sed faucibus nulla, id ultricies purus. Quisque ultrices dui vitae nisi viverra, euismod interdum nulla efficitur. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus ante elit, bibendum a nunc id, imperdiet bibendum neque.";
export const carSample = {
  _id: "61336ee903f99659fa8e6d1c",
  vehicleType: "M1",
  plateNumber: "SU66107", //kjennemerke  //(valgfritt) Reg.nr eller understellsnr. må fylles ut for biler registrert etter 1981.
  vin: "JTDKC20E900010226", // Understellsnummer
  plateNumberPersonal: "", //personligKjennemerke
  makeYear: 2005, //registrering.forstegangsregistrering: "2005-03-31" <----extract from
  make: "TOYOTA", //tekniskKjoretoy.merke
  model: "COROLLA", //tekniskKjoretoy.handelsbetegnelse
  modelSpecification: "1.4 -90 D", //Modellspesifikasjon-v // (valgfritt) Maks 55 tegn. F.eks: 2.0D, 520d xDrive eller R-Design.
  mileage: 123456,
  euControlLast: "2021-11-30",
  euControlNext: "2023-11-30",

  //locationCountry:true, // Bilen står i norgen
  //Engin and fule
  engine: {
    fuelType: "diesel", //motorer[0].drivstoff.drivstofftype: "Diesel", //["GASOLINE","DIESEL","ELECTRICAL","ELECTRICALANDGASOLINE","ELECTRICALANDDIESEL","LPG","LPGANDGASOLINE","LPGANDDIESEL","HYDROGEN"]
    cylinderVolumeLiters: 1.4, //slagvolum: 1364, i liter (valgfritt) Dette finner du i vognkortet under Motor/drivverk. Eks: 2 400cm3 = 2,4 L
    effect: 66, //motorer[0].drivstoff.effekt -> kW x 1.36 =  Horse Power <- // i hk (valgfritt) hk
    co2Emissions: 128, //forbrukOgUtslipp[0].co2Utslipp i g/km (valgfritt)g/km Dette finner du i vognkortet under Prøveresultater/miljøklasse.
    batteryCapacity: null, // i kWh (valgfritt)kWh
    range: null, // i km (valgfritt) km Elektrisk rekkevidde fra produsenten.Tips! Mange elbil-kjøpere er opptatt av rekkevidde og batterikapasitet. Hvis du fyller ut disse feltene, blir det enklere for kjøperne å vurdere bilen din.
    gearbox: "M", //girkasse
    gearboxDesignation: "Steptronic", // (valgfritt)F.eks: Steptronic, 4-MATIC eller Manuell 6 gir.
    wheelDrive: "Front", //"rear", "front", "four" ->  [Bakjudrif,Forhjulsdrift,Firehjulsdrift]
    wheelDriveDesignation: "", // (valgfritt)F.eks: xDrive, 4WD eller 4MOTION.
    shaftType: "4x2",
    wheelbase: 120,
    springTypeFront: "air",
    springTypeBack: "parabel",
    additionalBrake: "check this ?",
    topSpeed: 230,
  },
  //karosseri
  bodyType: "combi-5door", //["CABRIOLET","COUPE","MPV","VAN","COMBI-3DOOR","COMBI-5DOOR","PICKUP","OFFROAD","SEDAN","STATIONWAGON","OTHER"]
  taxClass: "passenger-car", //avgiftsklasse: "", //["OTHER","COMBINED-CAR","LIGHT-LORRY","MINIBUS","PASSENGER-CAR","DELIVERY-TRUCK"]
  antallSeter: "", //Num
  antallDører: "", // (valgfritt) Number

  egenvekt: "", // i kg (valgfritt) kg
  maksTilhengervekt: "", //i kg (valgfritt),
  hovedfarge: "", //["BEIGE","BLUE","BRONZE","BROWN","GREY","GREEN","YELLOW","GOLD","WHITE","LILAC","ORANGE","PINK","RED","BLACK","SILVER","TURQUOISE"]
  fargebeskrivelse: "", // (valgfritt)F.eks: Grå metallic, Safirsort, Bursting Blue, Deep Black.
  interiørfarge: "", // (valgfritt)F.eks: Titan Black, Sort skinn, Ultra White, Grå alcantara.
  //(valgfritt)
  seatsNumber: 5,
  doorNumber: 5,
  trunkVolume: 0, //  bagasjeromsvolum: "", // i liter (valgfritt)
  netWeight: 1205, //Egenvekt i kg (valgfritt)
  maxTrailerWeight: 0, //maksTilhengervekt: "", //i kg (valgfritt),
  //  color: "Black",
  colorDescription: "Deep black", //F.eks: Grå metallic, Safirsort, Bursting Blue, Deep Black.
  interiorColor: "black", //F.eks: Titan Black, Sort skinn, Ultra White, Grå alcantara.
  body: {
    bodyType: "cabriolet",
    taxClass: "Combined-car",
    seatsNumber: 5,
    doorNumber: 5,
    trunkVolume: 80, //liters
    netWeight: 300, //kg
    maxTrailerWeight: 250, //kg
    color: "white",
    colorDescription: "Metalic", //For example: Gray metallic, Sapphire black, Bursting Blue, Deep Black.
    interiorColor: "Beige",
  },
  equipment: [
    //Exterior (optional) -v
    "summerTires",
    "winterTires",
    "metallicPaint",
    "allSeasonTires",
    "towBar", //HengerfesteFastKrok
    "towBarRemovable",
    "loadHoldersSkiStand",
    "lightweightRimSummer",
    "lightweightRimWinter",
    "roofRails",
    "xenonLights",
    "ledLight",
    "laserLight",
    //Driver assistance
    "highBeamAssistant", //Fjernlysassistent
    "lightSensor",
    "parkingSensorBack",
    "parkingSensorFront",
    "rainSensor",
    "rearViewCamera",
    //Interior:
    "powerWindows",
    "electricMirror",
    "sunRoofGlass",
    "darkRearWindShields",
    "seatPartlyLeather",
    "seatWholeLeather",
    "sportSeats",
    //Comfort (optional)
    "heatedSeats",
    "airConditioning",
    "cruiseControl",
    "cruiseControlAdaptive",
    "airCondition",
    "trunkCover",
    "electricSeatMMemory",
    "electricSeatUMemory",
    "interiorHeater", //Kupevarmer / coupe warmer
    "airSuspension",
    "centeralArmrests",
    "engineHeater",
    "levelControl",
    "keyless",
    //Media / Entertainment -v
    "cdPlayer",
    "cassettePlayer",
    "auxInput",
    "bluetooth",
    "handsfreeScheme",
    "headUpDisplay",
    "multifunctionSteeringwheel",
    "navigationSystem",
    "originalPhone",
    "radioDAB",
    "radioFM",
    "tvScreenBakseat",
    //Engine / drive -v
    "powerSteering",
    "antiSpin",
    "diffLock", //diffSperre
    "antiSkid",
    "dieselParticulateFilter",
    "tripComputer",
    //Security (optional)
    "centralLocking",
    "airbagForan",
    "airbagSide",
    "absBrakes",
    "alarm",
    "carTrackingSystem",
    "Isofix",
    "immobilizer",
  ],
  conditionWarranty: {
    mileage: 25555555, // kilometerstand: 0, //km Bilens totale kjørelengde skal oppgis i kilometer (ikke miles). Karosseriets totale kilometerstand skal oppgis dersom motor eller dashbord er byttet. Husk å få med alle sifrene.
    firsttimeRegistered: "2005",
    totalNumbersOwners: 5,
    warrantyType: "newCarWarranty",
    supplierOfConditionReport: "NAF",
    followedMaintenanceProgram: true, // For Car Bobil	MC	Moped	ATV	Snøscooter
    numberOfWorkingHours: 500, //Traktor og Tresker have only this attribute
    machineIsCEmarked: true, // For bygg og anlegg
    machineHasDeclarationConformity: true, // Maskinen har samsvarserklæring For bygg og anlegg
  },

  images: ["http://res.cloudinary.com/ebasar/image/upload/v1630760678/item_images/jgtt67ewhfzvjmyhpf4p.jpg"], //images
  videoURL: "", //Lenke til video på YouTube eller Vimeo.
  description: description, // (valgfritt)

  cost: {
    price: 25000, //i kr - eks. omregistrering
    reRegistrationFee: 1000, //omregistreringsavgif
    exemptionForReregistrationFee: false,
    totalPrice: 26000, //totalprisSøkbarPris: 0
  },
  contact: {
    phone: "40171777", //valgfritt Hvilket telefonnummer ønsker du at interesserte kjøpere skal kontakte deg på?
    address: "Bretlandsgata 50", // (valgfritt)
    postcode: "5009",
    city: "Stavanger",
    land: "Norway",
    showAddress: true, //Hvis denne profilen ikke er riktig kan du endre den på din Schibsted-konto, men vær obs på at du da samtidig endrer profilen på alle nettsteder som benytter Schibsted-konto.
    showPhonNr: true,
  },
  location: { type: "Point", coordinates: [10.75, 59.91234] },
  leasing: {
    startRentDeposit: 5000,
    monthlyAmount: 3000,
    remainingDrivingDistance: 50000,
    debtorChangeFee: 2000,
    agreementDuration: "Dato",
    agreementExpirationDate: "2022-12-12",
    agreementNumberOfTermsLeft: 300,
    leasingCompany: "eBasar",
    submissionPlace: "Stavanger",
  },
  sellerSelfDeclaration: {
    hasDefect: "yes",
    usedInBuisniess: "yes",
    engineTrimmed: "yes",
    majorCollision: "yes",
  },
};

export const item = {
  item: {
    _id: "61336ee903f99659fa8e6d1c",
    title: "ikke auksjon",
    description: "testtzsrxtsrt",
    price: 329,
    category: "Family",
    subCategory: "Health & beauty",
    countryCode: "NOR",
    countryName: "Norge",
    state: "Oslo",
    county: "Oslo",
    city: "Oslo",
    postalCode: "0155",
    addressLabel: "Oslo, Norge",
    user_id: "60bbeaf443de7042a5405e0f",
    username: "zhans",
    images: ["http://res.cloudinary.com/ebasar/image/upload/v1630760678/item_images/jgtt67ewhfzvjmyhpf4p.jpg"],
    location: { type: "Point", coordinates: [10.75, 59.91234] },
    likedBy: [
      "60e31bf138cb740acdd34e99",
      "60bbeaf443de7042a5405e0f",
      "614707368a3c034fe34bb209",
      "60ca4e1b63aff2f96d7d0cab",
    ],
    sold: false,
  },
  user: {
    id: "61b1e45d048b84e9c1d11cb4",
    accessToken:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWFzX2RldmljZV9pZCI6IjYxY2Y2Yzc4NjM5ZTdlYjg1ZWJmMDkzYyIsImJhYXNfZG9tYWluX2lkIjoiNWZmYTA2NTgzNzJjNmIyNDRjYzdkYTZkIiwiZXhwIjoxNjQxMjI4ODU4LCJpYXQiOjE2NDEyMjcwNTgsImlzcyI6IjYxY2Y2Yzc4NjM5ZTdlYjg1ZWJmMDkzZiIsInN0aXRjaF9kZXZJZCI6IjYxY2Y2Yzc4NjM5ZTdlYjg1ZWJmMDkzYyIsInN0aXRjaF9kb21haW5JZCI6IjVmZmEwNjU4MzcyYzZiMjQ0Y2M3ZGE2ZCIsInN1YiI6IjYxYjFlNDVkMDQ4Yjg0ZTljMWQxMWNiNCIsInR5cCI6ImFjY2VzcyIsInVzZXJfZGF0YSI6eyJfaWQiOiI2MWIxZTQ1ZDA0OGI4NGU5YzFkMTFjYjgiLCJ1c2VyX2lkIjoiNjFiMWU0NWQwNDhiODRlOWMxZDExY2I0IiwidXNlckluZm8iOnsiZmlyc3ROYW1lIjoiIiwibGFzdE5hbWUiOiIiLCJjZWxsUGhvbmUiOiIiLCJhZGRyZXNzIjp7ImFkZHJlc3MxIjoiIiwiYWRkcmVzczIiOiIiLCJjaXR5IjoiIiwic3RhdGUiOiIiLCJwb3N0YWxDb2RlIjoiIiwiY291bnRyeSI6IiJ9fSwiZmF2b3VyaXRlcyI6e319fQ.dlW99LUk5dtDN7TLkSPCe35kdZ8CpiMZPMQg7_M7Kg8",
    refreshToken:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWFzX2RhdGEiOm51bGwsImJhYXNfZGV2aWNlX2lkIjoiNjFjZjZjNzg2MzllN2ViODVlYmYwOTNjIiwiYmFhc19kb21haW5faWQiOiI1ZmZhMDY1ODM3MmM2YjI0NGNjN2RhNmQiLCJiYWFzX2lkIjoiNjFjZjZjNzg2MzllN2ViODVlYmYwOTNmIiwiYmFhc19pZGVudGl0eSI6eyJpZCI6IjYxYjFlNDM2Njg5ODFiMjA3NzkwZDUzMSIsInByb3ZpZGVyX3R5cGUiOiJsb2NhbC11c2VycGFzcyIsInByb3ZpZGVyX2lkIjoiNWZmYTMyNjRkM2E4YjVmNzExYWIzNjJlIn0sImV4cCI6MTY0NjE2NzY3MiwiaWF0IjoxNjQwOTgzNjcyLCJzdGl0Y2hfZGF0YSI6bnVsbCwic3RpdGNoX2RldklkIjoiNjFjZjZjNzg2MzllN2ViODVlYmYwOTNjIiwic3RpdGNoX2RvbWFpbklkIjoiNWZmYTA2NTgzNzJjNmIyNDRjYzdkYTZkIiwic3RpdGNoX2lkIjoiNjFjZjZjNzg2MzllN2ViODVlYmYwOTNmIiwic3RpdGNoX2lkZW50Ijp7ImlkIjoiNjFiMWU0MzY2ODk4MWIyMDc3OTBkNTMxIiwicHJvdmlkZXJfdHlwZSI6ImxvY2FsLXVzZXJwYXNzIiwicHJvdmlkZXJfaWQiOiI1ZmZhMzI2NGQzYThiNWY3MTFhYjM2MmUifSwic3ViIjoiNjFiMWU0NWQwNDhiODRlOWMxZDExY2I0IiwidHlwIjoicmVmcmVzaCJ9.iYedezqAzyZM5sxfrLBO1KIjlqoL02BYQXsPG_oiTZc",
    profile: {
      type: "normal",
      identities: [{ id: "61b1e43668981b207790d531", providerType: "local-userpass" }],
      data: { email: "contactbehzad@icloud.com" },
    },
    state: "active",
    customData: {
      _id: "61b1e45d048b84e9c1d11cb8",
      user_id: "61b1e45d048b84e9c1d11cb4",
      userInfo: {
        firstName: "",
        lastName: "",
        cellPhone: "",
        address: { address1: "", address2: "", city: "", state: "", postalCode: "", country: "" },
      },
      favourites: {},
    },
  },
};
