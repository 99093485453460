import React, { useEffect, useReducer, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import PropTypes from "prop-types";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import FilterDrawer from "../components/FilterDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { IconButton, Button, InputAdornment } from "@mui/material";
import RadioSelector from "../components/RadioSelector";
import { debounce } from "../utils/debounce";
import RangeSlider from "../components/RangeSlider";
import categories from "../categories";
import LocationPickerInput from "../components/LocationPickerInput";
import SelectWithError from "../components/SelectWithError";
import { useCachedAggregate } from "../db/hooks";

import ItemCard from "../components/ItemCard";

const filterItems = {
  // title: {
  //   type: "text",
  //   value: "",
  //   fuzzy: true,
  // },
  // sellOrGive: {
  //   type: "radio-select",
  //   value: null,
  //   options: [
  //     { value: "sell", label: "Sell" },
  //     { value: "give", label: "Give away" },
  //   ],
  // },
  // description: {
  //   type: "text",
  //   value: "",
  //   fuzzy: true,
  // },
  price: {
    name: "price",
    type: "range-slider",
    value: [0, 100000],
    label: "price",
    max: 100000,
  },
  // city: {
  //   name: "city",
  //   type: "LocationPickerInput",
  //   value: null,
  //   label: "city",
  //   types: "city",
  // },
  area: {
    name: "area",
    type: "LocationPickerInput",
    value: null,
    label: "area",
    types: "area",
  },

  category: {
    type: "select",
    options: Object.keys(categories),
    value: "",
  },
  subCategory: {
    dontRenderIf: (filters) => !!filters.category.value,
    options: (filters) => categories[filters.category.value],
    type: "select",
    value: "",
  },
};

const FilterItem = ({ type, name, onChange, value, options, label, max }) => {
  switch (type) {
    case "text":
      return (
        <OutlinedInput
          placeholder={name}
          onChange={onChange}
          value={value}
          endAdornment={
            <InputAdornment position="end">
              <IconButton type="submit" aria-label="search" size="large">
                <SearchOutlinedIcon color="secondary" fontSize="large" />
              </IconButton>
            </InputAdornment>
          }
        />
      );
    case "radio-select":
      return <RadioSelector options={options} onChange={onChange} value={value} />;
    case "range-slider":
      return <RangeSlider onChange={onChange} value={value} label={label} max={max} />;
    case "select":
      return (
        <SelectWithError
          allowDeselect
          onChange={onChange}
          value={value}
          fullWidth
          inputLabel={name}
          options={options}
        />
      );
    case "LocationPickerInput":
      return (
        <LocationPickerInput allowDeselect fullWidth onChange={onChange} value={value} name={name} label={label} />
      );
    default:
      throw new Error(`unknown filteritem type ${type}`);
  }
};
FilterItem.propTypes = {
  max: PropTypes.number,
  type: PropTypes.oneOf(["text", "radio-select", "range-slider", "select", "LocationPickerInput"]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  options: PropTypes.array,
  label: PropTypes.string,
};

const filterReducer = (state, action) => {
  const newState = { ...state, [action.name]: { ...state[action.name], value: action.value } };
  if (action.name === "category") {
    newState.subCategory = { ...newState.subCategory, value: filterItems.subCategory.value };
  }
  return newState;
};

const hereApiResultToSearchQuery = (value) => {
  switch (value.resultType) {
    case "locality":
      switch (value.localityType) {
        case "city":
          return {
            countryCode: value.address.countryCode,
            state: value.address.state,
            city: value.address.city,
          };
        case "postalCode":
          return {
            countryCode: value.address.countryCode,
            postalCode: value.address.postalCode,
          };
        default:
          throw Error(`Unknown resultType (localityType) ${value}`);
      }
    case "street":
      return {
        countryCode: value.address.countryCode,
        state: value.address.state,
        city: value.address.city,
        street: value.address.street,
      };
    case "houseNumber":
      return {
        countryCode: value.address.countryCode,
        state: value.address.state,
        city: value.address.city,
        street: value.address.street,
        houseNumber: value.address.houseNumber,
      };
    case "administrativeArea":
      switch (value.administrativeAreaType) {
        case "state":
          return {
            countryCode: value.address.countryCode,
            state: value.address.state,
          };
        case "country":
          return {
            countryCode: value.address.countryCode,
          };
        case "county":
          return {
            countryCode: value.address.countryCode,
            state: value.address.state,
            county: value.address.county,
          };
        default:
          throw Error(`Unknown resultType (administrativeArea) ${value}`);
      }
    default:
      throw Error(`Unknown resultType ${value}`);
  }
};

const filterToSearchQuery = (name, values) => {
  switch (name) {
    case "price":
      return { range: { path: name, gte: values.value[0], lte: values.value[1] } };
    case "area":
      return Object.entries(hereApiResultToSearchQuery(values.value)).map(([name, value]) => ({
        text: { path: name, query: value },
      }));
    case "category":
    case "subCategory":
      return { text: { path: name, query: values.value } };
    default:
      throw Error(`Unknown filter name: ${JSON.stringify(name)}`);
  }
};

const filtersToSearchQuery = (filters) =>
  Object.entries(filters)
    .filter(([, values]) => !!values.value)
    .map(([name, values]) => filterToSearchQuery(name, values))
    .flat();

const itemsReducer = (state, action) => {
  if (action.type === "reset") return action.payload;
  return state.concat(action.payload);
};

const ITEMS_PER_PAGE = 24;
const fetchNewItems = debounce(
  async (aggregate, filters, searchQuery, setItems, setHasMoreItems, $skip) => {
    const $search = {
      index: "title-description",
      compound: {
        must: [
          ...filtersToSearchQuery(filters),
          ...(searchQuery
            ? [
                {
                  text: {
                    query: searchQuery,
                    path: ["title", "description"],
                    fuzzy: {
                      maxEdits: 1,
                      maxExpansions: 50,
                      prefixLength: 0,
                    },
                  },
                },
              ]
            : []),
        ],
      },
    };

    const pipeline = [
      ...($search.compound.must.length ? [{ $search }] : []),
      { $sort: { score: -1, _id: -1 } },
      ...($skip ? [{ $skip }] : []),
      { $limit: ITEMS_PER_PAGE },
    ];

    const items = await aggregate(pipeline);
    setHasMoreItems(!(items.length < ITEMS_PER_PAGE));
    // if it is a fresh query (not skip) set it as current items, even if it is empty
    if (!$skip || items.length) {
      setItems({ payload: items, type: $skip ? "add" : "reset" });
    }
  },
  500,
  true
);

const useStyles = makeStyles((theme) => ({
  paperItem: {
    margin: 10,
    padding: 10,
    width: 400,
    height: 550,
    "& p": {
      display: "-webkit-box",
      overflow: "hidden",
      boxOrient: "vertical",
      lineClamp: 2,
      textOverflow: "ellipsis",
    },
  },
  fullHeight: {
    height: "100%",
  },
  itemContainer: {
    overflow: "auto",
  },
  searchContainer: {
    padding: 15,
  },
}));

const ListItems = () => {
  const [aggregate] = useCachedAggregate("items", "ListItems");
  const searchContainerHeight = 90;
  const [items, setItems] = useReducer(itemsReducer, []);
  const classes = useStyles();
  const [filters, setFilters] = useReducer(filterReducer, filterItems);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const search = ($skip = null) => fetchNewItems(aggregate, filters, searchQuery, setItems, setHasMoreItems, $skip);
  useEffect(
    () => search(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.values(filters).map((f) => f.value)
  );
  return (
    <Grid container className={classes.fullHeight} direction="row">
      <Grid item sm={false} md={4} lg={3} xl={2}>
        <FilterDrawer>
          <List>
            {Object.entries(filters)
              .filter(([, values]) => !values.dontRenderIf || values.dontRenderIf(filters))
              .map(([name, values], i) => (
                <ListItem key={i}>
                  <FilterItem
                    {...values}
                    options={typeof values.options === "function" ? values.options(filters) : values.options}
                    onChange={(e, nn) => setFilters({ name, value: name === "price" ? nn : e.target.value })}
                    name={name}
                  />
                </ListItem>
              ))}
          </List>
        </FilterDrawer>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
        sm={12}
        md={8}
        lg={9}
        xl={10}
        className={classes.fullHeight}
      >
        <Grid item className={classes.searchContainer} style={{ height: searchContainerHeight }}>
          <OutlinedInput
            fullWidth
            placeholder={"Search"}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && search()}
            value={searchQuery}
            endAdornment={
              <InputAdornment position="end">
                <IconButton type="submit" aria-label="search" onClick={() => search()} size="large">
                  <SearchOutlinedIcon color="secondary" fontSize="large" />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          className={classes.itemContainer}
          style={{ height: `calc(100% - ${searchContainerHeight}px)` }}
        >
          {items.map((x) => (
            <Grid style={{ margin: 16 }} item key={x._id}>
              <ItemCard item={x} width={223} height={280} />
              {/*<Item paperItem={classes.paperItem} setFilters={setFilters} {...x} />*/}
            </Grid>
          ))}
          {hasMoreItems && (
            <Grid item style={{ flexBasis: "100%" }}>
              <Button fullWidth variant="contained" color="primary" onClick={() => search(items.length)}>
                Fetch more items
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListItems;

// const Item = (x) => (
//   <Paper className={x.paperItem}>
//     <Link to={`/item/${x._id}`}>
//       {(x.images && x.images.length && (
//         <img src={x.images[0]} alt="mainimage" width="200" height="200" style={{ objectFit: "contain" }} />
//       )) ||
//         ""}
//       <h1>{x.title}</h1>
//     </Link>
//     <p>{x.description}</p>
//     <p>{x.sellOrGive === "sell" ? x.price : "give away"}</p>
//     <Button onClick={() => x.setFilters({ name: "category", value: x.category })}>{x.category}</Button>
//     <Button
//       onClick={() => {
//         x.setFilters({ name: "category", value: x.category });
//         x.setFilters({ name: "subCategory", value: x.subCategory });
//       }}
//     >
//       {x.subCategory}
//     </Button>
//     <br />
//     {x.street}: {x.houseNumber}, {x.city}, {x.state}, {x.countryName}
//   </Paper>
// );
