import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useSetLoading } from "../providers/LoadingProvider";
import PropTypes from "prop-types";
import { useAuth } from "../providers/AuthProvider";
import { cloudinary_cloudName, cloudinary_api_key } from "../staticVars";

const UploadImage = ({ onImageUploaded }) => {
  const { setIsLoading } = useSetLoading("UploadImage");
  const { user } = useAuth();
  const idOfContainer = "motherfucker";
  const uploadWidgetInstance = useRef(null);
  const uploadWidgetCallback = useCallback(
    (error, result) => {
      switch (result.event) {
        case "queues-start":
          setIsLoading(true);
          break;
        case "success":
          onImageUploaded(result);
          break;
        case "queues-end":
          uploadWidgetInstance.current.close();
          uploadWidgetInstance.current.open();
          setIsLoading(false);
          break;
        default:
          break;
      }
    },
    [onImageUploaded, setIsLoading]
  );
  const uploadWidgetOptions = useMemo(
    () => ({
      folder: "item_images",
      cloudName: cloudinary_cloudName,
      api_key: cloudinary_api_key,
      upload_signature: async (callback, params_to_sign) => {
        const response = await user.functions.cloudinary_generate_signature(params_to_sign);
        callback(response);
      },
    }),
    [user]
  );
  useEffect(() => {
    if (uploadWidgetInstance.current) {
      uploadWidgetInstance.current.update(uploadWidgetOptions, uploadWidgetCallback);
    } else {
      // eslint-disable-next-line no-undef
      uploadWidgetInstance.current = cloudinary.openUploadWidget(
        { ...uploadWidgetOptions, inlineContainer: `#${idOfContainer}` },
        uploadWidgetCallback
      );
    }
    return () => {
      uploadWidgetInstance.current.destroy();
      uploadWidgetInstance.current = null;
    };
  }, [uploadWidgetCallback, uploadWidgetOptions]);
  return <div id={idOfContainer} />;
};

UploadImage.propTypes = {
  onImageUploaded: PropTypes.func.isRequired,
};

export default UploadImage;
