import React, { useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import { Link as RouterLink } from "react-router-dom";
import CardActionArea from "@mui/material/CardActionArea";
import emptyImage from "../assets/images/emptyImage.svg";
import PropTypes from "prop-types";
import { useState } from "react";
import { useUpdateOne } from "../db/hooks";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  iconButtons: {
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: theme.palette.common.gray2,
    fontWeight: 500,
  },
  price: {
    color: theme.palette.common.gray2,
    fontWeight: 500,
  },
  popoverTypography: {
    cursor: "pointer",
    maxWidth: 125,
    padding: theme.spacing(1, 1, 0, 1),
    color: theme.palette.common.gray2,
    fontWeight: 600,
  },
}));

export default function MyItemTile({ item, width, height }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [itemSold, setItemSold] = useState(item.sold);
  const [updateOne] = useUpdateOne("items", "MyItemTile-useUpdateOne");
  const [anchorEl, setAnchorEl] = useState(null);
  const deletePopoverOpen = Boolean(anchorEl);

  const onClickDelete = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const onShare = (e) => {
    e.preventDefault();
  };

  const setSold = useCallback(
    (id, state) => {
      updateOne({ _id: id }, { $set: { sold: state } }).then((res) => {
        setItemSold(state);
      });
    },
    [updateOne]
  );
  const onMarkeAsSolde = (e) => {
    e.preventDefault();
    setSold(item._id, !itemSold);
  };

  return (
    <Card style={{ backgroundColor: "transparent", width, height }} elevation={0}>
      <CardActionArea component={RouterLink} to={`/item/${item._id}`}>
        <CardMedia className={classes.media} image={item.images[0] || emptyImage} title="Paella dish" />
        <Grid container className={classes.iconButtons}>
          <Grid item>
            <Tooltip title="Marke as solde" placement="top">
              <IconButton aria-label="Marke as solde" onClick={onMarkeAsSolde} size="large">
                <GavelOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Delete" placement="top">
              <IconButton id={item._id} aria-label="Delete" onClick={onClickDelete} size="large">
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Edit" placement="top">
              <IconButton aria-label="Edit" component={RouterLink} to={`/edit-item/${item._id}`} size="large">
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Share" placement="top">
              <IconButton aria-label="Share" onClick={onShare} size="large">
                <ShareOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Popover
          open={deletePopoverOpen}
          anchorEl={anchorEl}
          onClick={(e) => {
            e.preventDefault();
          }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <Grid className={classes.popoverTypography}>
            <Typography variant="h7">are you sure you want to delete?</Typography>
          </Grid>
          <Grid container className={classes.iconButtons}>
            <Grid item>
              <Button onClick={handleDelete}>yes</Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose}>no</Button>
            </Grid>
          </Grid>
        </Popover>
        <CardContent>
          <Typography variant="h5" className={classes.title}>
            {item.title}
          </Typography>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" className={classes.price}>
                {itemSold ? "Sold" : "Kr " + item.price}
              </Typography>
            </Grid>
            {item.likedBy.length ? (
              <Grid item>
                <Grid container style={{ direction: "row" }}>
                  <Grid item>
                    <Typography variant="h6" className={classes.price}>
                      {item.likedBy.length}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FavoriteBorderIcon />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
MyItemTile.propTypes = {
  item: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
};
