import { useReducer } from "react";
import { strIsDateTime } from "../utils/datetime";

const useForm = (initialData, valueToProps) => {
  const [formProps, setFormData] = useReducer((formProps, { eventType, target }) => {
    const value = target.value;
    const newProps = valueToProps?.[target.name]?.[eventType]?.(value);
    return {
      ...formProps,
      [target.name]: {
        ...formProps[target.name],
        ...newProps,
        value,
      },
    };
  }, initialData);
  // runs through all possible valueToProps events, until one returns error=true, but does not actually change any props
  const validateFormPure = () => {
    for (const [inputName, props] of Object.entries(formProps)) {
      for (const eventType of Object.keys(valueToProps[inputName])) {
        const validation = valueToProps[inputName][eventType](props.value);
        if (validation.error) {
          return {
            error: true,
            msg: `${inputName}: ${validation.helperText}`,
          };
        }
      }
    }
    return {
      error: false,
      msg: "",
    };
  };
  // validates by triggering all possible valueToProps event on all inputs, but does it through dispatch, so props actually change.
  const validateFormStateful = () => {
    for (const [inputName, props] of Object.entries(formProps)) {
      for (const eventType of Object.keys(valueToProps[inputName])) {
        setFormData({ eventType, target: { name: inputName, value: props.value } });
      }
    }
  };
  return [
    validateFormPure,
    validateFormStateful,
    Object.entries(formProps).reduce(
      (obj, [key, value]) => ({
        ...obj,
        [key]: {
          ...value,
          onChange: (e) => setFormData({ eventType: "onChange", target: e.target }),
          onBlur: (e) => setFormData({ eventType: "onBlur", target: e.target }),
          id: key,
          name: key,
        },
      }),
      {}
    ),
  ];
};
export default useForm;

export const formPropsToValues = (formProps) =>
  Object.entries(formProps).reduce(
    (obj, [key, props]) => ({ ...obj, [key]: props.type === "number" ? parseFloat(props.value) : props.value }),
    {}
  );

export const initialInputValue = (value) => ({ value, error: false, helperText: "", type: valueToTypeProp(value) });

export const initInputValSpecType = (value, type) => ({ value, error: false, helperText: "", type });

const valueToTypeProp = (value) => {
  // https://www.w3schools.com/html/html_form_input_types.asp
  // all input types that exists..
  if (typeof value === "number") {
    return "number";
  }
  if (strIsDateTime(value)) {
    return "datetime-local";
  }
  return "text";
};

export const fakeEvent = (name, value) => ({ target: { value, name } });
