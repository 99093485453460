import React from "react";
import notFound from "../assets/404.svg";
import { Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Container } from "@mui/material";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));
const PageNotFound = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container>
      <Grid container className={classes.root} spacing={2} alignItems="center" direction="column">
        <Grid item xs={12}>
          <img src={notFound} width={600} alt="page not found" style={{ marginTop: 100 }}></img>{" "}
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" onClick={history.goBack}>
            Back to previous page
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageNotFound;
