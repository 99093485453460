import * as React from "react";
import PropTypes from "prop-types";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { fakeEvent } from "../form/useForm";

const CheckboxWrapped = ({ onChange, onBlur, value, name, label }) => {
  const eventCallback = (eventType) => (e) => eventType(fakeEvent(name, e.target.checked));
  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={value} onBlur={eventCallback(onBlur)} onChange={eventCallback(onChange)} />}
        label={label}
      />
    </FormGroup>
  );
};
CheckboxWrapped.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
};
export default CheckboxWrapped;
