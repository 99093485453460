import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "../components/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import { useApp } from "../providers/AppProvider";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AlertDialog from "./AlertDialog";
import useFormB from "../form/useFormB";

export default function ResetPassword(props) {
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const tokenId = params.get("tokenId");
  const { t } = useTranslation();
  if (!token || !tokenId) {
    throw new Error("You can only call resetPassword() if the user followed a confirmation email link");
  }
  const history = useHistory();
  const app = useApp();
  const [helperTexts] = useState({ password: "", passwordRepeat: "" });
  const [errorFlags] = useState({ password: false, passwordRepeat: false });
  const [open, setOpen] = React.useState(false);

  const { formData, onChange, handleSubmit } = useFormB(
    {
      password: "",
      passwordRepeat: "",
    },
    (formData) => resetPassword(password)
    //    (formData) => console.dir(formData)
  );

  const { password, passwordRepeat } = formData;

  // const validate = (type, value, setHelper) => {
  //   let valid;
  //   switch (type) {
  //     case "password":
  //       valid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/.test(value);
  //       if (!valid) {
  //         setHelper("Invalid password,");
  //         return false;
  //       } else {
  //         setHelper("");
  //         return true;
  //       }
  //     default:
  //       break;
  //   }
  // };
  const resetPassword = (password) => {
    // if (!validate("password", password, setHelperTexts)) {
    //   setErrorFlags({
    //     ...errorFlags,
    //     password: true,
    //   });
    //   return;
    // }
    //const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    try {
      app.emailPasswordAuth.resetPassword({ password: password, token, tokenId });
      setOpen(true);
    } catch (err) {
      // if (err.message.includes("user not found")) {
      //   setEmailHelper(
      //     "We could not find a corrsponding user with the spesified email. Please make sure you have provided the correct email address"
      //   );
      // }
      // setEmailErrorFlag(true);
    }
  };

  return (
    <React.Fragment>
      <Container component="main" maxWidth="md">
        <Paper
          sx={{
            p: 5,
            mt: 5,
            boxShadow: 5,
          }}
        >
          <Box
            sx={{
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Typography>{t("Reset Password Message")}</Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                value={password}
                error={errorFlags.password}
                helperText={helperTexts.password}
                onChange={onChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="passwordRepeat"
                label="Confirm Password"
                name="passwordRepeat"
                value={passwordRepeat}
                error={errorFlags.passwordRepeat}
                helperText={helperTexts.passwordRepeat}
                onChange={onChange}
              />

              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Reset password
              </Button>

              <Grid container>
                <Grid item>
                  <Link to="/signin" variant="body2">
                    I remember my password
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Container>
      <AlertDialog
        contentText={`your password is successfully reset`}
        buttonText={"OK"}
        open={open}
        handleClose={() => {
          setOpen(false);
          history.push("/signin");
        }}
      ></AlertDialog>
    </React.Fragment>
  );
}
