import React, { useMemo } from "react";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useForm, { formPropsToValues, initialInputValue } from "../form/useForm";
import { isNumberBetween } from "../form/validators";
import { useOptimisticDocuments, useFind_OneShot } from "../db/hooks";
import { useAuth } from "../providers/AuthProvider";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import PropTypes from "prop-types";
import { itemPropType } from "../utils/sharedPropTypes";

const getHighestBid = (bids, itemPrice) =>
  bids.reduce((acc, curr) => (acc.bid > curr.bid ? acc : curr), {
    bid: itemPrice || 0,
    username: "none yet",
  });

const AuctionComponent = ({ item }) => {
  const auctionHasEnded = new Date(item.auctionEnds) < new Date();
  return auctionHasEnded ? <RenderInactiveAuction item={item} /> : <BidContainer item={item} />;
};
AuctionComponent.propTypes = {
  item: itemPropType.isRequired,
};

const RenderInactiveAuction = ({ item }) => {
  const [bids, isLoading] = useFind_OneShot(
    "bids",
    useMemo(() => ({ item_id: item._id }), [item._id]),
    "RenderInactiveAuction"
  );
  return (
    <>
      <h1>Auction is over</h1>
      {<p>Winning bid was: {isLoading ? "Fetching bid" : getHighestBid(bids, item.price).bid}</p>}
    </>
  );
};
RenderInactiveAuction.propTypes = {
  item: itemPropType.isRequired,
};

const BidContainer = ({ item }) => {
  const bidsQuery = useMemo(
    () => ({
      item_id: item._id,
    }),
    [item._id]
  );
  const [bids, postBid, loading] = useOptimisticDocuments("bids", "BidContainer-Optimistic", bidsQuery);
  return loading ? <h1>loading</h1> : <Bid item={item} bids={bids} postBid={postBid} />;
};

BidContainer.propTypes = {
  item: itemPropType.isRequired,
};

const Bid = ({ item, bids, postBid }) => {
  const minimumBid = 10;
  const highestBid = getHighestBid(bids, item.price);
  const { user, ensureUsername } = useAuth();
  const [validateFormPure, formProps] = useForm(
    {
      bid: initialInputValue(highestBid.bid + minimumBid),
    },
    {
      bid: { onBlur: isNumberBetween(0.0001, 1000000000000) },
    }
  );
  const formValidation = validateFormPure();

  const formValues = formPropsToValues(formProps);
  const doBid = async () => {
    postBid({ ...formValues, user_id: user.id, username: await ensureUsername(), item_id: item._id });
  };
  return (
    <>
      Your Bid:
      <TextField type="number" {...formProps.bid} />
      <Button onClick={doBid}>BID</Button>
      <Typography variant="h6" style={{ fontWeight: 500 }}>
        Current bid: {highestBid.bid}, from: {highestBid.username}
      </Typography>
      <CountDown auctionEnds={item?.auctionEnds} />
      {formValidation.error && <Alert>{formValidation.msg}</Alert>}
    </>
  );
};
Bid.propTypes = {
  item: itemPropType.isRequired,
  bids: PropTypes.arrayOf(
    PropTypes.shape({
      bid: PropTypes.number,
      username: PropTypes.string,
    })
  ).isRequired,
  postBid: PropTypes.func.isRequired,
};

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6,
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

const CountDown = ({ auctionEnds }) => {
  const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = new Date(auctionEnds) / 1000; // use UNIX timestamp in seconds

  const remainingTime = endTime - stratTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <h2>Auction ends in:</h2>
      <CountdownCircleTimer
        {...timerProps}
        colors={[["#7E2E84"]]}
        duration={daysDuration}
        initialRemainingTime={remainingTime}
      >
        {({ elapsedTime }) => renderTime("days", getTimeDays(daysDuration - elapsedTime))}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors={[["#D14081"]]}
        duration={daySeconds}
        initialRemainingTime={remainingTime % daySeconds}
        onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > hourSeconds]}
      >
        {({ elapsedTime }) => renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors={[["#EF798A"]]}
        duration={hourSeconds}
        initialRemainingTime={remainingTime % hourSeconds}
        onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > minuteSeconds]}
      >
        {({ elapsedTime }) => renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors={[["#218380"]]}
        duration={minuteSeconds}
        initialRemainingTime={remainingTime % minuteSeconds}
        onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > 0]}
      >
        {({ elapsedTime }) => renderTime("seconds", getTimeSeconds(elapsedTime))}
      </CountdownCircleTimer>
    </div>
  );
};
CountDown.propTypes = {
  auctionEnds: PropTypes.string,
};
export default AuctionComponent;
