import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { vehicleBodyPropType } from "../../utils/sharedPropTypes";

const Body = (prop) => {
  const attributeToTitle = {
    bodyType: "Body Type",
    taxClass: "TaxClass",
    seatsNumber: "Seats Number",
    doorNumber: "Door Number",
    trunkVolume: "Trunk Volume",
    netWeight: "Net Weight",
    maxTrailerWeight: "Max Trailer Weight",
    color: "Color",
    colorDescription: "Color Description",
    interiorColor: "Interior Color",
  };
  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography variant="h5" style={{ fontWeight: 500 }} color="primary">
          Body
        </Typography>
      </Grid>
      <Grid item container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {Object.entries(prop).map(([key, value], i) => (
          <Grid item xs={2} sm={4} md={4} key={key}>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
                  {attributeToTitle[key]}
                </Typography>
              </Grid>
              <Grid item>{value}</Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
export default Body;

Body.propTypes = {
  ...vehicleBodyPropType,
};
