import React, { useState } from "react";
import dayjs from "dayjs";
import Container from "@mui/material/Container";
import AsyncButton from "../components/AsyncButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SelectWithError from "../components/SelectWithError";
import { PostAdd } from "@mui/icons-material";
import useForm, { formPropsToValues, initInputValSpecType } from "../form/useForm";
import { useAuth } from "../providers/AuthProvider";
import { isNumberBetween, isRequired } from "../form/validators";
import {
  VehiclePropType,
  vehicleIdentityCategories,
  vehicleTypeOptions,
  gearboxTypes,
  wheelDriveTypes,
  shaftTypeOptions,
  springTypeOptions,
  fuelTypeOptions,
  warrantyOptions,
  supplierOfConditionReportOptions,
  bodyTypeOptions,
} from "../utils/sharedPropTypes";
import CheckboxWrapped from "../components/CheckboxWrapped";
import DatePickerWrapped from "../components/DatePickerWrapped";
import { Button } from "@mui/material";

const tekniskKodeToCategory = (kode) => {
  switch (kode.toUpperCase()) {
    case "M1":
    case "M2":
    case "N1":
    case "N2":
    case "N3":
      return "car";
    case "M3":
      return "bus";
    case "O1":
    case "O2":
    case "O3":
    case "O4":
      return "henger";
    case "T1":
    case "T2":
    case "T3":
    case "T4":
    case "C1":
    case "C2":
    case "C3":
    case "C4":
    case "C5":
      return "tractor";
    case "L1E":
    case "L2E":
    case "L6E":
    case "MCM":
      return "moped";
    case "L3E":
    case "L4E":
    case "L5E":
    case "L7E":
      return "motorcycle";
    default:
      throw new Error(
        "tekniskKode, ikke implentert, sjekk https://github.com/ebasarlive/ebasar/issues/7#issuecomment-1008051507"
      );
  }
};
const motorerToFuelTypeOption = (motorer) => {
  const uniqueDrivstoffType = [
    ...new Set(motorer.reduce((acc, m) => acc.concat(m.drivstoff.map((ds) => ds.drivstofftype.toLowerCase())), [])),
  ];
  if (uniqueDrivstoffType.length === 1) {
    switch (uniqueDrivstoffType[0]) {
      case "gass":
        return "lpg";
      case "bensin":
        return "gasoline";
      case "diesel":
        return "diesel";
      case "elektrisk":
        return "electrical";
      case "hydrogen":
        return "hydrogen";
      default:
        throw new Error("ukjent driftsofftype");
    }
  } else if (uniqueDrivstoffType.length === 2) {
    if (uniqueDrivstoffType.includes("bensin") && uniqueDrivstoffType.includes("elektrisk")) {
      return "electricalandgasoline";
    }
    if (uniqueDrivstoffType.includes("bensin") && uniqueDrivstoffType.includes("gass")) {
      return "lpgandgasoline";
    }
    if (uniqueDrivstoffType.includes("diesel") && uniqueDrivstoffType.includes("elektrisk")) {
      return "electricalandgasoline";
    }
    if (uniqueDrivstoffType.includes("diesel") && uniqueDrivstoffType.includes("gass")) {
      return "lpganddiesel";
    }
  }
  throw new Error("ukjent driftsofftype");
};

const mapVegvesenToPrefillData = (data) => {
  const kw = data.tekniskKjoretoy.motorer.reduce((acc, m) => (m.effekt > acc ? m.effekt : acc), null);
  return {
    category: tekniskKodeToCategory(data.tekniskKjoretoy.tekniskKode),
    plateNumber: data.kjennemerke,
    vin: data.understellsnummer,
    makeYear: new Date(data.registrering.forstegangsregistrering).getFullYear(),
    make: data.tekniskKjoretoy.merke,
    model: data.tekniskKjoretoy.handelsbetegnelse,
    modelSpecification: null,
    cylinderVolume: data.tekniskKjoretoy.motorer.reduce((acc, m) => (m.slagvolum > acc ? m.slagvolum : acc), null),
    kw,
    hp: kw * 1.341,
    fuelType: motorerToFuelTypeOption(data.tekniskKjoretoy.motorer),
    vehicleType: data.tekniskKjoretoy.tekniskKode,
    co2Emissions: data.tekniskKjoretoy.forbrukOgUtslipp.map((fou) => fou.co2Utslipp),
    batteryCapacity: null, // we dont get that from vegvesen?
    range: data.tekniskKjoretoy.forbrukOgUtslipp.reduce(
      (acc, fou) => (fou.rekkeviddeKm > acc ? fou.rekkeviddeKm : acc),
      null
    ),
    gearbox: data.tekniskKjoretoy.girkasse,
    gearboxOther: null,
    gearboxDesignation: null,
    wheelDrive: null,
    wheelDriveDesignation: null,
    shaftType: null, // lastebil, henger, buss og minibuss relevatn
    wheelbase: null, // in cm Lastebil og Henger Buss og Minibuss
    springTypeFront: null,
    springTypeBack: null,
    additionalBrake: null,
    topSpeed: null,
    mileage: null,
    firsttimeRegistered: dayjs(data.registrering.forstegangsregistrering),
    totalNumbersOwners: null,
    warrantyType: null,
    supplierOfConditionReport: null,
    followedMaintenanceProgram: null,
    numberOfWorkingHours: null,
    roadworthinessTestApproved: null,
    roadworthinessTestApprovalDate: null,
    machineIsCEmarked: null,
    machineHasDeclarationConformity: null,
    price: null,
    reRegistrationFee: null,
    totalPrice: null, //Totalpris= salePrice + reRegistrationFee <- Used in filter
    bodyType: null,
    taxClass: null,
    seatsNumber: data.tekniskKjoretoy.sitteplasser,
    doorNumber: null,
    trunkVolume: null,
    netWeight: data.tekniskKjoretoy.lastegenskaper.egenvekt,
    maxTrailerWeight: data.tekniskKjoretoy.lastegenskaper.tillattTilhengervektMedBrems,
    color: data.tekniskKjoretoy.karosseri.farge,
    colorDescription: null,
    interiorColor: null,
  };
};

const AddVehicle = () => {
  const { user } = useAuth();
  const [plateNumber, setPlateNumber] = useState(null);
  const [vehicleData, setVehicleData] = useState(null);
  const [errorFromVegvesen, setErrorFromVegvesen] = useState(null);
  // eslint-disable-next-line no-constant-condition
  if (!vehicleData) {
    return (
      <div>
        <h1>{errorFromVegvesen}</h1>
        <TextField
          margin="normal"
          variant="outlined"
          required
          fullWidth
          type="text"
          label="Plate Number"
          onChange={(e) => {
            setErrorFromVegvesen(null);
            setPlateNumber(e.target.value);
          }}
        />
        <Button onClick={() => setVehicleData({})} fullWidth variant="contained" color="secondary" size="large">
          I dont have number
        </Button>
        <AsyncButton
          // disabled={formValidation.error || isPosting}
          tooltipTitle={"idno"}
          // onClickWhileDisabled={validateFormStateful}
          // loading={isPosting}
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          // startIcon={<PublishIcon />}
          fullWidth
          onClick={(e) => {
            user.functions.vehiclePrefill(plateNumber).then((data) => {
              if (data.status) {
                setErrorFromVegvesen(data.melding);
              } else {
                setVehicleData(mapVegvesenToPrefillData(data));
              }
            });
          }}
        >
          Ok
        </AsyncButton>
      </div>
    );
  }
  return <RenderAddVehicle vehicle={vehicleData} />;
};
const RenderAddVehicle = ({ vehicle }) => {
  // eslint-disable-next-line no-unused-vars
  const [validateFormPure, validateFormStateful, formProps] = useForm(
    {
      category: initInputValSpecType(vehicle.category || "", "text"),
      plateNumber: initInputValSpecType(vehicle.plateNumber || "", "text"),
      vin: initInputValSpecType(vehicle.vin || "", "text"),
      makeYear: initInputValSpecType(vehicle.makeYear || 0, "number"),
      make: initInputValSpecType(vehicle.make || "", "text"),
      model: initInputValSpecType(vehicle.model || "", "text"),
      modelSpecification: initInputValSpecType(vehicle.modelSpecification || "", "text"),
      cylinderVolume: initInputValSpecType(vehicle.cylinderVolume || 0, "number"),
      kw: initInputValSpecType(vehicle.kw || 0, "number"),
      hp: initInputValSpecType(vehicle.kw || 0, "number"),
      fuelType: initInputValSpecType(vehicle.fuelType || "", "text"),
      fuel: initInputValSpecType(vehicle.kw || 0, "number"),
      vehicleType: initInputValSpecType(vehicle.vehicleType || 0, "text"),
      co2Emissions: initInputValSpecType(vehicle.co2Emissions || 0, "number"),
      batteryCapacity: initInputValSpecType(vehicle.batteryCapacity || 0, "number"),
      range: initInputValSpecType(vehicle.range || 0, "number"),
      gearbox: initInputValSpecType(vehicle.gearbox || 0, "text"),
      gearboxOther: initInputValSpecType(vehicle.gearboxOther || "", "text"),
      wheelDrive: initInputValSpecType(vehicle.wheelDrive || "", "text"),
      shaftType: initInputValSpecType(vehicle.shaftType || "", "text"),
      wheelbase: initInputValSpecType(vehicle.wheelbase || null, "number"),
      springTypeFront: initInputValSpecType(vehicle.springTypeFront || null, "text"),
      springTypeBack: initInputValSpecType(vehicle.springTypeBack || null, "text"),
      additionalBrake: initInputValSpecType(vehicle.additionalBrake || null, "text"),
      topSpeed: initInputValSpecType(vehicle.topSpeed || null, "number"),
      mileage: initInputValSpecType(vehicle.mileage || 0, "number"),
      firsttimeRegistered: initInputValSpecType(vehicle.firsttimeRegistered || "", "text"),
      totalNumbersOwners: initInputValSpecType(vehicle.totalNumbersOwners || 1, "number"),
      warrantyType: initInputValSpecType(vehicle.warrantyType || "", "text"),
      supplierOfConditionReport: initInputValSpecType(vehicle.supplierOfConditionReport || "", "text"),
      followedMaintenanceProgram: initInputValSpecType(vehicle.followedMaintenanceProgram || true, "bool"),
      numberOfWorkingHours: initInputValSpecType(vehicle.numberOfWorkingHours || 0, "number"),
      roadworthinessTestApproved: initInputValSpecType(vehicle.roadworthinessTestApproved || false, "bool"),
      roadworthinessTestApprovalDate: initInputValSpecType(vehicle.roadworthinessTestApprovalDate || null, "datetime"),
      machineIsCEmarked: initInputValSpecType(vehicle.machineIsCEmarked || null, "bool"),
      machineHasDeclarationConformity: initInputValSpecType(vehicle.machineHasDeclarationConformity || null, "bool"),
      price: initInputValSpecType(vehicle.price || 0, "number"),
      reRegistrationFee: initInputValSpecType(vehicle.reRegistrationFee || 0, "number"),
      totalPrice: initInputValSpecType(vehicle.totalPrice || 0, "number"),
      bodyType: initInputValSpecType(vehicle.bodyType || "", "text"),
      seatsNumber: initInputValSpecType(vehicle.seatsNumber || 0, "number"),
      doorNumber: initInputValSpecType(vehicle.doorNumber || 0, "number"),
      trunkVolume: initInputValSpecType(vehicle.trunkVolume || 0, "number"),
      netWeight: initInputValSpecType(vehicle.netWeight || 0, "number"),
      maxTrailerWeight: initInputValSpecType(vehicle.maxTrailerWeight || 0, "number"),
      color: initInputValSpecType(vehicle.color || "", "text"),
      colorDescription: initInputValSpecType(vehicle.color || "", "text"),
      interiorColor: initInputValSpecType(vehicle.color || "", "text"),
    },
    {
      category: { onBlur: isRequired() },
      plateNumber: { onBlur: isRequired() },
      vin: { onBlur: isRequired() },
      makeYear: { onBlur: isNumberBetween(0, 3000) },
      make: { onBlur: isRequired() },
      model: { onBlur: isRequired() },
      modelSpecification: { onBlur: isRequired() },
      cylinderVolume: { onBlur: isNumberBetween(0, 300000) },
      kw: { onBlur: isNumberBetween(0, 300000) },
      hp: { onBlur: isNumberBetween(0, 300000) },
      fuelType: { onBlur: isRequired() },
      vehicleType: { onBlur: isRequired() },
      co2Emissions: { onBlur: isNumberBetween(0, 99999999) },
      batteryCapacity: { onBlur: isNumberBetween(0, 99999999) },
      range: { onBlur: isNumberBetween(0, 99999999) },
      gearbox: { onBlur: isRequired() },
      gearboxOther: {},
      wheelDrive: { onBlur: isRequired() },
      shaftType: { onBlur: isRequired() },
      wheelBase: {},
      springTypeFront: {},
      springTypeBack: {},
      additionalBrake: {},
      topSpeed: {},
      mileage: { onBlur: isNumberBetween(0, 99999999) },
      firsttimeRegistered: { onBlur: isRequired() },
      totalNumbersOwners: { onBlur: isRequired() },
      warrantyType: { onBlur: isRequired() },
      supplierOfConditionReport: { onBlur: isRequired() },
      followedMaintenanceProgram: {},
      numberOfWorkingHours: {},
      roadworthinessTestApproved: {},
      roadworthinessTestApprovalDate: {},
      machineIsCEmarked: {},
      machineHasDeclarationConformity: {},
      price: { onBlur: isNumberBetween(0.0001, 100000000) },
      reRegistrationFee: { onBlur: isNumberBetween(0.0001, 100000000) },
      totalPrice: { onBlur: isNumberBetween(0.0001, 100000000) },
      bodyType: {},
      seatsNumber: { onBlur: isNumberBetween(1, 100000000) },
      doorNumber: { onBlur: isNumberBetween(1, 100000000) },
      trunkVolume: { onBlur: isNumberBetween(1, 100000000) },
      netWeight: { onBlur: isNumberBetween(1, 100000000) },
      maxTrailerWeight: { onBlur: isNumberBetween(1, 100000000) },
      color: {},
      colorDescription: {},
      interiorColor: {},
    }
  );
  const formValues = formPropsToValues(formProps);
  return (
    <Container component="main" maxWidth="md">
      <div>
        {/*<pre>{JSON.stringify(vehicle, null, 4)}</pre>*/}
        <PostAdd fontSize={"large"} />
        <Typography component="h1" variant="h5">
          Create new vehicle
        </Typography>

        <form>
          <SelectWithError
            fullWidth
            inputLabel="category"
            options={vehicleIdentityCategories}
            {...formProps.category}
          />
          <TextField label="plateNumber" color="secondary" {...formProps.plateNumber} />
          <TextField label="vin" {...formProps.vin} />
          <TextField label="Make Year" {...formProps.makeYear} />
          <TextField label="make" {...formProps.make} />
          <TextField label="model" {...formProps.model} />
          <TextField label="modelSpecification" {...formProps.modelSpecification} />
          <TextField label="cylinderVolume" {...formProps.cylinderVolume} />
          <TextField label="kilowatt" {...formProps.kw} />
          <TextField label="horse power" {...formProps.hp} />
          <SelectWithError fullWidth inputLabel="Fuel type" options={fuelTypeOptions} {...formProps.fuelType} />
          <SelectWithError
            fullWidth
            inputLabel="Vehicle type"
            options={vehicleTypeOptions}
            {...formProps.vehicleType}
          />
          <TextField label="co2 emissions" {...formProps.co2Emissions} />
          <TextField label="battery capacity" {...formProps.batteryCapacity} />
          <TextField label="range" {...formProps.range} />
          <SelectWithError fullWidth inputLabel="gearbox" options={gearboxTypes} {...formProps.gearbox} />
          {formValues.gearbox === "other" && <TextField label="gearbox other" {...formProps.gearboxOther} />}
          <SelectWithError fullWidth inputLabel="wheel drive" options={wheelDriveTypes} {...formProps.wheelDrive} />
          {["trailer", "henger", "bus"].includes(formValues.category) && (
            <>
              <SelectWithError fullWidth inputLabel="shaft type" options={shaftTypeOptions} {...formProps.shaftType} />
              <TextField label="wheel base" {...formProps.wheelBase} />
              <SelectWithError
                fullWidth
                inputLabel="Spring Type Front"
                options={springTypeOptions}
                {...formProps.springTypeFront}
              />
              <SelectWithError
                fullWidth
                inputLabel="Spring Type Back"
                options={springTypeOptions}
                {...formProps.springTypeBack}
              />
              <TextField label="additional Brake" {...formProps.additionalBrake} />
            </>
          )}
          {["construction"].includes(formValues.category) && <TextField label="top speed" {...formProps.topSpeed} />}
          <TextField label="mileage" {...formProps.mileage} />
          <DatePickerWrapped label="First time registered" {...formProps.firsttimeRegistered} />
          <SelectWithError fullWidth inputLabel="Warranty Type" options={warrantyOptions} {...formProps.warrantyType} />
          <SelectWithError
            fullWidth
            inputLabel="Supplier condition report"
            options={supplierOfConditionReportOptions}
            {...formProps.supplierOfConditionReport}
          />
          <CheckboxWrapped label="Followed maintenanceprogram" {...formProps.followedMaintenanceProgram} />
          {["tractor"].includes(formValues.category) && (
            <TextField label="number of working hours" {...formProps.numberOfWorkingHours} />
          )}
          {["tractor", "trailer", "henger", "bus"].includes(formValues.category) && (
            <>
              <CheckboxWrapped label="Roadworthiness test approved" {...formProps.roadworthinessTestApproved} />
              {formValues.roadworthinessTestApproved && (
                <DatePickerWrapped
                  label="Roadworthiness test approved date"
                  {...formProps.roadworthinessTestApprovalDate}
                />
              )}
            </>
          )}
          {["construction"].includes(formValues.category) && (
            <>
              <CheckboxWrapped label="Machine is ce marked" {...formProps.machineIsCEmarked} />
              <CheckboxWrapped
                label="Machine has declaration conformity"
                {...formProps.machineHasDeclarationConformity}
              />
            </>
          )}
          <TextField label="Price" {...formProps.price} />
          <TextField label="re registration fee" {...formProps.reRegistrationFee} />
          <TextField label="total price" {...formProps.totalPrice} />
          <SelectWithError fullWidth inputLabel="body type" options={bodyTypeOptions} {...formProps.bodyType} />
          <TextField label="seats number" {...formProps.seatsNumber} />
          <TextField label="door number" {...formProps.seatsNumber} />
          <TextField label="trunk volume" {...formProps.seatsNumber} />
          <TextField label="net weight" {...formProps.seatsNumber} />
          <TextField label="max trailer weight" {...formProps.seatsNumber} />
          <TextField label="color" {...formProps.color} />
          <TextField label="color description" {...formProps.colorDescription} />
          <TextField label="interior description" {...formProps.interiorColor} />
        </form>
      </div>
    </Container>
  );
};
RenderAddVehicle.propTypes = {
  vehicle: VehiclePropType,
};

export default AddVehicle;
