import React, { useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import { captureException } from "@sentry/react";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";
import Link from "../components/Link";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { useAuth } from "../providers/AuthProvider";

let search = window.location.search;
if (!search) search = sessionStorage.getItem("search-param"); // index.html stores in case routing clutters the params

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  outer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100%",
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    marginLeft: "20%",
    marginRight: "20%",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "300px",
  },
  field: {
    width: "300px",
    marginBottom: "0.5rem",
  },
}));

const ConfirmEmail = () => {
  const { app } = useAuth();

  const params = new URLSearchParams(search);
  const token = params.get("token");
  const tokenId = params.get("tokenId");

  const [success, setSuccess] = useState(true);
  const [working, setWorking] = useState(false);
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");
  const [resent, setResent] = useState(false);
  const { t } = useTranslation();

  const confirm = useCallback(async () => {
    setWorking(true);
    if (token && tokenId) {
      try {
        await app.emailPasswordAuth.confirmUser(token, tokenId);
      } catch (err) {
        captureException(err);
        setSuccess(false);
        setMsg(err.message);
      }
    } else {
      setSuccess(false);
      setMsg("missing token or tokenId");
    }
  }, [token, tokenId, app.emailPasswordAuth]);

  const classes = useStyles();

  const resend = async (e) => {
    e.preventDefault();
    try {
      await app.emailPasswordAuth.resendConfirmation(email);

      setResent(true);
    } catch (err) {
      captureException(err);
      setResent(true);
    }
  };
  useEffect(() => {
    confirm();
  }, [confirm]);
  return (
    <article className={classes.outer}>
      <div className={classes.inner}>
        <h1>Confirm email</h1>
        {working ? (
          success ? (
            <p>
              Your email was confirmed, now you can
              <Link color="inherit" component={RouterLink} to="/login">
                {t("Login")}
              </Link>
            </p>
          ) : (
            <div>
              {resent ? (
                <p>Resent verification email to {email}</p>
              ) : (
                <div>
                  <h2>Error</h2>
                  <h4>{msg}</h4>
                  <form className={classes.form} onSubmit={resend}>
                    <TextField
                      className={classes.field}
                      id="email"
                      label="Email"
                      variant="filled"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button className={classes.field} type="submit" variant="contained">
                      Resend confirmation
                    </Button>
                  </form>
                </div>
              )}
            </div>
          )
        ) : (
          <Button onClick={confirm} variant="contained">
            Confirm
          </Button>
        )}
      </div>
    </article>
  );
};

export default ConfirmEmail;
