import * as React from "react";
import Modal from "@mui/material/Modal";
import { useModalProvider } from "../../providers/ModalProvider";

export default function ModalProviderRenderer() {
  const { modalList, doModalAction } = useModalProvider();
  const handleClose = () => {
    doModalAction({ type: "shift" });
  };

  return (
    <Modal open={!!modalList.length} onClose={handleClose}>
      <>{!!modalList.length && modalList[0]}</>
    </Modal>
  );
}
