import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";

export default function RangeSlider({ label, value, ...props }) {
  return (
    <div>
      <Typography id="range-slider" gutterBottom>
        {label}: {value[0]} - {value[1]}
      </Typography>
      <Slider value={value} {...props} />
    </div>
  );
}
RangeSlider.propTypes = { label: PropTypes.string, value: PropTypes.arrayOf(PropTypes.number) };
