import { PropTypes } from "prop-types";
import React from "react";
import Paper from "@mui/material/Paper";
import safeJsonStringify from "safe-json-stringify";
import Alert from "@mui/material/Alert";

const ErrorView = ({ description, error }) => {
  return (
    <Paper>
      <Alert severity="error">
        <h4>Error</h4>
        <p>{description}</p>
        <pre>{safeJsonStringify(error, null, 2)}</pre>
      </Alert>
    </Paper>
  );
};
ErrorView.propTypes = {
  error: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
};

export default ErrorView;
