import React from "react";
import Grid from "@mui/material/Grid";
import { PropTypes } from "prop-types";

const Title = ({ make, model, modelSpecification }) => {
  return (
    <React.Fragment>
      <Grid container direction="column">
        <Grid container>
          <Grid item>{make}</Grid>
          <Grid item>{model}</Grid>
        </Grid>
        <Grid item>{modelSpecification}</Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Title;
Title.propTypes = {
  make: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  modelSpecification: PropTypes.string,
};
