import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { PropTypes } from "prop-types";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Description = ({ description }) => {
  const [readMore, setReadMore] = useState(false);

  return (
    <React.Fragment>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
            Desciption{" "}
          </Typography>
        </Grid>
        <Grid item>
          {" "}
          <Grid item>
            {readMore ? description : description.substr(0, 400)}
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setReadMore(!readMore);
              }}
            >
              {readMore ? "Read less" : " ...Read more"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Description;
Description.propTypes = {
  description: PropTypes.string,
};
