import React, { useCallback, useReducer, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { PostAdd } from "@mui/icons-material";
import PublishIcon from "@mui/icons-material/Publish";
import useForm, { formPropsToValues, initialInputValue } from "../form/useForm";
import { useHistory } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { isNumberBetween, isRequired, isStringBetween, isValidDate } from "../form/validators";
import categories from "../categories";
import SelectWithError from "../components/SelectWithError";
import AsyncButton from "../components/AsyncButton";
import { here_api_key } from "../staticVars";
import LocationPickerInput from "../components/LocationPickerInput";
import UploadImage from "../components/UploadImage";
import ReArrangeableImageRoll from "../components/ReArrangeableImageRoll";
import { deleteObjWithKeyPrefix } from "../utils/collectionCache";
import { useInsertOne, useUpdateOne } from "../db/hooks";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
import { itemPropType } from "../utils/sharedPropTypes";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textarea: {
    width: "100%",
    height: "100%",
  },
}));

const getLngLat2 = async (id) => {
  const url = new URL("https://autocomplete.search.hereapi.com/v1/lookup");
  const params = { id, apiKey: here_api_key };
  Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value));
  const response = await fetch(url);
  const responseAsJson = await response.json();
  return [responseAsJson.position.lng, responseAsJson.position.lat];
};

const AddItem = ({ item }) => {
  const [isAuction, setIsAuction] = useState(false);
  const [insertOne, isInserting] = useInsertOne("items", "AddItem-insertOne-oneshot");
  const [updateOne, isUpdating] = useUpdateOne("items", "AddItem-updateOne-oneshot");
  const classes = useStyles();
  const { user, ensureUsername } = useAuth();
  const [images, doImagesOperation] = useReducer((images, imagesOperation) => {
    let newImages = [];
    if (imagesOperation.action === "add") {
      newImages = images.concat(imagesOperation.payload);
    } else if (imagesOperation.action === "reset") {
      newImages = imagesOperation.payload;
    } else {
      throw Error(`unknown imagesOperation: ${JSON.stringify(imagesOperation, null, 4)}`);
    }
    return [...new Set(newImages)];
  }, [...new Set(item?.images)] || []);
  const isPosting = isUpdating || isInserting;

  const [validateFormPure, validateFormStateful, formProps] = useForm(
    {
      title: initialInputValue(item?.title || ""),
      description: initialInputValue(item?.description || ""),
      price: initialInputValue(item?.price || 0),
      location: initialInputValue(null),
      category: initialInputValue(item?.category || "Clothing"),
      subCategory: initialInputValue(item?.subCategory || ""),
      auctionEnds: initialInputValue(item?.auctionEnds || new Date().toISOString()),
    },
    {
      title: { onBlur: isStringBetween(3, 100) },
      description: { onBlur: isStringBetween(10, 300) },
      price: { onBlur: isNumberBetween(0.0001, 100000000) },
      location: { onBlur: isRequired() },
      category: { onBlur: isStringBetween(1, 100) },
      subCategory: { onBlur: isStringBetween(1, 100) },
      auctionEnds: { onBlur: isValidDate() },
    }
  );
  const formValidation = validateFormPure();

  const formValues = formPropsToValues(formProps);
  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isPosting) {
      return;
    }
    const coordinates = await getLngLat2(formValues.location.id);
    // we want to move locationkey to the top object
    const { location, auctionEnds, ...restOfFormValues } = formValues;
    const { label, ...restOfAddress } = location.address;
    const newItem = {
      ...restOfFormValues,
      ...restOfAddress,
      ...(isAuction ? { auctionEnds } : {}),
      addressLabel: label,
      user_id: user.id,
      username: await ensureUsername(),
      images,
      location: { type: "Point", coordinates },
      likedBy: [],
    };
    if (item) {
      await updateOne({ _id: item._id }, newItem);
    } else {
      await insertOne(newItem);
    }
    deleteObjWithKeyPrefix("items");
    history.push("/list-items");
  };
  return (
    <Container component="main" maxWidth="md">
      <div className={classes.paper}>
        <PostAdd fontSize={"large"} />
        <Typography component="h1" variant="h5">
          Create new advertisement
        </Typography>

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField variant="outlined" required fullWidth label="Title" color="secondary" {...formProps.title} />
          <TextField
            margin="normal"
            variant="outlined"
            required
            fullWidth
            label="Description"
            multiline
            rows={10}
            className={classes.textarea}
            {...formProps.description}
          />

          <TextField
            margin="normal"
            variant="outlined"
            required
            fullWidth
            type="number"
            label="Price"
            {...formProps.price}
          />
          <LocationPickerInput
            {...formProps.location}
            label="location"
            required
            initialInputValue={item?.addressLabel || ""}
          />
          <SelectWithError
            required
            inputLabel="category"
            fullWidth
            options={Object.keys(categories)}
            {...formProps.category}
          />

          <SelectWithError
            required
            inputLabel="subcategory"
            fullWidth
            options={categories[formValues.category]}
            {...formProps.subCategory}
          />
          <FormControlLabel
            control={<Checkbox checked={isAuction} onChange={(e) => setIsAuction(e.target.checked)} name="isAuction" />}
            label="Is auction"
          />
          {isAuction && (
            <TextField
              label="Auction ends:"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              {...formProps.auctionEnds}
            />
          )}
          <h1>Images</h1>
          <ReArrangeableImageRoll
            images={images}
            updateImages={(newImages) => doImagesOperation({ action: "reset", payload: newImages })}
          />
          <UploadImage
            onImageUploaded={useCallback(
              (result) => doImagesOperation({ action: "add", payload: result.info.url }),
              []
            )}
          />
          <br />
          <AsyncButton
            disabled={formValidation.error || isPosting}
            tooltipTitle={formValidation.msg}
            onClickWhileDisabled={validateFormStateful}
            loading={isPosting}
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            className={classes.button}
            startIcon={<PublishIcon />}
            fullWidth
          >
            Publish
          </AsyncButton>
        </form>
      </div>
    </Container>
  );
};
AddItem.propTypes = {
  item: itemPropType,
};

export default AddItem;
