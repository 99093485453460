export const boatSample = {
  registrationNumber: "ABCDEF123", //The boat's identification mark in the Small Boat or Ship Register.
  boatType: "bowrider",
  makeYear: 2015, // Four digit year
  boatBrand: "ABYACHT",
  boatModel: "Boat Model",
  enginIncluded: true,
  engineType: "Engin type",
  engineBrand: "Engin brand",
  enginPorwre: 120, //In Hosre Power
  maxSpeed: 120, // in knots (optional)
  fuel: "gasolin",
  length: 120, // in feet
  width: 20, // in cm (optional)
  depth: 5, // in cm (optional)
  weight: 200, // in kg (optional)
  material: "aluminium",
  color: "Red",
  numberOfSeats: 3,
  numberOfBeds: 4,
  lightNumbers: 4, // (only for sailboats)
  equipment: "Equipments bla bla",
};
