import React from "react";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { fakeEvent } from "../form/useForm";

const SelectWithError = ({
  allowDeselect,
  onChange,
  required,
  inputLabel,
  fullWidth,
  error,
  helperText,
  options,
  ...props
}) => {
  return (
    <Grid container>
      <Grid item style={{ flexGrow: 1 }}>
        <FormControl fullWidth={fullWidth} required={required} variant="outlined">
          {error ? helperText : ""}
          <InputLabel>{inputLabel}</InputLabel>
          <Select onChange={onChange} {...props}>
            {options.map((c) => (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {allowDeselect && (
        <IconButton color="inherit" onClick={() => onChange(fakeEvent(options.name, ""))} size="large">
          <Grid item>
            <ClearIcon />
          </Grid>
        </IconButton>
      )}
    </Grid>
  );
};
SelectWithError.propTypes = {
  onChange: PropTypes.func,
  allowDeselect: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputLabel: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  options: PropTypes.array,
};
export default SelectWithError;
