import React, { useReducer } from "react";
import { useAuth } from "../providers/AuthProvider";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Link from "../components/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
//import { useAuth } from "../providers/AuthProvider";
//import { useHistory } from "react-router-dom";
// import EmailConfirmModal from "../components/modals/EmailConfirmModal";
import GoogleFacebookLogin from "./GoogleFacebookLogin";
import PropTypes from "prop-types";
import { useModal } from "../utils/modalHooks";
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" to="/">
        ebasar.no
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignUp(props) {
  const { registerNewUser } = useAuth();
  const { showSuccess, showError } = useModal();
  const [accountInfo, setAccountInfo] = useReducer((state, { target }) => ({ ...state, [target.name]: target.value }), {
    email: "",
    password: "",
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    registerNewUser(accountInfo.email, accountInfo.password)
      .then(() => {
        showSuccess("User created", `user with email ${accountInfo.email} created`);
        props.changeType("in");
      })
      .catch((e) => showError("There was an issue creating the user", e));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={setAccountInfo}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={setAccountInfo}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography ariant="h6">
                By proceeding, you agree to eBasar{"'"}{" "}
                <Link to="/" variant="body2">
                  Terms of Use
                </Link>{" "}
                and{" "}
                <Link to="/" variant="body2">
                  Privacy Policy.
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <GoogleFacebookLogin />
            </Grid>
            <Grid item>
              <Link component="button" onClick={() => props.changeType("in")} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
SignUp.propTypes = {
  changeType: PropTypes.func,
};
