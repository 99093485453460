import PropTypes from "prop-types";
import { ObjectID } from "bson";

// All gategories
export const mediaPropType = {
  images: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  videoURL: PropTypes.string,
};

export const contactInfoPropType = {
  phone: PropTypes.number,
  address: PropTypes.string,
  postcode: PropTypes.number,
  land: PropTypes.string,
  showProfile: PropTypes.bool,
};

export const auctionPropType = {
  auctionEnds: PropTypes.string,
};
export const customDataPropType = {
  username: PropTypes.string,
};

export const itemPropType = PropTypes.shape({
  _id: PropTypes.instanceOf(ObjectID).isRequired,
  // adType: PropTypes.oneOf(["sell", "buy", "giveAway"]).isRequired, // giveaway = price is zero, buy we dont support
  // condition: PropTypes.oneOf(["new", "used"]).isRequired, // not yet supported
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  subCategory: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  ...mediaPropType,
  ...contactInfoPropType,
  ...auctionPropType,
});
export const vehicleIdentityCategories = [
  "car",
  "mc",
  "ATV",
  "boat",
  "carvan",
  "trailer",
  "henger",
  "bus",
  "construction",
  "agricultural",
  "tractor",
];
//************  Vehicle ************/
const vehicleIdentityPropTypes = {
  // _id: PropTypes.instanceOf(ObjectID).isRequired,
  category: PropTypes.oneOf(vehicleIdentityCategories),
  plateNumber: PropTypes.string, //Registreringsnummer-v
  vin: PropTypes.string, // Vehicle identification number
  makeYear: PropTypes.number.isRequired,
  make: PropTypes.string.isRequired, //Merke
  model: PropTypes.string.isRequired, //Modell
  modelSpecification: PropTypes.string, //Modellspesifikasjon-v
  // locationCountry: PropTypes.string, this seems useless?
};
export const fuelTypeOptions = [
  "gasoline",
  "diesel",
  "electrical",
  "electricalandgasoline",
  "electricalanddiesel",
  "lpg",
  "lpgandgasoline",
  "lpganddiesel",
  "hydrogen",
];
export const gearboxTypes = ["M", "A", "Opticruise", "Optidrive", "other"];
export const wheelDriveTypes = ["Rear", "Front", "Four"];
export const shaftTypeOptions = ["4x2", "4x4", "6x2", "6x2/4", "6x4", "8x2", "8x4"];
export const springTypeOptions = ["parabel", "air", "steel"];
export const vehicleEngineAndFuel = {
  cylinderVolume: PropTypes.number, //This can be found in the vehicle card under Engine / drive. Ex: 2 500cm3 = 2.5 L
  kw: PropTypes.number, // Can be translated to hp by multiplying with 1.341
  hp: PropTypes.number,
  fuelType: PropTypes.oneOf(fuelTypeOptions),
  co2Emissions: PropTypes.number, //g / km
  batteryCapacity: PropTypes.number, // kWh (if fueltype electrical, electricalanddiesel, electricalandgasoline)
  range: PropTypes.number, //Rekkevide in km (if fueltype electrical, electricalanddiesel, electricalandgasoline)
  gearbox: PropTypes.oneOf(gearboxTypes), // Bus minibus can have Manule, Automatic, Opticruise or Optidrive.
  gearboxOther: PropTypes.string, // use this if gearbox  other is chosen
  // gearboxDesignation: PropTypes.string, //F.eks: Steptronic, 4-MATIC eller Manuell 6 gir.
  wheelDrive: PropTypes.oneOf(wheelDriveTypes),
  // wheelDriveDesignation: PropTypes.string,
  shaftType: PropTypes.oneOf(shaftTypeOptions), // For Lastebil og henger  Buss og Minibuss (Trailer, bus, henger)
  wheelbase: PropTypes.number, // in cm Lastebil og Henger Buss og Minibuss(Trailer, bus, henger)
  springTypeFront: PropTypes.oneOf(springTypeOptions), //Lastebil og henger Buss og Minibuss(Trailer, bus, henger)
  springTypeBack: PropTypes.oneOf(springTypeOptions), //Lastebil og henger Buss og Minibuss(Trailer, bus, henger)
  additionalBrake: PropTypes.string, //Lastebil og henger Buss og Minibuss-> Indicate brand or type if this is fitted.(Trailer, bus, henger)
  topSpeed: PropTypes.number, // in km/t for Bygg og Anlegg(construction)
};
//Condition and Warranty / Tilsand
export const warrantyOptions = ["newCarWarranty", "usedCarWarranty"];
export const supplierOfConditionReportOptions = ["NAF", "BMW", "Shake", "Viking"];
export const vehicleConditionPropType = {
  mileage: PropTypes.number,
  firsttimeRegistered: PropTypes.string,
  totalNumbersOwners: PropTypes.number,
  warrantyType: PropTypes.oneOf(warrantyOptions),
  supplierOfConditionReport: PropTypes.oneOf(supplierOfConditionReportOptions),
  followedMaintenanceProgram: PropTypes.bool, // For Car Bobil	MC	Moped	ATV	Snøscooter
  numberOfWorkingHours: PropTypes.number, //Traktor og Tresker have only this attribute
  roadworthinessTestApproved: PropTypes.bool, // For Lastebil og henger	Buss og Minibuss
  roadworthinessTestApprovalDate: PropTypes.instanceOf(Date), //For Lastebil og henger	Buss og Minibuss
  machineIsCEmarked: PropTypes.bool, // For bygg og anlegg
  machineHasDeclarationConformity: PropTypes.bool, // Maskinen har samsvarserklæring For bygg og anlegg
};

export const vehiclePricePropType = {
  price: PropTypes.number, //i kr - eks. omregistrering (this is already defined elsewhere)
  reRegistrationFee: PropTypes.number,
  totalPrice: PropTypes.number, //Totalpris= salePrice + reRegistrationFee <- Used in filter
  //Totalpris= salePrice + reRegistrationFee <- Used in filter
};
export const leasingInfoPropType = {
  startRentDeposit: PropTypes.number, // Startleie/innskudd i NOK
  monthlyAmount: PropTypes.number, //Månedsbeløp i kr
  remainingDrivingDistance: PropTypes.number, // Gjenværende kjøredistanse -v
  debtorChangeFee: PropTypes.number, // // Debitorskifte - gebyr i NOK
  agreementDuration: PropTypes.oneOf(["Date", "NumberOfTerms"]), // Leasingavtalens varighet -v
  agreementExpirationDate: PropTypes.instanceOf(Date), // Avtalens utløpsdato -v Date
  agreementNumberOfTermsLeft: PropTypes.number,
  leasingCompany: PropTypes.string, // Leasingselskap -v
  submissionPlace: PropTypes.string, // Sted for innlevering -v
};
export const vehicleTypeOptions = [
  "M1",
  "M2",
  "M3",
  "N1",
  "N2",
  "N3",
  "O1",
  "O2",
  "O3",
  "O4",
  "T1",
  "T2",
  "T3",
  "T4",
  "T5",
  "C1",
  "C2",
  "C3",
  "C4",
  "C5",
  "L1e",
  "L2e",
  "L3e",
  "L4e",
  "L5e",
  "L6e",
  "L7e",
  "R1",
  "R2",
  "R3",
  "R4",
  "BB",
  "BM",
  "MCM",
  "MCL",
  "MCT",
  "MR",
  "TR",
  "TT",
  "A",
  "B",
  "1",
  "2",
  "3",
  "a",
  "b",
  "T4.1",
  "T4.2",
  "T4.3",
  "C4.1",
  "V1",
  "V2",
  "V3",
  "B1",
  "B2",
  "MT",
  "SA",
  "U11",
  "G",
];
export const vehicleType = {
  vehicleType: PropTypes.oneOf(vehicleTypeOptions),
};
export const bodyTypeOptions = [
  "cabriolet",
  "coupe",
  "combi-3door",
  "combi-5door",
  "mpv",
  "pickup",
  "offroad",
  "sedan",
  "stationwagon",
  "van",
  "other",
];
export const vehicleBodyPropType = {
  bodyType: PropTypes.oneOf(bodyTypeOptions).isRequired,
  // taxClass: PropTypes.oneOf(["combined-car", "delivery-truck", "light-lorry", "minibus", "passenger-car", "other"])
  //   .isRequired, // skip tax ?
  seatsNumber: PropTypes.number.isRequired,
  doorNumber: PropTypes.number,
  trunkVolume: PropTypes.number, //liters
  netWeight: PropTypes.number, //kg
  maxTrailerWeight: PropTypes.number, //kg
  color: PropTypes.oneOf([
    "white",
    "black",
    "gray",
    "silver",
    "red",
    "blue",
    "brown",
    "green",
    "beige",
    "orange",
    "gold",
    "yellow",
    "purple",
  ]).isRequired,
  colorDescription: PropTypes.string, //For example: Gray metallic, Sapphire black, Bursting Blue, Deep Black.
  interiorColor: PropTypes.string,
};
export const caravanRvPropType = {
  netWeight: PropTypes.number, // in kg
  totalWeight: PropTypes.number, //in kg
  length: PropTypes.number, //in cm -v
  internalLength: PropTypes.number, //in cm -v
  width: PropTypes.number, // in cm -v
  numberOfRegisteredSeats: PropTypes.number,
  numberOfBeds: PropTypes.number,
  bedType: PropTypes.number,
};
// MC	Moped	ATV	Snøscooter	Traktor	Tresker
export const mcLikeEngineAndChassis = {
  engineDisplacement: PropTypes.number, // is the measure of the cylinder volume//in ccm -v
  power: PropTypes.number, // In Horse Power
  weight: PropTypes.number, // in kg -v
  colour: PropTypes.string,
  wheelFront: PropTypes.bool, // Traktor
};
export const mcMopedQuadbikeSnowmobileGearProptypes = PropTypes.shape({
  make: PropTypes.oneOf([
    "aie",
    "aima",
    "ajp",
    "ajs",
    "argo",
    "atz",
    "access",
    "adiva",
    "adly",
    "aenon",
    "aeon",
    "aero",
    "agirra",
    "aixam",
    "allroad",
    "alpina",
    "altina",
    "american iron horse",
    "andre",
    "apex",
    "aprilia",
    "arctic-cat",
    "atala",
    "atex",
    "bmw",
    "bsa",
    "bajaj",
    "baotian",
    "barossa",
    "benelli",
    "benghe",
    "beta",
    "big dog",
    "bimota",
    "bombardier",
    "bosshoss",
    "bourget",
    "brudeli",
    "buell",
    "bullit",
    "bultaco",
    "bunker trike",
    "cfmoto",
    "cpi",
    "cagiva",
    "can-am",
    "casalini",
    "cectek",
    "chatenet",
    "classic",
    "club cadet",
    "cobra",
    "comarth",
    "corvus",
    "crescent",
    "dtv shredder",
    "daelim",
    "dayun",
    "derbi",
    "diblasi",
    "dinli",
    "ducati",
    "e-ton",
    "electric motion",
    "emc",
    "enduro",
    "energica",
    "estrima",
    "euroscooter",
    "evoride",
    "eznen",
    "f7",
    "fh",
    "fym",
    "fantic",
    "furia",
    "gamax",
    "garelli",
    "gasgas",
    "generic",
    "gilera",
    "goes",
    "govecs",
    "hanway",
    "harley-davidson",
    "hero",
    "highlander",
    "hisun",
    "honda",
    "hooper",
    "horwin",
    "husaberg",
    "husqvarna",
    "hyosung",
    "indian",
    "italjet",
    "jcb",
    "jawa/cz",
    "jinling",
    "john deere",
    "jonwayviper",
    "jotagas",
    "junak",
    "ktm",
    "kainuo",
    "kawasaki",
    "kayo",
    "kazuma",
    "keeway",
    "kinroad",
    "knievel_cycles",
    "kubota",
    "kudaki",
    "kymco",
    "lambretta",
    "lauge jensen",
    "laverda",
    "lem",
    "lifan",
    "ligier",
    "lingben",
    "linhai",
    "loncin",
    "lynx",
    "mev",
    "mgb",
    "mv agusta",
    "mz",
    "mademoto",
    "malaguti",
    "mancini",
    "maxgass",
    "maxus",
    "mega",
    "megelli",
    "meijs",
    "microcar",
    "mikilon",
    "mobelli",
    "mondial",
    "moto guzzi",
    "moto morini corsarino",
    "moto zeta",
    "motonordic",
    "motormoro",
    "motorbike",
    "motorhispania",
    "motrac",
    "niu",
    "nsu",
    "norton",
    "nzita",
    "ockelbo",
    "odes",
    "oset",
    "ossa",
    "panthera",
    "paoletti",
    "paxter",
    "peugeot",
    "pgo",
    "piaggio",
    "pioneer",
    "pitpro",
    "polaris",
    "proffy",
    "puch",
    "qingqi",
    "qooder",
    "qroad",
    "quaddy",
    "quadro qooder",
    "quark",
    "ram",
    "redline",
    "regal raptor",
    "rieju",
    "romet",
    "enfield",
    "smc",
    "swm",
    "sym",
    "sachs",
    "saxon",
    "scorpa",
    "secma",
    "segway",
    "senda",
    "sherco",
    "simson",
    "skandic",
    "ski-doo",
    "skyteam",
    "somoto",
    "sonik",
    "starway",
    "streetsmart",
    "super moto",
    "super soco",
    "surron",
    "suzuki",
    "tgb",
    "tm",
    "trs motorcycles",
    "taotao",
    "tazzari",
    "tempo",
    "thunder_by_city_wheels",
    "thundercat",
    "tms",
    "tomos",
    "triumph",
    "tromox",
    "v3",
    "v8_choppers",
    "vertigo",
    "vga",
    "voge",
    "vectra",
    "veli",
    "vespa",
    "viarelli",
    "victory",
    "x-pro",
    "xel",
    "xway",
    "yamaha",
    "yamoto",
    "zero",
    "znen",
    "zundap",
    "4speed",
    "4wmoto",
  ]),
  mcType: PropTypes.oneOf([
    "chopper",
    "classic",
    "crossendurotrial",
    "cruiser",
    "custom",
    "lightmc",
    "offroadmotard",
    "scooterMc",
    "sidecar",
    "sport",
    "touring",
    "trike",
    "veteran",
    "other",
  ]),
  mopedType: PropTypes.oneOf(["moped", "scooter"]),
  gearType: PropTypes.oneOf([
    "indoor_mechanization",
    "grasstool",
    "plenogparkmaskiner",
    "potatotool",
    "wood_equipment",
    "soiltool",
    "spraytool",
    "sow_and_fertilizer",
    "tools_accessories",
    "transport_utility",
    "road_maintenance_equipment",
    "miscellaneous",
  ]),
});
export const MC = {
  registrationNumber: PropTypes.string,
  ...mcMopedQuadbikeSnowmobileGearProptypes.make,
  model: PropTypes.string,
  ...mcMopedQuadbikeSnowmobileGearProptypes.mcType,
  modelYear: PropTypes.number,
};
//Lastebil og henger	Buss og Minibuss
export const loadCapacityTruckTrailerBusMinibus = {
  cargoSpaceVolumee: PropTypes.number, // in m3 -v //    Lasteromsvolum i m3 -v
  cabinetTypee: PropTypes.number, //    Skaptype -v
  cabinetLengthe: PropTypes.number, // in meters -v
  internalLengthe: PropTypes.number, // Innvendig lengde i cm -v
  internalWidthe: PropTypes.number, // in cm -v
  rearlifte: PropTypes.number, //Bakløft > Bus og minibus
};

export const equipmentCar = PropTypes.oneOf([
  //Exterior (optional) -v
  "summerTires",
  "winterTires",
  "metallicPaint",
  "allSeasonTires",
  "towBar",
  "towBarRemovable",
  "loadHoldersSkiStand",
  "LettmetFelgSommer",
  "LettmetFelgVinter",
  "roofRails",
  "xenonLights",
  "ledLight",
  "laserLight",
  //Driver assistance
  "highBeamAssistant",
  "lightSensor",
  "parkingSensorBack",
  "parkingSensorFront",
  "rainSensor",
  "rearViewCamera",
  //Interior:
  "powerWindows",
  "electricMirror",
  "sunRoofGlass",
  "darkRearWindShields",
  "seatPartlyLeather",
  "seatWholeLeather",
  "sportSeats",
  //Comfort (optional)
  "heatedSeats",
  "airConditioning",
  "cruiseControl",
  "cruiseControlAdaptive",
  "AirCondition",
  "trunkCover",
  "electricSeatMMemory",
  "electricSeatUMemory",
  "interiorHeater",
  "airSuspension",
  "centeralArmrests",
  "engineHeater",
  "levelControl",
  "keyless",
  //Media / Entertainment -v
  "cdPlayer",
  "cassettePlayer",
  "auxInput",
  "bluetooth",
  "handsfreeScheme",
  "headUpDisplay",
  "multifunctionSteeringwheel",
  "navigationSystem",
  "originalPhone",
  "radioDAB",
  "radioFM",
  "tvScreenBakseat",
  //Engine / drive -v
  "powerSteering",
  "antiSpin",
  "diffLock",
  "antiSkid",
  "dieselParticulateFilter",
  "tripComputer",
  //Security (optional)
  "centralLocking",
  "airbagForan",
  "airbagSide",
  "absBrakes",
  "alarm",
  "carTrackingSystem",
  "isofix",
  "immobilizer",
]);
//Equipment MC	Moped	ATV	Snøscooter
export const equipmentMopedQuadbikeSnowmobilMC = PropTypes.oneOf([
  "abs",
  "tractionControl",
  "heatSinks", //Varmeholker
  "seatHeater", //Varmesete
  "outlet12V",
  "speakers",
  "sideBags",
  "radioCdMp3",
  "handleProtectors",
  "tankpad",
  "tankCover",
  "tankBag",
  "topBag",
  "forkBag",
  "windshield", //Vindskjerm
  "alarm",
  "luggageTray",
  "sunCover", //Solodeksel
  "rollerBlocks", // Velteklosser
  "cupHolder",
  "extraCoats", //Ekstra kåper
  "intercom",
  "cover", //Overtrekk
  "navigationSystem",
  "powerPlant", //Effektanlegg
  "xenonLight",
  "cruiseControl",
  "tripComputer",
  "towingHook",
  "reverse",
  "autShockAbsorberAdjustment", // Aut. demperjustering
  "centerSupport", // Midtstøtte
  "ledLights",
]);
export const equipmentCarvan = PropTypes.oneOf([
  "aluRims",
  "cruiseControl",
  "animalFree",
  "fixedToilet",
  "awningSummer", //Fortelt Sommer
  "awningWinter",
  "freezer",
  "gasOutlet",
  "underfloorHeating",
  "refrigerator",
  "microwaveOven",
  "radioCd",
  "radioBox",
  "rearViewCamera",
  "nonSmoking",
  "centralHeating",
  "summerWheels",
  "oven",
  "bicycleRack",
  "roofGateLadder",
  "tvAntenna",
  "waterborneHeat",
  "hotWater",
  "winterWheels",
]);
// Bobil / Recreational vehicle
export const equipmentRV = PropTypes.oneOf([
  //exteriorOptionalV
  "summerTires",
  "winterTires",
  "wholeyearTire",
  "alloyWheels", //Lettmetalfelg
  "supportLegs", //Støtteben
  "awning", //Markise
  "roofGateLadder", //Takgrind
  "towingHitch",
  "bicycleRack", //Sykkel Stativ

  //driverAssistanceV
  "cruiseControl",
  "tripComputer",
  "navigationSystem",
  "parkingSensorFront",
  "rearViewCamera",
  //interior
  "electricWindows",
  "electricMirror",
  "gasOutlet",
  "oven",
  "leatherInterior",
  "refrigerator",
  "looseRugs",
  "nonSmoking",
  "theMicrowave",
  //comfort
  "airSuspension",
  "airConditioningCarPart",
  "airConditioningLivingArea",
  "electricHeating",
  "airHeating",
  "underfloorHeating",
  "waterborneHeat", //Vannbåren varme
  "additionalHeatingEgVebasto",
  "hotWater",
  "frostFreeWaterTank",
  "frostFreeWastewaterTank",
  "fixedToilet",
  "mosquitoDoor",
  //mediaEntertainment
  "radioCd",
  "tvAntenna",
  "manualDish",
  "automaticDishSatelite",
  "tv",
  "dvd",
  //engineDrive
  "antiSpin",
  "diffSperre",
  "antiScratch",
  "dieselParticleFilter",
  "centralLocking",
  //securityOptional
  "gasAlarm",
  "carAlarm",
  "absBreaks",
  "airbagDriver",
  "airbagPassenger",
]);
export const equipmentTractor = PropTypes.oneOf([
  "completeFrontLoader",
  "bracketAndHydraulicCentersForFrontLoaders",
  "frontHydraulics",
  "frontPowerOutlet",
  "clutchFreeReversingGear",
  "fastGear", //Hurtiggir
  "powershiftTransmission",
  "steplessTransmission", //Trinnløs transmisjon
  "electronicHydraulics",
  "hydraulicTrailerBrake",
  "airConditioning",
  "passengerSeat",
  "rearWindowCleaner",
  "resilientFrontAxle", //Fjernede framaksel
  "resilientCabin", //Fjærende hytte
  "reversibleDriverSeat",
]);

export const equipmentTresker = PropTypes.oneOf([
  "quicklyConnectedCuttingTable", //Hurtigkoblet skjærebord
  "cuttingTableTrolley", //Skjærebordsvogn
  "foldingCuttingTable", //Sammenfellbart skjærebord
  "automaticCuttingTableControl", //Automatisk skjærebordskontroll
  "automaticThreshingSetting", //Automatisk treskeverkinnstilling
  "automaticDrainageOfThreshingSlopes", //Automatisk avvatring av tresker i sidehelling
  "gameIndicator", //Spillindikator
  "areaMeter", //Arealmåler
  "cropRegistrar", //Avlingsregistrator
  "humidityMeter", //Fuktighetsmåler
  "strawCutter", //Halmkutter
  "remoteControlRegulationOfSpreadingVanes", //Fjernbetjent regulering av spredevinger
  "airConditioning", //Aircondition;
  "passengerSeat", //Passasjersete
  "fourWheelDrive", //Firehjulstrekk
]);
export const equipmentTruckHanger = PropTypes.oneOf([
  "shaftPressureDistributes", //Akseltrykk fordeler
  "bogieAxleLift", //Boggi -akselløft
  "diffBar", // Diff sperre
  "extraLight",
  "refrigerator",
  "climateControl",
  "comfortChair",
  "powerOutlet", //KraftUttal
  "interiorHeater",
  "luxuryChair",
  "navigation",
  "hubReduction", //Boggi -akselløft
  "radioCd",
  "robsondrive", //Robsondrive
  "centralLubricationSystem", //Sentralsmøreanlegg
  "spoilerSet", //Spoilersett
  "swivelDisc", //Svingskive
  "tipHydraulics", //Tipp hydraulikk
]);
export const equipmentBusMinibus = PropTypes.oneOf([
  "extraLight",
  "refrigerator",
  "climateControl",
  "comfortChair",
  "interiorHeater",
  "luxuryChair",
  "navigation",
  "radioCd",
]);

export const boatPropTypes = {
  registrationNumber: PropTypes.string, //The boat's identification mark in the Small Boat or Ship Register.
  boatType: PropTypes.oneOf([
    "bowrider",
    "sailboats",
    "openboats",
    "speedboat",
    "snekke",
    "waterjet",
    "cabincruiser",
    "daycruiser",
    "dinghy",
    "pilothouse",
    "rib",
    "yacht",
    "commercialvessel",
    "other",
  ]),
  makeYear: PropTypes.number, // Four digit year
  boatBrand: PropTypes.oneOf([
    "ab inflatables",
    "ABYACHT",
    "aga",
    "al",
    "amt",
    "absolute",
    "accent",
    "achilles",
    "adrenaline",
    "adriatic",
    "adventurer",
    "Aermarine",
    "agder",
    "aicon",
    "aironmarine",
    "airship",
    "airship ribs",
    "akerø",
    "albatross",
    "albin",
    "alfa",
    "aliboats",
    "alicraft",
    "Alloycraft",
    "allures",
    "Almarcraft",
    "alo",
    "alphaboat",
    "ALUMARIN",
    "AluVenture",
    "alubat",
    "alucraft",
    "alufish",
    "alukin",
    "Alunaut",
    "Alunautic",
    "alutec",
    "ancas queen",
    "andre",
    "andunge",
    "andy",
    "Antaris",
    "anytec",
    "aphrodite",
    "apollo",
    "Aqua",
    "aquador",
    "aquamar",
    "aquamarine",
    "aquapro",
    "aquaquad",
    "aquaquick",
    "AQUARIUS",
    "AQUASPIRIT",
    "aquatron",
    "arcona",
    "arctic",
    "artic blue",
    "ardea",
    "arendalsnekke",
    "argo",
    "arimar",
    "arksund",
    "armor",
    "arronet",
    "Artekno",
    "artic",
    "arvor",
    "askeladden",
    "ASPEN",
    "astondoa",
    "athena",
    "athena 38",
    "atlantic",
    "atlantic marine",
    "atlantis",
    "atomix",
    "Australian high performance",
    "avance",
    "avanti",
    "aventura",
    "avon",
    "awtish",
    "axopar",
    "azimut",
    "azure",
    "azzurro",
    "baja",
    "bb",
    "bic",
    "BMB",
    "bowi serai",
    "brig",
    "brp",
    "bwa",
    "bakke",
    "ballistic",
    "balt",
    "baltic",
    "bandholm",
    "banner",
    "barcon",
    "baretta",
    "barracuda",
    "bavaria",
    "bayliner",
    "Becker",
    "BELASSI",
    "bell",
    "bella",
    "hsr-benelli",
    "beneteau",
    "Bennington",
    "Berika",
    "BERKUT",
    "bertram",
    "BestMarin",
    "bestyear",
    "bever",
    "biam",
    "bianca",
    "birchwood",
    "black fin",
    "BLACKWATER",
    "blesser",
    "Blue Spirit",
    "Bluesea",
    "BLUSAIL",
    "boheme",
    "bolero",
    "bombard",
    "bonanza",
    "boston whaler",
    "bostrøm",
    "bowrider",
    "BRABUS-MARINE",
    "Braveboat",
    "Brenta",
    "brioni",
    "brizo_yachts",
    "broom",
    "bryant",
    "bryza",
    "bumerang",
    "buster",
    "båtsmann",
    "candc",
    "capferret",
    "cb",
    "cde",
    "cr",
    "crn",
    "CALYPSO",
    "camoline",
    "campion",
    "canmar",
    "cantieridisarnico",
    "capelli",
    "carat",
    "caravelle",
    "caribe",
    "carver",
    "castello marine",
    "Catalina",
    "cayenne",
    "cenit",
    "century",
    "challenger",
    "champion",
    "chaparral",
    "checkmate",
    "chris craft",
    "cigarette",
    "ciljan",
    "cleopatra",
    "clipper",
    "coast liner",
    "coastmaster",
    "coaster",
    "cobalt",
    "cobra",
    "cobrey",
    "colin archer",
    "colombo",
    "comet",
    "comfort",
    "comfortina",
    "COMPASS",
    "concord",
    "contessa",
    "contest",
    "contrast",
    "cooper",
    "cormate",
    "coronet",
    "coverline",
    "cranchi",
    "crescent",
    "crownline",
    "cruiser",
    "cruisers",
    "custom line",
    "cutwater",
    "cyber 370",
    "dacapo",
    "dallapieta",
    "DATELINE",
    "DEFEVER",
    "Degerø",
    "dehler",
    "Dell Quay Dory",
    "delphia",
    "delta",
    "derubisyachts",
    "DIANA",
    "DISCOVERY",
    "diva",
    "dixie",
    "dolmøy",
    "Dolvik",
    "dominant",
    "donzi",
    "doral",
    "drabant",
    "draco",
    "drago",
    "dragonfly",
    "drake",
    "drive",
    "dromedille",
    "dsp",
    "dufour",
    "duramar",
    "dynamic",
    "ebbtide",
    "edge water",
    "eikeli",
    "elan",
    "elling",
    "elwaro",
    "emili",
    "endourance",
    "enes",
    "eolo",
    "epoca",
    "etap",
    "euro yacht",
    "EUROCRAFT",
    "europajolle",
    "EUROSPORT",
    "evada",
    "EXCEDO",
    "excess_catamarans",
    "explorer",
    "fm",
    "fun",
    "fabola",
    "faeton",
    "fagitta",
    "fairline",
    "falcon",
    "Fantasi",
    "farr",
    "fast cab",
    "faster",
    "faurby",
    "feeling",
    "FEMUNDBOAT",
    "fenix",
    "ferretti",
    "fevik",
    "fiart",
    "finessa",
    "finncamp",
    "finngulf",
    "finnmaster",
    "finnsailer",
    "finnsport",
    "firling",
    "first",
    "first class 8",
    "fiskeskøyte",
    "fjord",
    "fjord terne",
    "fiordboats",
    "fjordling",
    "flem",
    "FLEMING",
    "fletcher",
    "flipper",
    "folla",
    "forbina",
    "forgus",
    "Formosa",
    "formula",
    "formula watercraft",
    "fortego",
    "fountain",
    "Fountaine Pajot",
    "fourwinns",
    "fram",
    "FRAUSCHER",
    "freedom",
    "freeman",
    "FRERS_AND_CIBIL",
    "friendship",
    "fun-yak",
    "furuholmen",
    "færing",
    "gh",
    "GJ",
    "GJ690",
    "gp",
    "grp",
    "GALA",
    "galeon",
    "galia",
    "gambling",
    "gamler",
    "gecco",
    "gemi",
    "Gemini",
    "gib&#39;sea",
    "gibbs",
    "gilbert",
    "gimle",
    "gjeving",
    "gladiator",
    "glastron",
    "GLISERIS",
    "gobbi",
    "goldfish",
    "gozzi",
    "grady white",
    "granada",
    "grand",
    "grand banks",
    "grand soleil",
    "grandzessa",
    "grandezza",
    "greatline",
    "greenline",
    "GRIMSTADJOLLE",
    "grimsoy",
    "grinde",
    "GRIZZLY",
    "gromling",
    "Guernsey",
    "gulfcraft",
    "guy",
    "h-båt",
    "hb",
    "hr",
    "hrs-benelli",
    "halco",
    "hallberg rassy",
    "hankø terna",
    "hanse",
    "hansvik",
    "hanto",
    "happy",
    "hardanger",
    "hardangersnekke",
    "harmony",
    "hasla",
    "hasle",
    "hasle summer fun",
    "HASTIG",
    "havboat",
    "hawk",
    "HELLEFISKEREN",
    "hero",
    "herva",
    "herwa",
    "HeyDay",
    "highperformance",
    "highfield",
    "hitra",
    "hobby",
    "hobiecat",
    "honda",
    "hortensnekka",
    "hudson",
    "hui",
    "humber",
    "hunter",
    "hurrycane",
    "husky",
    "hutting",
    "hydrolift",
    "hydromarine",
    "Hårek",
    "if",
    "IOD Princess",
    "IRON Boats",
    "isy",
    "ITAMA",
    "ibiza",
    "ideal",
    "idylla",
    "impala",
    "in lander",
    "infra",
    "integrity",
    "inter",
    "Invictus",
    "irene",
    "isis",
    "italmar",
    "jcraft",
    "j-boats",
    "jaguar",
    "janmor",
    "jansentry",
    "JARRACK",
    "jeanneau",
    "jeppe",
    "joda",
    "johnson",
    "joker",
    "jolly",
    "junker",
    "jupiter",
    "jypling",
    "kb snekke",
    "kmv",
    "kaasbøll",
    "karmoy",
    "karnic",
    "KAVALER",
    "kawasaki",
    "kayot",
    "kelt",
    "killing",
    "kimple",
    "kloster",
    "knarr",
    "korsfjord",
    "korso",
    "kragerø",
    "kral",
    "Krash",
    "krona",
    "Kulla",
    "kværnø",
    "lm",
    "ln",
    "lagoon",
    "laguna",
    "Lami",
    "larsen",
    "larson",
    "laser",
    "lasse",
    "Lema",
    "LEXTOR_MARIN",
    "libra",
    "lima",
    "Limbo",
    "limestone",
    "linder",
    "linjett",
    "linssen",
    "lodestar",
    "lomac",
    "los",
    "luna",
    "lyngør",
    "lærling",
    "M_SPORT",
    "MAR_CO",
    "msboat",
    "mtx",
    "mv",
    "mvyachts",
    "mab",
    "mabi yachts",
    "macgregor",
    "maestral",
    "maestro",
    "magnum",
    "mainship",
    "majesty",
    "mako",
    "malibu",
    "malo",
    "malø yachts",
    "mamba",
    "mantra",
    "marex",
    "mariah",
    "marieholm",
    "Maril",
    "marina",
    "MARINE TECHNOLOGY INC",
    "mariner",
    "marino",
    "Marion",
    "Maritimo",
    "marlin",
    "marquisyachts",
    "mascot",
    "master",
    "mastercraft",
    "matrix boats",
    "maxi",
    "maxum",
    "maxus",
    "mazury",
    "mekling",
    "melges",
    "menken maritiem",
    "mercury",
    "meridian yachts",
    "mery nautic",
    "micora",
    "mikkel",
    "milia",
    "millenium",
    "minor",
    "mirage",
    "mixer",
    "modesty",
    "modius",
    "monaco",
    "monark",
    "monterey",
    "moody",
    "morgan",
    "mosquito",
    "motiva",
    "motorscoop",
    "musling",
    "mustang",
    "myra",
    "maløy",
    "møn",
    "mørebas",
    "mørejet",
    "nb",
    "neel",
    "NF",
    "nabcat",
    "nabwork",
    "najad",
    "narwhal",
    "nauticat",
    "nautico",
    "nautor",
    "NAUTUS",
    "naver",
    "navette",
    "neptim",
    "neptunkrysser",
    "neptunus",
    "nessy",
    "NEWCLASSIC",
    "nicholson",
    "nidelv",
    "nilsen",
    "nimbus",
    "nimo",
    "nisamirib",
    "Nitra",
    "nor sea",
    "nor star",
    "nor-dan",
    "nor-tech",
    "norplast",
    "Nortech",
    "Noratlantis",
    "norbas",
    "norbelle",
    "norbåt",
    "norcraft",
    "nord west",
    "nordstar",
    "nordic marine",
    "nordic",
    "nordic star",
    "nordis folkebat",
    "nordkapp",
    "nordship",
    "norfun",
    "norlin",
    "norline",
    "norman",
    "Norrib",
    "norsafe",
    "northatlanticrib",
    "North Pacific",
    "north ribs",
    "northstar",
    "NorthMaster",
    "nova",
    "NOVURANIA",
    "nuovajolly",
    "nymar",
    "OX",
    "obsession",
    "ocean alexander",
    "oceanmarin",
    "oceancraft",
    "oceanfarer",
    "oceanis",
    "ocean master",
    "oceanrunner",
    "ockelbo",
    "ocqueteau",
    "olson 88",
    "olympic",
    "ombrine",
    "omega",
    "optima",
    "orrskär",
    "oselvar",
    "Otasport",
    "oyster",
    "pakri",
    "paragon",
    "Paramount Powerboats",
    "parent",
    "partizan",
    "Pearlsea",
    "PEGAZUS",
    "pershing",
    "pettersson",
    "piet hein sloep",
    "pilot",
    "pioner",
    "plattgatter",
    "pogo",
    "polar",
    "polarcirkel",
    "polaris",
    "polarkraft",
    "polo",
    "poseidon",
    "powerquest",
    "powercraft",
    "predator",
    "prestige",
    "princess",
    "pro rib",
    "pro-line",
    "PROWORKER",
    "pronavia",
    "protector",
    "puma",
    "pursuit",
    "quicksilver",
    "ris",
    "rm_yachts",
    "racemaster",
    "racingsailboats",
    "ram-marine",
    "ramin",
    "ran craft",
    "rana",
    "rand",
    "ranger",
    "ranger tugs",
    "ranieri",
    "rascala",
    "rasmus",
    "ravage",
    "real",
    "REALCRAFT",
    "regal",
    "reinell",
    "REMUS",
    "revenger",
    "rheamarine",
    "riblimited",
    "ribcraft",
    "ribeye",
    "Ridas",
    "rinker",
    "risør",
    "riva",
    "rival",
    "rivena yachts",
    "river",
    "riviera",
    "robalo",
    "rodman",
    "rondo",
    "royalcruiser",
    "royalmarine",
    "runn37",
    "rupert",
    "rush",
    "ryds",
    "sacs",
    "Seacore",
    "SF_MARITIM",
    "sk",
    "sl",
    "SPX RIB",
    "sun",
    "SAFIR",
    "Safter",
    "saga",
    "SAGEN",
    "sagitta",
    "salman theri",
    "salona",
    "salpa",
    "sand",
    "sandström",
    "sandstø",
    "Sandtangen",
    "sandvik",
    "sandøy",
    "sargo",
    "saver",
    "SAXDOR",
    "saxe",
    "scampi",
    "scand",
    "scandica",
    "SCANDINAVAL",
    "scandinavien",
    "scanmar",
    "scanner",
    "scarab",
    "scarani",
    "schaefer yachts",
    "scorpion",
    "sea finn",
    "seaforceone",
    "sea hawk",
    "sea jeep",
    "sea master",
    "seapro",
    "sea queen",
    "sea ray",
    "seastar",
    "SEABOY",
    "seadan",
    "seadoo",
    "seahunter",
    "searider",
    "seawave",
    "seabird",
    "seabraker",
    "seaco",
    "seacraft",
    "seaking",
    "Seaking Dominator",
    "sealegs",
    "sealine",
    "sealver",
    "seamarin",
    "seaqrest",
    "seaside",
    "SEASPIRIT",
    "seaswirl",
    "seaviking",
    "seawings",
    "seigur",
    "selby",
    "selco",
    "selfa",
    "selmar",
    "selva",
    "Serecraft",
    "sessa",
    "shakespeare",
    "sheriff",
    "shimano",
    "shipman",
    "sigma",
    "sillinger",
    "silver",
    "silver viking",
    "silverton",
    "SIMMS",
    "simppu",
    "sirena",
    "sisumar",
    "sjark",
    "sjomann",
    "SJOTROLL",
    "skaar",
    "skager",
    "skagerak",
    "skarpnes",
    "skarsvaag",
    "skater",
    "skibsplast",
    "skilsø",
    "Skipper",
    "skipperyachts",
    "skorgenes",
    "skøyte",
    "smart rib",
    "smartliner",
    "smiling",
    "smuggler",
    "snipe",
    "solaris",
    "soling",
    "sollux",
    "spornes",
    "sportique",
    "sporty",
    "sprint",
    "starcraft",
    "starfisher",
    "statesman",
    "steady",
    "STIGFJORD",
    "sting",
    "stingher",
    "stingray",
    "storebro",
    "storm",
    "stormway",
    "Stormy",
    "storø",
    "strandebarmer",
    "straholmen",
    "Strømøy Rib",
    "style",
    "sugarsand",
    "summer dreams",
    "summer family",
    "summersport",
    "sun fast",
    "sun marine",
    "sun odyssey",
    "sun quest",
    "sun tracker",
    "sunbeam",
    "sunbird",
    "sunchaser",
    "sunline",
    "sunrider",
    "sunsation",
    "sunseeker",
    "sunwind",
    "SUOMI",
    "Sur Marine",
    "surplus",
    "suvi",
    "suzumar",
    "swan",
    "swede",
    "swedestar",
    "sweden yachts",
    "sørlandssnekka",
    "tb",
    "Teknomar",
    "tg-boat",
    "tpmarine",
    "Tahoe",
    "TAKACAT",
    "targa",
    "tartan",
    "tayana",
    "teal",
    "TECHNOHULL",
    "TECHNORIB",
    "terhi",
    "TETHYS_YACHTS",
    "tetis",
    "thunderbird",
    "tiara",
    "tiger marine",
    "tobias",
    "topaz",
    "topcat",
    "tornado",
    "toy",
    "tracker",
    "trader",
    "trapper",
    "trawler",
    "tresfjord",
    "TRINELLA",
    "trintella",
    "trio",
    "tristan",
    "triton",
    "troll",
    "tromøy",
    "trophy",
    "trønder&#39;n",
    "tullioabbate",
    "tune-jolle",
    "TUSTNA",
    "twostar",
    "UMSBOATS",
    "uniesse",
    "unique",
    "uttern",
    "Ventum",
    "vaagen",
    "valiant",
    "Van Der Heijden",
    "venus",
    "vermøy",
    "vernøy",
    "verøy",
    "veslefrikk",
    "vest",
    "vestfjord",
    "VIGGO",
    "vikfjord",
    "viking",
    "viknes",
    "viko",
    "viksfjord",
    "viksund",
    "vindø",
    "viper",
    "VIRUS",
    "vista",
    "Westplast",
    "walker bay",
    "wally",
    "wasa",
    "waterfun",
    "watercraft",
    "WATERWISH",
    "wauquiez",
    "wayfarer",
    "wellcraft",
    "wesling",
    "WEST",
    "westbas",
    "westcruiser",
    "wester",
    "WESTERLY",
    "westfjord",
    "westline",
    "wharran",
    "white shark",
    "wiking",
    "williams",
    "willing",
    "windy",
    "winga",
    "winrace",
    "with",
    "x-yachts",
    "xo",
    "XS Ribs",
    "XSHORE",
    "yam",
    "yamaha",
    "yamarin",
    "yngling",
    "zar",
    "zebec",
    "zee marine",
    "zephyr",
    "zitarra",
    "zodiac",
    "ribtec",
    "årø",
    "øien",
    "ørnvik",
    "5,5 meter",
    "8 meter",
  ]),
  boatModel: PropTypes.string,
  enginIncluded: PropTypes.bool,
  engineType: PropTypes.string,
  engineBrand: PropTypes.string,
  enginPorwre: PropTypes.number, //In Hosre Power
  maxSpeed: PropTypes.number, // in knots (optional)
  fuel: PropTypes.string,
  length: PropTypes.number, // in feet
  width: PropTypes.number, // in cm (optional)
  depth: PropTypes.number, // in cm (optional)
  weight: PropTypes.number, // in kg (optional)
  material: PropTypes.oneOf(["wood", "aluminium", "plastic", "fiberglass", "other"]),
  color: PropTypes.string,
  numberOfSeats: PropTypes.number,
  numberOfBeds: PropTypes.number,
  lightNumbers: PropTypes.number, // (only for sailboats)
  equipment: PropTypes.string,
};

export const carvanOrRVPropTypes = PropTypes.shape({
  adType: PropTypes.oneOf(["rent", "sell"]),
  type: PropTypes.oneOf(["carvan", "rv"]),
  rvType: PropTypes.oneOf([
    "classAMotorhomes",
    "classBMotorhomes",
    "classCMotorhome",
    "fifthWheel",
    "toyHaulers",
    "travelTrailers",
    "popUpCampers",
    "tinyTravelTrailers",
    "truckCampers",
  ]), //
  carvanType: PropTypes.oneOf(["carvan", "other"]),
  ...vehicleIdentityPropTypes,
});
//Redskap -> gear

export const truckTrailerPropTypes = PropTypes.shape({
  truckTrailertype: PropTypes.oneOf([
    "concrete_truck",
    "car_transport",
    "fire_truck",
    "chassis_add",
    "lagab",
    "animal_transport",
    "trailer",
    "crane_truck",
    "krokloft",
    "skiploader",
    "regid",
    "renovation",
    "cargo",
    "special",
    "tanker",
    "tipper",
    "tractor",
    "logtruck",
    "veteran_car",
    "tow_truck",
    "other",
  ]),
  trucTrailerkMake: PropTypes.oneOf([
    "amttrailer",
    "other",
    "bks trailer",
    "bmc",
    "bedford",
    "broshuis",
    "bussbygg",
    "carnehl",
    "chereau",
    "citroen",
    "daf",
    "damm",
    "dodge",
    "dyna",
    "ekeri",
    "equi_trek",
    "flc",
    "faymonville",
    "fiat",
    "fliegl",
    "ford",
    "fuso",
    "globetrotter",
    "goupil",
    "hfr",
    "hm",
    "hrd",
    "hammar",
    "hino",
    "humbaur",
    "istrail",
    "isuzu",
    "iveco",
    "iveco_eurocargo",
    "jpm",
    "krone",
    "kel_berg1",
    "knapen",
    "konar",
    "kogel",
    "lagendorf",
    "langendorf",
    "kel-berg",
    "leci-trailer",
    "legras",
    "limetec",
    "man",
    "magirus deutz",
    "maur bilpåbygg",
    "mazda",
    "mercedes-benz",
    "mitsubishi",
    "nc_maskinhenger",
    "nissan",
    "nooteboom",
    "norslep",
    "narko",
    "pacton",
    "peugeot",
    "red river",
    "renault",
    "scania",
    "scanslep",
    "schmitz",
    "schaffer",
    "sisu",
    "spartveit",
    "steelbro",
    "sørling",
    "tym",
    "tatra",
    "tezeller",
    "toyota",
    "trail king",
    "trio-lift",
    "tyllis",
    "vak",
    "van hool",
    "vang",
    "volkswagen",
    "volvo",
    "wilco",
    "xilin",
  ]),
});

export const busMinibusVPropTypes = PropTypes.shape({
  type: PropTypes.oneOf(["bus", "minibus", "other"]),
  make: PropTypes.oneOf([
    "daf",
    "fiat",
    "ford",
    "hino",
    "irisbus_iveco",
    "irizar",
    "isuzu",
    "iveco",
    "man",
    "mercedes-benz",
    "neoplan",
    "omnibus",
    "peugeot",
    "renault",
    "scania",
    "setra",
    "temsa",
    "vdl",
    "van_hool",
    "volkswagen",
    "volvo",
    "other",
  ]),
  totalWeight: PropTypes.number.isRequired, // in kg
  payload: PropTypes.number, // in kg -v
  length: PropTypes.number, // in cm -v
  cabinType: PropTypes.number, // -v
  // numberOfSeats: PropTypes.number, // -v we have seatsNumber
});
//BYgg og anlegg
export const constructionVehiclePropTypes = PropTypes.shape({
  type: PropTypes.oneOf([
    "drillingequipment",
    "agridumper",
    "mechanicaldigger",
    "wheeledloader",
    "industrimaskin",
    "crushingtelescopegear",
    "skid_steer_loader",
    "comprimator_machine",
    "agricranes",
    "lift",
    "minidigger",
    "toolcarrier",
    "telescopic_handler",
    "agriaccessories",
    "backhoe_loader",
    "truck",
    "other",
  ]),
  make: PropTypes.oneOf([
    "abeco",
    "abs",
    "ameise",
    "ammann",
    "aichi",
    "airman",
    "almac",
    "amco weba",
    "other",
    "arjes",
    "ascendant",
    "atlas",
    "atlas_copco",
    "atlet",
    "attack",
    "ausa",
    "avant",
    "avelingbarford",
    "bf-tecnic",
    "bt",
    "basket",
    "belarus",
    "bell",
    "bergmann",
    "bluelift",
    "bobcat",
    "bomag",
    "boschung",
    "boss",
    "bravisol",
    "broyt",
    "case",
    "caseih/case",
    "cat",
    "cesab",
    "clark",
    "coltrax",
    "crown",
    "cummins",
    "daemo",
    "daewoo",
    "dantruck",
    "david brown",
    "demag",
    "denka",
    "deutz",
    "dexter",
    "dieci",
    "dingli",
    "dino",
    "ditch witch",
    "doosan",
    "doppstadt",
    "dynapac",
    "ep",
    "edge",
    "eurocomach",
    "europelift",
    "falck schmidt",
    "faresin",
    "faun",
    "fendt",
    "fiat",
    "fiat kobelco",
    "fiatallis",
    "fiathitachi",
    "ford",
    "france_elevateur",
    "gmg",
    "gardner denver",
    "gehl",
    "genie",
    "giant",
    "goransons",
    "gravitasjon",
    "grove",
    "hmf",
    "haahjem",
    "halla",
    "hamm",
    "hanscha",
    "hanix",
    "hanomag",
    "hardlife",
    "haulotte",
    "heatwork",
    "heli",
    "hinowa",
    "hitachi",
    "holland",
    "hurliman",
    "hydrema",
    "hydromac",
    "hymax",
    "hyster",
    "hyundai",
    "ihi",
    "infront",
    "ingersoll",
    "isoli",
    "iteco",
    "jac",
    "jcb",
    "jenz",
    "jlg",
    "jinma",
    "job-mann",
    "john deere",
    "jungheinrich",
    "kaeser",
    "kalmar",
    "kamag",
    "kato",
    "keestrack",
    "klaas",
    "kleemann",
    "kobelco",
    "kockum",
    "komatsu",
    "komptech",
    "konecranes",
    "kramerallrad",
    "kubota",
    "karcher",
    "levox",
    "lgmg",
    "lamborghini",
    "libra",
    "liebherr",
    "liftstar",
    "linde",
    "liugong",
    "ljungby",
    "logitrans",
    "lundberg",
    "mafi",
    "mec",
    "ms",
    "mx",
    "magni",
    "manitou",
    "massey ferguson",
    "matilsa",
    "maximal",
    "mb track",
    "mc cormick",
    "mccloskey",
    "mecalac",
    "merlo",
    "messersi",
    "metso",
    "michigan",
    "milano",
    "mitsubishi",
    "monark",
    "morooka",
    "movex",
    "moxy",
    "multione",
    "mustang",
    "ntc",
    "nante",
    "nasta",
    "neuson",
    "new holand",
    "niftylift",
    "nilfisk",
    "nissan",
    "norcar",
    "novatron",
    "ok",
    "okp",
    "omg",
    "oil &amp; steel",
    "omme",
    "optimas",
    "peljob",
    "paclite",
    "palfinger",
    "paus",
    "pesci",
    "ponsse",
    "portafill",
    "powertrack",
    "powerscreen",
    "rf",
    "rammax",
    "renault",
    "rocla",
    "rototilt",
    "rottne",
    "royal",
    "rubble-master",
    "sb",
    "sdc",
    "smv",
    "safelift",
    "same",
    "samsung",
    "sandvik",
    "sany",
    "schaeff",
    "shibaura",
    "silverstone",
    "sino",
    "sisu",
    "sky high",
    "skyjack",
    "snorkel",
    "socma",
    "still",
    "stronga",
    "sumitomo",
    "sumo",
    "sunward",
    "svetruck",
    "tmc",
    "tadano",
    "tadano-faun",
    "takeuchi",
    "tamrock",
    "tana",
    "tapio",
    "telestack",
    "terberg",
    "terex",
    "terex ecotec",
    "terex washing systems",
    "terex-demag",
    "terexfinlay",
    "tesmec",
    "teupen",
    "thaler",
    "thwaites",
    "timberjack",
    "toyota",
    "trevi_benne",
    "truxta",
    "ultrascreen",
    "unimog",
    "upright",
    "ursus",
    "valmet",
    "valtra",
    "venieri",
    "vermeer",
    "versalift",
    "verticom",
    "viking",
    "vitra",
    "volvo",
    "wacker",
    "wacker_neuson",
    "weidemann",
    "weycor",
    "wille",
    "wipco",
    "xilin",
    "yanmar",
    "yuchai",
    "zemmler",
    "zetor",
    "zettelmeyer",
    "aakerman",
    "osa",
  ]),
  //body and chassis
  bodyType: PropTypes.oneOf(["chassis", "joint", "other"]), //"CHASSIS" > Fastspent ramme "JOINTED" >Leddet
  cabType: PropTypes.oneOf(["open", "closed"]),
  grossWeight: PropTypes.number, // in kg
  substructure: PropTypes.number, // in percent -v
  // work disk capacity
  workload: PropTypes.number, // in kg
  loadCapacity: PropTypes.number, // in m3
  liftingCapacity: PropTypes.number, // in kg
  liftingHeight: PropTypes.number, // in meters
  hydraulicPerformance: PropTypes.number, //in liters / min
  hasAdditionalRental: PropTypes.bool,
});

export const dummyPropType = PropTypes.shape({
  ...vehiclePricePropType,
  ...vehicleConditionPropType,
  ...leasingInfoPropType,
  ...vehicleType,
  ...vehicleEngineAndFuel,
  ...vehicleBodyPropType,
  ...caravanRvPropType,
  ...mcLikeEngineAndChassis,
  ...MC,
  ...loadCapacityTruckTrailerBusMinibus,
  ...equipmentCar,
  ...equipmentMopedQuadbikeSnowmobilMC,
  ...equipmentCarvan,
  ...equipmentRV,
  ...equipmentTractor,
  ...equipmentTresker,
  ...equipmentTruckHanger,
  ...equipmentBusMinibus,
  ...vehicleIdentityPropTypes,
  ...boatPropTypes,
});

export const VehiclePropType = PropTypes.shape({
  ...vehicleIdentityPropTypes,
  ...vehicleType,
  ...vehicleBodyPropType,
  ...vehicleEngineAndFuel,
  ...vehicleConditionPropType,
  ...vehiclePricePropType,
});
