import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import PinterestIcon from "@mui/icons-material/Pinterest";
import PropTypes from "prop-types";
//import { ObjectID } from "bson";

const useStyles = makeStyles((theme) => ({
  botton: {
    backgroundColor: "transparent",
    "&:hover": {
      color: "#E60023",
      backgroundColor: "transparent",
    },
  },
}));
const PinterestShareButton = ({ itemID }) => {
  const classes = useStyles();

  return (
    // <a href={"https://www.facebook.com/sharer/sharer.php?u=http://localhost:3006/item/" + itemID}>
    <a href={"http://pinterest.com/pin/create/link/?url=http://vg.no"}>
      <IconButton style={{ backgroundColor: "transparent" }} size="large">
        <PinterestIcon className={classes.botton} />
      </IconButton>
    </a>
  );
};

PinterestShareButton.propTypes = {
  itemID: PropTypes.string.isRequired,
};

export default PinterestShareButton;
