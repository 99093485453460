import React from "react";
import Grid from "@mui/material/Grid";
import { PropTypes } from "prop-types";
import Typography from "@mui/material/Typography";

const EuControl = ({ euControlLast, euControlNext }) => {
  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="row"
        justifyContent="left"
        alignItems="center"
        spacing={5}
        style={{ marginTop: 0 }}
      >
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
              Last EU approved
            </Typography>
          </Grid>
          <Grid item>{euControlLast}</Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
              Next deadline to approved EU control
            </Typography>
          </Grid>

          <Grid item>{euControlNext}</Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default EuControl;

EuControl.propTypes = {
  euControlLast: PropTypes.string, // NB should it be instanceOf(Date) ?
  euControlNext: PropTypes.string, // NB should it be instanceOf(Date)?
  roadworthinessTestApproved: PropTypes.bool, // For Lastebil og henger	Buss og Minibuss
  roadworthinessTestApprovalDate: PropTypes.string, //For Lastebil og henger	Buss og Minibuss
};
