import React, { useCallback, useEffect, useMemo } from "react";

const LoadingContext = React.createContext();

const LoadingContextProvider = (props) => {
  const [loadingObj, modifyLoading] = React.useReducer(
    (state, { actionName, isLoading }) =>
      state[actionName] !== isLoading ? { ...state, [actionName]: isLoading } : state,
    {}
  );
  const isLoading = useMemo(() => Object.values(loadingObj).reduce((acc, curr) => acc || curr, false), [loadingObj]);
  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        modifyLoading,
        loadingObj,
      }}
      {...props}
    />
  );
};

const useIsLoading = () => {
  const { isLoading } = React.useContext(LoadingContext);
  return isLoading;
};

const useSetLoading = (actionName, initialLoading = null) => {
  const initLoadingHack = React.useRef(initialLoading);
  const { modifyLoading, loadingObj } = React.useContext(LoadingContext);
  const setIsLoading = useCallback(
    (isLoading) => {
      modifyLoading({ actionName, isLoading });
      initLoadingHack.current = null;
    },
    [actionName, modifyLoading]
  );
  useEffect(() => {
    return () => {
      modifyLoading({ actionName, isLoading: false });
    };
  }, [modifyLoading, actionName]);
  return { setIsLoading, isLoading: initLoadingHack.current === true || !!loadingObj[actionName] };
};

export { LoadingContextProvider, useSetLoading, useIsLoading };
