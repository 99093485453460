import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link, useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { routeToTabsValue, getRouteValue, getRouteType } from "../utils/navigations";
import logo from "../assets/logo.svg";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const WrappedTab = ({ routeObj, anchorEl, handleMouseOverNewAd, handleCloseNewAdMenu, ...props }) => {
  const { t } = useTranslation();
  const tabRef = React.useRef();
  const location = useLocation();
  const routeType = getRouteType(routeObj);
  const isActiveRoute = (route) => location.pathname === route;
  const popoverIsOpen = anchorEl === tabRef.current && routeType === "subRoutes";
  const renderInteractive = (rObj, routeType) => {
    if (routeType === "link") {
      return {
        component: Link,
        to: rObj.route,
      };
    }
    if (routeType === "onClick") {
      return {
        onClick: rObj.route,
      };
    }
    return {};
  };
  const menuId = `${routeObj.name}-menu`;
  return (
    <>
      <Tab
        {...props}
        ref={tabRef}
        sx={(theme) => ({
          ...theme.typography.tab,
          minWidth: 10,
          marginLeft: "25px",
        })}
        {...renderInteractive(routeObj, routeType)}
        label={routeObj.name}
        aria-owns={popoverIsOpen && menuId}
        aria-haspopup={popoverIsOpen}
        onMouseEnter={handleMouseOverNewAd}
        icon={routeObj.icon}
      />
      {routeType === "subRoutes" && (
        <Menu
          id={menuId}
          anchorEl={tabRef.current}
          open={popoverIsOpen}
          onClose={handleCloseNewAdMenu}
          sx={{ pointerEvents: "none" }}
          onMouseLeave={handleCloseNewAdMenu}
          MenuListProps={{
            onMouseLeave: handleCloseNewAdMenu,
          }}
          // elevation={0}
          // style={{ zIndex: 1302 }}
          keepMounted // Make sure all the menu items are mounted in the DOM regardless of they are visible on the screen. Better for SEO
        >
          {routeObj.route.map((subRoute) => (
            <MenuItem
              key={getRouteValue(subRoute)}
              {...renderInteractive(subRoute, getRouteType(subRoute))}
              sx={(theme) => ({
                ...theme.typography.tab,
                pointerEvents: "auto",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              })}
              selected={isActiveRoute(subRoute.route)}
            >
              {subRoute.icon}
              {t(subRoute.name)}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

WrappedTab.propTypes = {
  anchorEl: PropTypes.object,
  handleMouseOverNewAd: PropTypes.func.isRequired,
  handleCloseNewAdMenu: PropTypes.func.isRequired,
  routeObj: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    route: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.arrayOf(
        PropTypes.shape({
          route: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
          name: PropTypes.string.isRequired,
          icon: PropTypes.node.isRequired,
        })
      ),
    ]).isRequired,
  }),
};

const Navbar = ({ routes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMouseOverNewAd = (e) => setAnchorEl(e.currentTarget);
  const handleCloseNewAdMenu = (_e) => setAnchorEl(null);
  const location = useLocation();

  const tabsValue = useMemo(() => routeToTabsValue(location.pathname, routes), [location.pathname, routes]);
  return (
    <AppBar position="relative" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar disableGutters style={{ marginLeft: 10, marginRight: 10 }}>
        <Link to="/">
          <Box
            component="img"
            sx={{
              padding: 0,
              width: 130,
              // maxHeight: { xs: 233, md: 167 },
              // maxWidth: { xs: 350, md: 250 },
            }}
            alt="eBasar logo"
            src={logo}
          />
        </Link>
        <Tabs value={tabsValue} sx={{ marginLeft: "auto" }} textColor="secondary" indicatorColor="secondary">
          {routes
            .filter((r) => !r.mobileOnly)
            .map((routeObj) => (
              <WrappedTab
                key={getRouteValue(routeObj)}
                value={getRouteValue(routeObj)}
                routeObj={routeObj}
                anchorEl={anchorEl}
                handleMouseOverNewAd={handleMouseOverNewAd}
                handleCloseNewAdMenu={handleCloseNewAdMenu}
              />
            ))}
        </Tabs>
      </Toolbar>
    </AppBar>
  );
};
Navbar.propTypes = {
  routes: PropTypes.array,
};
export default Navbar;
