import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { here_api_key } from "../staticVars";
import Grid from "@mui/material/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

const highlightParse = (text, matches) => {
  var result = [];

  if (matches.length === 0) {
    result.push({
      text: text,
      highlight: false,
    });
  } else {
    if (matches[0].start > 0) {
      result.push({
        text: text.slice(0, matches[0].start),
        highlight: false,
      });
    }
  }

  matches.forEach(function (match, i) {
    result.push({
      text: text.slice(match.start, match.end),
      highlight: true,
    });

    if (i === matches.length - 1) {
      if (match.end < text.length) {
        result.push({
          text: text.slice(match.end, text.length),
          highlight: false,
        });
      }
    } else if (match.end < matches[i + 1].start) {
      result.push({
        text: text.slice(match.end, matches[i + 1].start),
        highlight: false,
      });
    }
  });

  return result;
};
const autocompleteLocation = async (types, str) => {
  const url = new URL("https://autocomplete.search.hereapi.com/v1/autocomplete");
  // we can introduce country here 	in=countryCode	, if we want to restrict results
  //Supported `types` values: 'area', 'city', 'postalCode'
  Object.entries({ ...{ q: str, apiKey: here_api_key }, ...(types && { types }) }).forEach(([key, value]) =>
    url.searchParams.append(key, value)
  );
  const response = await fetch(url);
  return await response.json();
};

const toFakeEvent = (name, value) => ({ target: { name, value } });

const LocationPickerInput = ({
  allowDeselect,
  types,
  initialInputValue,
  onBlur,
  onChange,
  value,
  name,
  allowedResultTypes,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(initialInputValue);
  const [options, setOptions] = useState([]);
  const onInputChange = (_e, newInputValue) => {
    setInputValue(newInputValue);
  };
  useEffect(() => {
    if (initialInputValue && !inputValue && options.length) {
      onChange(toFakeEvent(name, options[0]));
    }
  });

  useEffect(() => {
    if (inputValue.length) {
      autocompleteLocation(types, inputValue).then((r) =>
        setOptions(
          r.items.filter((f) => {
            if (!allowedResultTypes[f.resultType]) {
              return false;
            } else if (allowedResultTypes[f.resultType](f)) {
              return true;
            } else {
              return false;
            }
          })
        )
      );
    }
  }, [inputValue, types, allowedResultTypes]);
  return (
    <Grid container>
      <Grid item style={{ flexGrow: 1 }}>
        <Autocomplete
          fullWidth
          options={options}
          getOptionLabel={(o) => o.title}
          onInputChange={onInputChange}
          inputValue={inputValue}
          onBlur={() => onBlur(toFakeEvent(name, value))}
          onChange={(_e, value) => {
            onChange(toFakeEvent(name, value));
          }}
          filterOptions={(options, state) => options}
          renderOption={(props, option) => (
            <li {...props}>
              {highlightParse(option.title, option.highlights.title).map((y, i) => (
                <span key={i} style={{ fontWeight: y.highlight ? 700 : 400, whiteSpace: "pre" }}>
                  {y.text}
                </span>
              ))}
            </li>
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={value}
          clearOnBlur
          renderInput={(params) => {
            return <TextField {...params} margin="normal" variant="outlined" fullWidth {...props} type="text" />;
          }}
        />
      </Grid>
      {allowDeselect && (
        <IconButton color="inherit" onClick={() => onChange({ target: { value: null } })} size="large">
          <Grid item>
            <ClearIcon />
          </Grid>
        </IconButton>
      )}
    </Grid>
  );
};
LocationPickerInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  value: undefined,
  initialInputValue: "",
  allowedResultTypes: {
    locality: (f) => ["city", "postalCode"].includes(f.localityType),
    street: () => true,
    houseNumber: () => true,
    administrativeArea: (f) => ["state", "country", "county"].includes(f.administrativeAreaType),
  },
};
LocationPickerInput.propTypes = {
  allowDeselect: PropTypes.bool,
  allowedResultTypes: PropTypes.object.isRequired,
  types: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  initialInputValue: PropTypes.string,
};
export default LocationPickerInput;
