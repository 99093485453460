import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { vehicleConditionPropType } from "../utils/sharedPropTypes";
import startCase from "lodash/startCase";

const Responsive3To2Col = (prop) => {
  // Object.entries(prop.data).map(([key, value], i) => {
  //   console.log("key", key);
  //   console.log("value", value);
  //   console.log("i", i);
  // });

  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography variant="h5" style={{ fontWeight: 500 }} color="primary">
          {prop.title}
        </Typography>
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {Object.entries(prop.data).map(([key, value], i) => (
          <Grid item xs={2} sm={4} md={4} key={key}>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
                  {startCase(key)}
                </Typography>
              </Grid>
              <Grid item>{value}</Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
export default Responsive3To2Col;

Responsive3To2Col.propTypes = {
  ...vehicleConditionPropType,
};
