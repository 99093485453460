import React from "react";
import Badge from "@mui/material/Badge";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import ChatIcon from "@mui/icons-material/Chat";
import ListIcon from "@mui/icons-material/List";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ChairIcon from "@mui/icons-material/Chair";
import BikeScooterIcon from "@mui/icons-material/BikeScooter";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import WorkIcon from "@mui/icons-material/Work";

export const getRouteValue = (r) => (getRouteType(r) === "link" ? r.route : r.name);

export const getRouteType = (r) => {
  if (Array.isArray(r.route)) return "subRoutes";
  if (typeof r.route === "string") return "link";
  if (typeof r.route === "function") return "onClick";
  throw new Error(`Unknown routeType ${JSON.stringify(r)}`);
};

export const getNavigations = (isLoggedIn, unreadMessageCount, logout, setThemeMode, themeMode) => [
  {
    name: "ads",
    route: "/list-items",
    icon: <ListIcon />,
  },
  {
    name: "View",
    icon: <DriveEtaIcon />,
    route: [
      {
        name: "Car",
        icon: <DriveEtaIcon />,
        route: "/showcar",
      },
      {
        name: "MC",
        icon: <DriveEtaIcon />,
        route: "/showmc",
      },
      {
        name: "Trailer/Buss",
        icon: <DriveEtaIcon />,
        route: "/showtruck",
      },
      {
        name: "Boat",
        icon: <DriveEtaIcon />,
        route: "/showboat",
      },
    ],
  },
  themeMode === "light"
    ? {
        name: "DarkTheme",
        route: () => setThemeMode("dark"),
        icon: <DarkModeIcon />,
      }
    : {
        name: "LightTheme",
        route: () => setThemeMode("light"),
        icon: <LightModeIcon />,
      },
  ...(isLoggedIn
    ? [
        {
          name: "Sample",
          icon: <DriveEtaIcon />,
          route: [
            {
              name: "Car",
              route: "/sample-car",
              icon: <AddIcon />,
            },
            {
              name: "MC",
              route: "/sample-mc",
              icon: <AddIcon />,
            },
            {
              name: "Trailer/Buss",
              route: "/sample-truck-bus",
              icon: <AddIcon />,
            },
            {
              name: "Boat",
              route: "/sample-buss",
              icon: <AddIcon />,
            },
          ],
        },
        {
          name: "new ad",
          icon: <AddIcon />,
          route: [
            {
              name: "Item",
              route: "/new-item",
              icon: <ChairIcon />,
            },
            {
              name: "Vehicle/MC/Boat",
              route: "/new-vehicle",
              icon: <BikeScooterIcon />,
            },
            {
              name: "Property",
              route: "/new-property",
              icon: <HomeWorkIcon />,
            },
            {
              name: "Job",
              route: "/job-opening",
              icon: <WorkIcon />,
            },
          ],
        },
        {
          name: "Messages",
          route: "/chat",
          icon: (
            <Badge badgeContent={unreadMessageCount} color="error">
              <ChatIcon />
            </Badge>
          ),
        },
        {
          name: "My eBasar",
          icon: <PersonIcon />,
          route: [
            {
              name: "Profile",
              icon: <PersonIcon />,
              route: "/profile",
            },
            {
              name: "My items",
              icon: <ListAltIcon />,
              route: "/my-items",
            },
            {
              name: "Notifactions",
              route: "/notifactions",
              icon: <NotificationsIcon />,
            },
            {
              name: "Logout",
              icon: <LogoutIcon />,
              route: logout,
            },
          ],
        },
      ]
    : [
        {
          name: "Login",
          route: "/signin",
          icon: <LoginIcon />,
        },
      ]),
];

export const routeToTabsValue = (pathname, routes) => {
  if (pathname.startsWith("/item/") || pathname.startsWith("/reportad/") || pathname === "/") {
    // highlight list-items tab when ur inside a item
    return "/list-items";
  }
  if (pathname.startsWith("/chat/")) {
    // highlight list-items tab when ur inside a item
    return "/chat";
  }
  if (pathname.startsWith("/edit-item/")) {
    return "Profile";
  }
  if (pathname.startsWith("/lost-password") || pathname.startsWith("/reset-passwordtr")) {
    // highlight list-items tab when ur inside a item
    return "/list-items";
  }
  return routes.reduce(
    (acc, curr) =>
      (getRouteType(curr) === "subRoutes" && curr.route.map((o) => o.route).includes(pathname) && curr.name) || acc,
    pathname
  );
};
