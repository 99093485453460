import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Radio } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";

const RadioSelector = ({ options, error, helperText, ...props }) => {
  return (
    <div>
      {error ? helperText : ""}
      <RadioGroup {...props}>
        {options.map((o) => (
          <FormControlLabel key={o.value} value={o.value} control={<Radio />} label={o.label} />
        ))}
      </RadioGroup>
    </div>
  );
};

RadioSelector.propTypes = { options: PropTypes.array, error: PropTypes.bool, helperText: PropTypes.string };
export default RadioSelector;
