import React from "react";
import Link from "../components/Link";
import Typography from "@mui/material/Typography";

export default function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" to="/">
        ebasar.no
      </Link>{" "}
      2022
    </Typography>
  );
}
