import React from "react";
import { useParams } from "react-router";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import useForm, { formPropsToValues, initialInputValue } from "../form/useForm";
import { useHistory } from "react-router-dom";
import { useDb } from "../providers/DbProvider";
import { useAuth } from "../providers/AuthProvider";
import { isStringBetween } from "../form/validators";
import SelectWithError from "../components/SelectWithError";
import AsyncButton from "../components/AsyncButton";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { red } from "@mui/material/colors";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textarea: {
    width: "100%",
    height: "100%",
  },
}));

const ReportAd = () => {
  const { id } = useParams();
  const classes = useStyles();
  const db = useDb();
  const { user } = useAuth();
  const [reportCategory, setreportCategory] = React.useState("");
  const categories = ["Scam", "Weapon and Drugs", "Other"];

  const handleChange = (event) => {
    setreportCategory(event.target.value);
  };
  const [isPosting, setIsPosting] = React.useState(false);

  const [validateFormPure, validateFormStateful, formProps] = useForm(
    {
      description: initialInputValue(""),
      category: initialInputValue(""),
    },
    {
      description: { onBlur: isStringBetween(10, 300) },
      category: { onBlur: isStringBetween(1, 100) },
    }
  );
  const formValidation = validateFormPure();

  const formValues = formPropsToValues(formProps);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isPosting) {
      return;
    }
    setIsPosting(true);
    try {
      const { location, ...restOfFormValues } = formValues;

      const newReport = {
        ...restOfFormValues,
        user_id: user.id,
        ad_id: id,
      };
      await db.collection("reports").insertOne(newReport);

      history.push("/list-items");
    } catch (err) {
      setIsPosting(false);
      throw err;
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <ReportProblemRoundedIcon fontSize={"large"} style={{ color: red[500] }} />
        <Typography component="h1" variant="h5">
          Report Ad !
        </Typography>

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <SelectWithError
            required
            inputLabel="category"
            fullWidth
            options={Object.keys(categories)}
            {...formProps.category}
          />

          <FormControl className={classes.formControl}>
            <Select
              value={reportCategory}
              onChange={handleChange}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" disabled>
                Placeholder
              </MenuItem>
              <MenuItem value={1}>Scam</MenuItem>
              <MenuItem value={2}>Weapon or drug sales</MenuItem>
              <MenuItem value={3}>Other</MenuItem>
            </Select>
            <FormHelperText>Placeholder</FormHelperText>
          </FormControl>

          <TextField
            margin="normal"
            variant="outlined"
            fullWidth
            label="Description"
            multiline
            rows={10}
            className={classes.textarea}
            {...formProps.description}
          />

          <br />
          <AsyncButton
            disabled={formValidation.error || isPosting}
            tooltipTitle={formValidation.msg}
            onClickWhileDisabled={validateFormStateful}
            loading={isPosting}
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            className={classes.button}
            startIcon={<SendRoundedIcon />}
            fullWidth
          >
            Report Ad
          </AsyncButton>
        </form>
      </div>
    </Container>
  );
};

export default ReportAd;
