import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import PropTypes from "prop-types";
//import { ObjectID } from "bson";
const useStyles = makeStyles((theme) => ({
  botton: {
    backgroundColor: "transparent",
    "&:hover": {
      color: "#3b5998",
      backgroundColor: "transparent",
    },
  },
}));

const FacebookShareButton = ({ itemID }) => {
  const classes = useStyles();

  return (
    // <a href={"https://www.facebook.com/sharer/sharer.php?u=http://localhost:3006/item/" + itemID}>
    <a href={"https://www.facebook.com/sharer/sharer.php?u=http://vg.no"}>
      <IconButton style={{ backgroundColor: "transparent" }} size="large">
        <FacebookIcon className={classes.botton} />
      </IconButton>
    </a>
  );
};
FacebookShareButton.propTypes = { itemID: PropTypes.string.isRequired };

export default FacebookShareButton;
