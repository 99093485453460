import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const AsyncButton = ({ disabled, onClickWhileDisabled, tooltipTitle, loading, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Tooltip title={tooltipTitle} onClick={disabled ? onClickWhileDisabled : undefined}>
        <span>
          <Button disabled={loading || disabled} {...props}>
            {props.children}
          </Button>
        </span>
      </Tooltip>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};
AsyncButton.propTypes = {
  tooltipTitle: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClickWhileDisabled: PropTypes.func,
};
export default AsyncButton;
