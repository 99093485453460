import { createTheme } from "@mui/material/styles";
//Topaz #0198E1
//Diamond Blue #0EBFE9

// const eblue = "#0276d4"; // 0276d4 198ee8
// const behshin = "#0276d4"; //#00b7eb0B72B9  0375B4  1c539b  0076c9 005fb7 1654b7 0a3da9 088cf7
// const asemani = "#00b7fa";
const bBlue = "#005fb7";
// const happyBlue = "#01BFFF";
// const topaz = "#0198E1";
const cloud = "#F3F3F3";
// const gray1 = "#383838";
// const gray2 = "#606060";
// const gray3 = "#696969";
// const gray4 = "#A9A9A9"; //HTML silver
// const gray5 = "#C0C0C0"; //HTML DarkGray

export const theme = (themeMode) =>
  createTheme({
    palette: {
      mode: themeMode,
      // common: {
      //   blue: `${eblue}`,
      //   gray1: `${gray1}`,
      //   gray2: `${gray2}`,
      //   gray3: `${gray3}`,
      // },
      primary: {
        main: `${cloud}`,
      },
      secondary: {
        main: `${bBlue}`,
      },
    },

    typography: {
      tab: {
        fontFamily: "Raleway",
        textTransform: "none",
        fontWeight: 700,
        fontSize: "1rem",
      },
      estimate: {
        fontFamily: "Pacifico",
        fontSize: "1rem",
        textTransform: "none",
      },
      h2: {
        fontFamily: "Raleway",
        fontWeight: 700,
        fontSize: "2.5rem",
        lineHeight: 1.5,
      },
      h3: {
        fontFamily: "Pacifico",
        fontSize: "2.5rem",
      },
      h4: {
        fontFamily: "Raleway",
        fontSize: "1.75rem",
        fontWeight: 700,
      },
      h5: {
        fontSize: "1.75rem",
        fontWeight: 600,
      },
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: "1.25rem",
        fontWeight: 300,
      },
      subtitle2: {
        fontWeight: 300,
        fontSize: "1.25rem",
      },
      body1: {
        fontSize: "1.25rem",
      },
      caption: {
        fontSize: "1rem",
        fontWeight: 300,
      },
    },
    label: {
      backgroundColor: "white",
    },
    // components: {
    //   MuiInputLabel: {
    //     defaultProps: { shrink: true }
    //   },
    //   MuiOutlinedInput: {
    //     defaultProps: {
    //       notched: true
    //     }
    //   }
    // }
  });
