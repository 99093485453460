import React from "react";
import Grid from "@mui/material/Grid";
import HMap from "../HMap";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const Map = ({ lng, lat, adress, postcode, city, showAdress }) => {
  return (
    <React.Fragment>
      <Grid item container direction="column">
        {showAdress ? (
          <Grid item container spacing={3}>
            <Grid item>
              <Typography variant="h5" style={{ fontWeight: 700 }} color="primary">
                {adress}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" style={{ fontWeight: 700 }} color="primary">
                {postcode},{city}
              </Typography>
            </Grid>
          </Grid>
        ) : null}

        <Grid item style={{ color: "blue" }}>
          <HMap lng={lng} lat={lat} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Map;
Map.propTypes = {
  lng: PropTypes.number,
  lat: PropTypes.number,
  adress: PropTypes.string,
  postcode: PropTypes.string,
  city: PropTypes.string,
  showAdress: PropTypes.bool,
};
