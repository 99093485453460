import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import * as Realm from "realm-web";
import FillRequiredProfileData from "../components/modals/FillRequiredProfileData";
import LoadingFullscreen from "../components/LoadingFullscreen";
import { useApp } from "./AppProvider";
import { useModal } from "../utils/modalHooks";
import * as Sentry from "@sentry/react";
const AuthContext = React.createContext();

const isNotAnonUser = (u) => u._profile.identities.some((x) => x.providerType !== "anon-user") || false;

const realmUserToSentryId = (realmUser) => ({
  id: realmUser?.id,
  email: realmUser?._profile?.data?.email,
  username: realmUser?.customData?.username,
});

const safelyGetCustomData = (user) => {
  try {
    return user?.customData;
  } catch {
    return;
  }
};

const AuthProvider = (props) => {
  const { showError } = useModal();
  const app = useApp();
  const removeAllUsers = useCallback(
    () => Promise.all(Object.values(app.allUsers).map((u) => app.removeUser(u))),
    [app]
  );
  const [user, setUser] = useState(app.currentUser);
  const [customData, setCustomData] = useState(safelyGetCustomData(user));
  const history = useHistory();
  const sentryUser = realmUserToSentryId(app.currentUser);
  Sentry.setUser(sentryUser);
  const setUserAndCustomData = useCallback(() => {
    setUser(app.currentUser);
    setCustomData(safelyGetCustomData(app?.currentUser));
    // Sentry.setUser({ email: "john.doe@example.com" });
  }, [app.currentUser]);
  const refreshCustomData = useCallback(() => {
    return user
      .refreshCustomData()
      .then((r) => {
        setUserAndCustomData();
        return r;
      })
      .catch((error) => showError("refreshCustomData", error));
  }, [user, setUserAndCustomData, showError]);

  useEffect(() => {
    if (!user) {
      app.logIn(Realm.Credentials.anonymous()).then(() => {
        setUserAndCustomData();
      });
    }
  }, [user, app, setUserAndCustomData]);
  const logout = useCallback(
    () =>
      removeAllUsers()
        .then(setUserAndCustomData)
        .then(() => history.push("/")),
    [setUserAndCustomData, history, removeAllUsers]
  );
  const registerNewUser = useCallback(
    (email, password) =>
      app.emailPasswordAuth.registerUser(email, password).catch((error) => showError("registerNewUser", error)),
    [app, showError]
  );
  const loginThenSetUser = useCallback(
    (credentials) =>
      removeAllUsers()
        .then(() => app.logIn(credentials))
        .then(() => setUserAndCustomData())
        .then(() => history.replace(history.location?.state?.fromUrl || "/"))
        .catch((error) => showError("loginThenSetUser", error)),
    [app, setUserAndCustomData, history, showError, removeAllUsers]
  );

  const loginEmailPass = useCallback(
    (email, password) => loginThenSetUser(Realm.Credentials.emailPassword(email, password)),
    [loginThenSetUser]
  );

  const loginWithGoogle = useCallback(
    (credentials) => loginThenSetUser(Realm.Credentials.google(credentials)),
    [loginThenSetUser]
  );

  const loginFacebook = useCallback(
    (accessToken) => loginThenSetUser(Realm.Credentials.facebook(accessToken)),
    [loginThenSetUser]
  );
  const ensureUsername = useCallback(async () => {
    if (customData?.username) {
      return customData.username;
    }
    try {
      const result = await FillRequiredProfileData();
      if (result.username) {
        return result.username;
      }
    } catch (error) {
      return showError("ensureUsername", error);
    }
    return showError("ensureUsername", "Username is required.");
  }, [customData?.username, showError]);
  if (!user) {
    return <LoadingFullscreen>Authenticating</LoadingFullscreen>;
  }

  return (
    <AuthContext.Provider
      value={{
        app,
        user,
        logout,
        loginEmailPass,
        loginFacebook,
        isLoggedIn: isNotAnonUser(user),
        registerNewUser,
        loginWithGoogle,
        refreshCustomData,
        customData,
        ensureUsername,
      }}
      {...props}
    />
  );
};
const useAuth = () => React.useContext(AuthContext);
export { AuthProvider, useAuth };
