import React, { useMemo } from "react";
import { useAuth } from "../providers/AuthProvider";
import { useParams } from "react-router";
import { ObjectID } from "bson";
import { useFindOne_OneShot } from "../db/hooks";

import Item from "./Item";
const ItemContainer = () => {
  const { id } = useParams();

  const [item, isLoading] = useFindOne_OneShot(
    "items",
    useMemo(() => ({ _id: new ObjectID(id) }), [id]),
    "Item"
  );
  const { user } = useAuth();
  if (isLoading) {
    return <h1>loading</h1>;
  }
  return <Item item={item} user={user} />;
};

export default ItemContainer;
