import React, { useMemo } from "react";
import { useNavbarContext } from "./providers/NavbarContextProvider";
import { getNavigations } from "./utils/navigations";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { useMessages } from "./providers/MessageProvider";
import { useAuth } from "./providers/AuthProvider";
import { useFindOne_OneShot } from "./db/hooks";
import { useLocation } from "react-router-dom";
import { ObjectID } from "bson";
import { useParams } from "react-router-dom";
import LoadingFullscreen from "./components/LoadingFullscreen";
import { AppProvider } from "./providers/AppProvider";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { theme } from "./theme/theme";
import SignInOrUp from "./views/SignInOrUp";
import PageNotFound from "./views/PageNotFound";
import ConfirmEmailUp from "./views/ConfirmEmail";
import PrivacyPolicy from "./views/PrivacyPolicy";
import { AuthProvider } from "./providers/AuthProvider";
// import { Dashboard } from "./views/Dashboard";
import CssBaseline from "@mui/material/CssBaseline";
import AddItem from "./views/AddItem";
import { DbProvider } from "./providers/DbProvider";
import UserProfile from "./views/UserProfile";
import ListItems from "./views/ListItems";
import { Hidden, LinearProgress } from "@mui/material";
// import CreateCar from "./views/CreateCar";
import { NavbarContextProvider } from "./providers/NavbarContextProvider";
import PropTypes from "prop-types";
import MyItems from "./views/MyItems";
import { GeoLocationFromIpProvider } from "./providers/GeoLocationFromIpProvider";
import { MessageProvider } from "./providers/MessageProvider";
import Navbar from "./components/Navbar";
import NavbarBottom from "./components/NavbarBottom";
import ReportAd from "./views/ReportAd";
import { LoadingContextProvider, useIsLoading } from "./providers/LoadingProvider";
import AddVehicle from "./views/AddVehicle";
import ModalContainer from "react-modal-promise";
import { ModalProvider } from "./providers/ModalProvider";
import ModalProviderRenderer from "./components/modals/ModalProviderRenderer";
import ErrorBoundaryWrapped from "./components/ErrorBoundaryWrapped";
import ItemContainer from "./views/ItemContainer";
import ShowVehicle from "./views/ShowVehicle";
import LostPassword from "./views/LostPassword";
import ResetPassword from "./views/ResetPassword";
import { useSavedTheme } from "./utils/themeHook";

const ChatViewLazy = React.lazy(() => import("./views/Chat"));

const App = ({ history }) => {
  // Keep the logged in Realm user in local state. This lets the app re-render
  // whenever the current user changes (e.g. logs in or logs out).
  // If a user is logged in, show their details.
  // Otherwise, show the login screen.
  // const { i18n } = useTranslation();
  // i18n.changeLanguage("no");
  const { themeMode, setThemeMode } = useSavedTheme("light");
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme(themeMode)}>
        <CssBaseline />
        <Providers history={history}>
          <NavbarContextProvider>
            <ModalContainer />
            <ModalProviderRenderer />
            <InnerApp setThemeMode={setThemeMode} themeMode={themeMode} />
          </NavbarContextProvider>
        </Providers>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
App.propTypes = { history: PropTypes.object };

const Providers = ({ history, children }) => (
  <ModalProvider>
    <AppProvider>
      <Router history={history}>
        <AuthProvider>
          <GeoLocationFromIpProvider>
            <DbProvider>
              <LoadingContextProvider>
                <MessageProvider>{children}</MessageProvider>
              </LoadingContextProvider>
            </DbProvider>
          </GeoLocationFromIpProvider>
        </AuthProvider>
      </Router>
    </AppProvider>
  </ModalProvider>
);

Providers.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object,
};

const InnerApp = ({ setThemeMode, themeMode }) => {
  const isLoading = useIsLoading();
  const { navbarContext } = useNavbarContext();
  const { unreadMessageCount } = useMessages();
  const { logout, isLoggedIn } = useAuth();
  let routes = useMemo(
    () => getNavigations(isLoggedIn, unreadMessageCount, logout, setThemeMode, themeMode),
    [isLoggedIn, logout, unreadMessageCount, setThemeMode, themeMode]
  );
  routes = useMemo(() => navbarContext.extraNavigations.concat(routes), [routes, navbarContext.extraNavigations]);
  return (
    <div style={{ height: "100vh", flexDirection: "column", display: "flex" }}>
      <div style={{ order: 1, flexShrink: 1, minWidth: 0, padding: 1 }}>
        <Hidden mdUp>
          <NavbarBottom routes={routes} />
        </Hidden>
      </div>
      <Hidden mdDown>
        <Navbar routes={routes} />
      </Hidden>
      {/* <Navbar /> */}
      <div style={{ position: "relative" }}>
        {isLoading && <LinearProgress style={{ position: "absolute", width: "100%" }} color="secondary" />}
      </div>
      <div style={{ overflow: "auto", position: "relative", flexGrow: 1 }}>
        <RootRoutes />
      </div>
    </div>
  );
};
InnerApp.propTypes = {
  setThemeMode: PropTypes.func,
  themeMode: PropTypes.string,
};
const EditItem = () => {
  const { id } = useParams();
  const [item, isLoading] = useFindOne_OneShot(
    "items",
    useMemo(() => ({ _id: new ObjectID(id) }), [id]),
    "EditItem"
  );
  if (isLoading) {
    return <LoadingFullscreen>Loading item</LoadingFullscreen>;
  }
  return <AddItem item={item} />;
};
const RenderOrSendToLogin = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn } = useAuth();
  if (isLoggedIn) {
    return children;
  }
  // fromUrl is used by authProvider to redirect to page you wanted to go to after login
  return <Redirect to={{ pathname: "/signin", state: { fromUrl: location.pathname } }} />;
};
RenderOrSendToLogin.propTypes = {
  children: PropTypes.node,
};
const WrappedRoute = ({ path, exact, children }) => {
  return (
    <Route path={path} exact={exact}>
      <ErrorBoundaryWrapped description={path}>{children}</ErrorBoundaryWrapped>
    </Route>
  );
};
WrappedRoute.propTypes = {
  path: PropTypes.string,
  exact: PropTypes.bool,
  children: PropTypes.node,
};
// loginNormal("joe.jasper@example.com", "passw0rd").then((user) => {
//   console.log("Successfully logged in!", user);
// });
const RootRoutes = () => {
  // TODO: list and view should be able to view unauthenticated
  // TODO: When you login, it should remember what route you were on before
  // and send you there after you have logged in

  return (
    <Switch>
      <WrappedRoute path="/chat">
        <RenderOrSendToLogin>
          <React.Suspense fallback={<LoadingFullscreen />}>
            <ChatViewLazy />
          </React.Suspense>
        </RenderOrSendToLogin>
      </WrappedRoute>
      <WrappedRoute exact path="/item/:id">
        <ItemContainer />
      </WrappedRoute>
      <WrappedRoute exact path="/reportad/:id">
        <ReportAd />
      </WrappedRoute>
      <WrappedRoute exact path="/new-vehicle">
        <RenderOrSendToLogin>
          <AddVehicle />
        </RenderOrSendToLogin>
      </WrappedRoute>
      <WrappedRoute exact path="/profile">
        <RenderOrSendToLogin>
          <UserProfile />
        </RenderOrSendToLogin>
      </WrappedRoute>
      <WrappedRoute exact path="/edit-item/:id">
        <RenderOrSendToLogin>
          <EditItem />
        </RenderOrSendToLogin>
      </WrappedRoute>
      <WrappedRoute exact path="/new-item">
        <RenderOrSendToLogin>
          <AddItem />
        </RenderOrSendToLogin>
      </WrappedRoute>
      <WrappedRoute exact path="/signin">
        <SignInOrUp type={"in"} />
      </WrappedRoute>
      <WrappedRoute exact path="/signup">
        <SignInOrUp type={"up"} />
      </WrappedRoute>
      <WrappedRoute exact path="/signinup">
        <SignInOrUp type={"in"} />
      </WrappedRoute>
      <WrappedRoute exact path="/lost-password">
        <LostPassword />
      </WrappedRoute>
      <WrappedRoute exact path="/reset-password">
        <ResetPassword />
      </WrappedRoute>
      <WrappedRoute exact path="/confirm-email">
        <ConfirmEmailUp />
      </WrappedRoute>
      <WrappedRoute exact path="/not-found">
        <PageNotFound />
      </WrappedRoute>
      <WrappedRoute exact path="/privacy-terms">
        <PrivacyPolicy />
      </WrappedRoute>
      <WrappedRoute exact path="/">
        <Redirect to="/list-items" />
        {/* <Home /> */}
      </WrappedRoute>
      <WrappedRoute exact path="/list-items">
        <ListItems />
      </WrappedRoute>
      <WrappedRoute exact path="/my-items">
        <MyItems />
      </WrappedRoute>
      <WrappedRoute exact path="/showcar">
        <ShowVehicle />
      </WrappedRoute>
      <WrappedRoute exact path="/showmc">
        <MyItems />
      </WrappedRoute>
      <WrappedRoute exact path="/showtruck">
        <MyItems />
      </WrappedRoute>
      <WrappedRoute exact path="/showboat">
        <MyItems />
      </WrappedRoute>
      <WrappedRoute exact path="/dev">
        <p>Hello</p>
      </WrappedRoute>
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default App;
