import React, { useMemo } from "react";
import { useAuth } from "./AuthProvider";

const DbContext = React.createContext();

const DbProvider = (props) => {
  const { user } = useAuth();
  const db = useMemo(() => {
    const dbClient = user.mongoClient("mongodb-atlas");
    return dbClient.db("databay");
  }, [user]);

  return <DbContext.Provider value={db} {...props} />;
};

const useDb = () => React.useContext(DbContext);
export { DbProvider, useDb };
