import React, { useCallback, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import { useNavbarContext } from "../providers/NavbarContextProvider";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  drawer: {
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
    },
    height: "100%",
  },
  drawerPaper: {
    position: "relative",
    height: "100%",
  },
}));

const FilterDrawer = ({ children }) => {
  const classes = useStyles();
  const { setNavbarContext } = useNavbarContext();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const drawerClose = useCallback(() => setMobileOpen(false), []);
  const drawerOpen = useCallback(() => setMobileOpen(true), []);

  useEffect(() => {
    setNavbarContext({
      extraNavigations: [
        {
          mobileOnly: true,
          name: "Filters",
          icon: <MenuIcon />,
          route: drawerOpen,
        },
      ],
    });
    return () => setNavbarContext({ extraNavigations: [] });
  }, [classes.menuButton, drawerOpen, setNavbarContext]);
  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={drawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <IconButton style={{ width: "100%" }} onClick={drawerClose} size="large">
            <CloseIcon />
          </IconButton>
          {children}
          <Divider />
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          style={{ height: "100%" }}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {children}
        </Drawer>
      </Hidden>
    </nav>
  );
};
FilterDrawer.propTypes = {
  children: PropTypes.node,
};

export default FilterDrawer;
