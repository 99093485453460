import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import { useAuth } from "../providers/AuthProvider";
import Alert from "@mui/material/Alert";
import FacebookIcon from "@mui/icons-material/Facebook";
import googleIcon from "../assets/google.svg";

const LoginGoogle = () => {
  const { loginWithGoogle } = useAuth();
  const [errorMsg, setErrorMsg] = useState("");
  return (
    <React.Fragment>
      <GoogleLogin
        clientId="966583361913-di2bn44naeebj22dion950e263pf69r8.apps.googleusercontent.com"
        buttonText="Login with google"
        onSuccess={(response) => loginWithGoogle(response.tokenId)}
        onFailure={(r) => setErrorMsg(r)}
        responseType="id_token"
        cookiePolicy={"single_host_origin"}
        render={(renderProps) => (
          <Button
            fullWidth
            variant="outlined"
            sx={{ color: "common.gray1", borderColor: "", mt: 3, mb: 2, textTransform: "none" }}
            startIcon={<Avatar src={googleIcon} sx={{ width: 24, height: 24 }} />}
            {...renderProps}
          >
            Continue with Google
          </Button>
        )}
      />
      {errorMsg && <Alert severity="error">{JSON.stringify(errorMsg)}</Alert>}
    </React.Fragment>
  );
};

const LoginFacebook = () => {
  const { loginFacebook } = useAuth();
  const [errorMsg, setErrorMsg] = useState("");
  const responseFacebook = (response) => {
    loginFacebook(response.accessToken);
  };
  return (
    <>
      <FacebookLogin
        appId="267726038260759"
        // autoLoad={true}
        fields="name,email,picture"
        // onClick={componentClicked}
        callback={responseFacebook}
        onFailure={(r) => setErrorMsg(r)}
        render={(renderProps) => (
          <Button
            fullWidth
            variant="contained"
            sx={{ colro: "white", bgcolor: "#3b5998", mt: 3, mb: 2, textTransform: "none" }}
            startIcon={<FacebookIcon />}
            {...renderProps}
          >
            Continue using facebook
          </Button>
        )}
      />
      {errorMsg && <Alert severity="error">{JSON.stringify(errorMsg)}</Alert>}
    </>
  );
};
export default function GoogleFacebookLogin() {
  return (
    <Box sx={{ mt: 1 }}>
      <LoginGoogle />
      <LoginFacebook />
    </Box>
  );
}
