import React, { useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { Grid, Paper, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FavouriteList from "./FavouriteList";

const NewListForm = ({ addFavoriteListName, onClose }) => {
  const [newListName, setNewListName] = useState("");
  const [newListNameHelper, setNewListNameHelper] = useState("");
  const onChange = (event) => {
    let valid1;
    switch (event.target.id) {
      case "newListName":
        setNewListName(event.target.value);
        valid1 = /^\w+([.-]?\w+)*$/.test(event.target.value);

        if (!valid1) {
          setNewListNameHelper("Only Characters ");
        } else {
          setNewListNameHelper("");
        }
        break;
      default:
        break;
    }
  };
  const onClickAdd = (e) => {
    e.preventDefault();
    addFavoriteListName(newListName);
    onClose();
  };
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item style={{ width: "23em" }}>
        <span id="newListNameSpan">
          <TextField
            label="New List name"
            variant="outlined"
            error={newListNameHelper.length !== 0}
            helperText={newListNameHelper}
            id="newListName"
            fullWidth
            value={newListName}
            onChange={onChange}
          />
        </span>
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          size="large"
          startIcon={<DoneIcon />}
          onClick={onClickAdd}
          disabled={newListName.length === 0 || newListNameHelper.length !== 0}
        >
          Add
        </Button>
      </Grid>
      <Grid item>
        <IconButton
          variant="contained"
          color="secondary"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
NewListForm.propTypes = { addFavoriteListName: PropTypes.func.isRequired, onClose: PropTypes.func.isRequired };

export default function AddToCollectionModal({ display, closeModal, addFavoriteListName }) {
  const [displayNewListForm, setDisplayNewListForm] = useState(false);

  return (
    <Modal aria-labelledby="confirmation-dialog-title" open={display} onClose={closeModal}>
      <Paper sx={{ width: "50ch", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <DialogTitle id="confirmation-dialog-title">
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Save to</Typography>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                size="large"
                startIcon={<AddIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  setDisplayNewListForm(true);
                }}
              >
                New List
              </Button>
            </Grid>
          </Grid>
          {displayNewListForm && (
            <NewListForm addFavoriteListName={addFavoriteListName} onClose={() => setDisplayNewListForm(false)} />
          )}
        </DialogTitle>
        <DialogContent dividers id="back-to-top-anchor">
          <FavouriteList addFavoriteListName={addFavoriteListName} closeModal={closeModal} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<ClearOutlinedIcon />}
            onClick={(e) => {
              e.preventDefault();
              setDisplayNewListForm(false);
              closeModal();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
}

AddToCollectionModal.propTypes = {
  display: PropTypes.bool,
  closeModal: PropTypes.func,
  addFavoriteListName: PropTypes.func,
};
