import React from "react";
import { Link as RouterLink } from "react-router-dom";
import MaterialLink from "@mui/material/Link";
import { useRouteMatch } from "react-router-dom";
import { PropTypes } from "prop-types";

const Link = (props) => {
  const moduleMatch = useRouteMatch({
    path: props?.to,
    exact: props?.to?.length === 1,
  });
  // WHY COLOR NOT WORKING?!?!?!?
  return (
    <MaterialLink color={moduleMatch ? "primary" : "secondary"} underline="hover" component={RouterLink} {...props} />
  );
};

export default Link;
Link.propTypes = {
  to: PropTypes.string,
  length: PropTypes.number,
};
