import { ObjectID } from "bson";

const GLOBAL_CACHE_PREFIX = "$#LOL:";

const mkKey = (k) => `${GLOBAL_CACHE_PREFIX}::${k}`;

export const saveObjToLocalStorage = (key, obj) => localStorage.setItem(mkKey(key), JSON.stringify(obj));
export const getObjFromLocalStorage = (key) => {
  const x = JSON.parse(localStorage.getItem(mkKey(key)));
  if (x) {
    return {
      ...x,
      payload: x.payload.map((o) => ({ ...o, _id: new ObjectID(o._id) })),
    };
  }
  return null;
};

export const deleteObjWithKeyPrefix = (keyPrefix) => {
  const _keyPrefix = mkKey(keyPrefix);
  for (let key in localStorage) {
    if (key.startsWith(_keyPrefix)) {
      localStorage.removeItem(key);
    }
  }
};
