import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`signinup-tabpanel-${index}`}
      aria-labelledby={`signinup-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `signinup-tab-${index}`,
    "aria-controls": `signinup-tabpanel-${index}`,
  };
}

export default function SignInOrUp(props) {
  const [value, setValue] = React.useState(props.type === "in" ? 0 : 1);

  const handleChange = (event, newValue) => setValue(newValue);

  const changeType = (type) => {
    type === "in" ? setValue(0) : setValue(1);
  };

  return (
    <Container>
      <Paper
        sx={{
          pt: 5,
          mt: 5,
          alignItems: "center",
        }}
      >
        <Grid item container justifyContent="center" alignItems="left">
          <Grid item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "left",
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} aria-label="sign panel tab">
                  <Tab label="Sign In" {...a11yProps(0)} />
                  <Tab label="Create New User" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <TabPanel value={value} index={0}>
            <SignIn changeType={changeType} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SignUp changeType={changeType} />
          </TabPanel>
        </Grid>
      </Paper>
    </Container>
  );
}
SignInOrUp.propTypes = {
  type: PropTypes.oneOf(["in", "up"]),
};
