import React, { useCallback } from "react";
import ErrorView from "../views/ErrorView";
import { useModalProvider } from "../providers/ModalProvider";
import { captureException } from "@sentry/react";
import SuccessView from "../views/SuccessView";

export const useModal = () => {
  const { doModalAction } = useModalProvider();
  const showError = useCallback(
    (description, error) => {
      doModalAction({ type: "append", payload: <ErrorView description={description} error={error} /> });
      captureException(error);
    },
    [doModalAction]
  );
  const showSuccess = useCallback(
    (title, description) => {
      doModalAction({ type: "append", payload: <SuccessView description={description} title={title} /> });
    },
    [doModalAction]
  );
  return { showError, showSuccess };
};
