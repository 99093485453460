import { PropTypes } from "prop-types";
import React from "react";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";

const SuccessView = ({ title, description }) => {
  return (
    <Paper>
      <Alert severity="success">
        <h4>{title}</h4>
        <p>{description}</p>
      </Alert>
    </Paper>
  );
};
SuccessView.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SuccessView;
