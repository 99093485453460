import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { boatPropTypes } from "../../../utils/sharedPropTypes";

const Boat = ({ plateNumber, vin }) => {
  return (
    <Grid item container direction="row" justifyContent="left" alignItems="center" spacing={3}>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
            Plate Number
          </Typography>
        </Grid>
        <Grid item>{plateNumber}</Grid>
      </Grid>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 100 }} color="primary">
            Chasiss NUmber (VIN)
          </Typography>
        </Grid>
        <Grid item>{vin}</Grid>
      </Grid>
    </Grid>
  );
};
export default Boat;

Boat.propTypes = {
  ...boatPropTypes,
};

//  export const boatPropTypes = {
//   registrationNumber: PropTypes.string, //The boat's identification mark in the Small Boat or Ship Register.
//   boatType: PropTypes.oneOf([
//     "bowrider",
//     "sailboats",
//     "openboats",
//     "speedboat",
//     "snekke",
//     "waterjet",
//     "cabincruiser",
//     "daycruiser",
//     "dinghy",
//     "pilothouse",
//     "rib",
//     "yacht",
//     "commercialvessel",
//     "other",
//   ]),
//   makeYear: PropTypes.number, // Four digit year
//   boatBrand: PropTypes.oneOf([
//     "ab inflatables",
//     "ABYACHT",
//     "aga",
//     "al",
//     "amt",
//     "absolute",
//     "accent",
//     "achilles",
//     "adrenaline",
//     "adriatic",
//     "adventurer",
//     "Aermarine",
//     "agder",
//     "aicon",
//     "aironmarine",
//     "airship",
//     "airship ribs",
//     "akerø",
//     "albatross",
//     "albin",
//     "alfa",
//     "aliboats",
//     "alicraft",
//     "Alloycraft",
//     "allures",
//     "Almarcraft",
//     "alo",
//     "alphaboat",
//     "ALUMARIN",
//     "AluVenture",
//     "alubat",
//     "alucraft",
//     "alufish",
//     "alukin",
//     "Alunaut",
//     "Alunautic",
//     "alutec",
//     "ancas queen",
//     "andre",
//     "andunge",
//     "andy",
//     "Antaris",
//     "anytec",
//     "aphrodite",
//     "apollo",
//     "Aqua",
//     "aquador",
//     "aquamar",
//     "aquamarine",
//     "aquapro",
//     "aquaquad",
//     "aquaquick",
//     "AQUARIUS",
//     "AQUASPIRIT",
//     "aquatron",
//     "arcona",
//     "arctic",
//     "artic blue",
//     "ardea",
//     "arendalsnekke",
//     "argo",
//     "arimar",
//     "arksund",
//     "armor",
//     "arronet",
//     "Artekno",
//     "artic",
//     "arvor",
//     "askeladden",
//     "ASPEN",
//     "astondoa",
//     "athena",
//     "athena 38",
//     "atlantic",
//     "atlantic marine",
//     "atlantis",
//     "atomix",
//     "Australian high performance",
//     "avance",
//     "avanti",
//     "aventura",
//     "avon",
//     "awtish",
//     "axopar",
//     "azimut",
//     "azure",
//     "azzurro",
//     "baja",
//     "bb",
//     "bic",
//     "BMB",
//     "bowi serai",
//     "brig",
//     "brp",
//     "bwa",
//     "bakke",
//     "ballistic",
//     "balt",
//     "baltic",
//     "bandholm",
//     "banner",
//     "barcon",
//     "baretta",
//     "barracuda",
//     "bavaria",
//     "bayliner",
//     "Becker",
//     "BELASSI",
//     "bell",
//     "bella",
//     "hsr-benelli",
//     "beneteau",
//     "Bennington",
//     "Berika",
//     "BERKUT",
//     "bertram",
//     "BestMarin",
//     "bestyear",
//     "bever",
//     "biam",
//     "bianca",
//     "birchwood",
//     "black fin",
//     "BLACKWATER",
//     "blesser",
//     "Blue Spirit",
//     "Bluesea",
//     "BLUSAIL",
//     "boheme",
//     "bolero",
//     "bombard",
//     "bonanza",
//     "boston whaler",
//     "bostrøm",
//     "bowrider",
//     "BRABUS-MARINE",
//     "Braveboat",
//     "Brenta",
//     "brioni",
//     "brizo_yachts",
//     "broom",
//     "bryant",
//     "bryza",
//     "bumerang",
//     "buster",
//     "båtsmann",
//     "candc",
//     "capferret",
//     "cb",
//     "cde",
//     "cr",
//     "crn",
//     "CALYPSO",
//     "camoline",
//     "campion",
//     "canmar",
//     "cantieridisarnico",
//     "capelli",
//     "carat",
//     "caravelle",
//     "caribe",
//     "carver",
//     "castello marine",
//     "Catalina",
//     "cayenne",
//     "cenit",
//     "century",
//     "challenger",
//     "champion",
//     "chaparral",
//     "checkmate",
//     "chris craft",
//     "cigarette",
//     "ciljan",
//     "cleopatra",
//     "clipper",
//     "coast liner",
//     "coastmaster",
//     "coaster",
//     "cobalt",
//     "cobra",
//     "cobrey",
//     "colin archer",
//     "colombo",
//     "comet",
//     "comfort",
//     "comfortina",
//     "COMPASS",
//     "concord",
//     "contessa",
//     "contest",
//     "contrast",
//     "cooper",
//     "cormate",
//     "coronet",
//     "coverline",
//     "cranchi",
//     "crescent",
//     "crownline",
//     "cruiser",
//     "cruisers",
//     "custom line",
//     "cutwater",
//     "cyber 370",
//     "dacapo",
//     "dallapieta",
//     "DATELINE",
//     "DEFEVER",
//     "Degerø",
//     "dehler",
//     "Dell Quay Dory",
//     "delphia",
//     "delta",
//     "derubisyachts",
//     "DIANA",
//     "DISCOVERY",
//     "diva",
//     "dixie",
//     "dolmøy",
//     "Dolvik",
//     "dominant",
//     "donzi",
//     "doral",
//     "drabant",
//     "draco",
//     "drago",
//     "dragonfly",
//     "drake",
//     "drive",
//     "dromedille",
//     "dsp",
//     "dufour",
//     "duramar",
//     "dynamic",
//     "ebbtide",
//     "edge water",
//     "eikeli",
//     "elan",
//     "elling",
//     "elwaro",
//     "emili",
//     "endourance",
//     "enes",
//     "eolo",
//     "epoca",
//     "etap",
//     "euro yacht",
//     "EUROCRAFT",
//     "europajolle",
//     "EUROSPORT",
//     "evada",
//     "EXCEDO",
//     "excess_catamarans",
//     "explorer",
//     "fm",
//     "fun",
//     "fabola",
//     "faeton",
//     "fagitta",
//     "fairline",
//     "falcon",
//     "Fantasi",
//     "farr",
//     "fast cab",
//     "faster",
//     "faurby",
//     "feeling",
//     "FEMUNDBOAT",
//     "fenix",
//     "ferretti",
//     "fevik",
//     "fiart",
//     "finessa",
//     "finncamp",
//     "finngulf",
//     "finnmaster",
//     "finnsailer",
//     "finnsport",
//     "firling",
//     "first",
//     "first class 8",
//     "fiskeskøyte",
//     "fjord",
//     "fjord terne",
//     "fiordboats",
//     "fjordling",
//     "flem",
//     "FLEMING",
//     "fletcher",
//     "flipper",
//     "folla",
//     "forbina",
//     "forgus",
//     "Formosa",
//     "formula",
//     "formula watercraft",
//     "fortego",
//     "fountain",
//     "Fountaine Pajot",
//     "fourwinns",
//     "fram",
//     "FRAUSCHER",
//     "freedom",
//     "freeman",
//     "FRERS_AND_CIBIL",
//     "friendship",
//     "fun-yak",
//     "furuholmen",
//     "færing",
//     "gh",
//     "GJ",
//     "GJ690",
//     "gp",
//     "grp",
//     "GALA",
//     "galeon",
//     "galia",
//     "gambling",
//     "gamler",
//     "gecco",
//     "gemi",
//     "Gemini",
//     "gib&#39;sea",
//     "gibbs",
//     "gilbert",
//     "gimle",
//     "gjeving",
//     "gladiator",
//     "glastron",
//     "GLISERIS",
//     "gobbi",
//     "goldfish",
//     "gozzi",
//     "grady white",
//     "granada",
//     "grand",
//     "grand banks",
//     "grand soleil",
//     "grandzessa",
//     "grandezza",
//     "greatline",
//     "greenline",
//     "GRIMSTADJOLLE",
//     "grimsoy",
//     "grinde",
//     "GRIZZLY",
//     "gromling",
//     "Guernsey",
//     "gulfcraft",
//     "guy",
//     "h-båt",
//     "hb",
//     "hr",
//     "hrs-benelli",
//     "halco",
//     "hallberg rassy",
//     "hankø terna",
//     "hanse",
//     "hansvik",
//     "hanto",
//     "happy",
//     "hardanger",
//     "hardangersnekke",
//     "harmony",
//     "hasla",
//     "hasle",
//     "hasle summer fun",
//     "HASTIG",
//     "havboat",
//     "hawk",
//     "HELLEFISKEREN",
//     "hero",
//     "herva",
//     "herwa",
//     "HeyDay",
//     "highperformance",
//     "highfield",
//     "hitra",
//     "hobby",
//     "hobiecat",
//     "honda",
//     "hortensnekka",
//     "hudson",
//     "hui",
//     "humber",
//     "hunter",
//     "hurrycane",
//     "husky",
//     "hutting",
//     "hydrolift",
//     "hydromarine",
//     "Hårek",
//     "if",
//     "IOD Princess",
//     "IRON Boats",
//     "isy",
//     "ITAMA",
//     "ibiza",
//     "ideal",
//     "idylla",
//     "impala",
//     "in lander",
//     "infra",
//     "integrity",
//     "inter",
//     "Invictus",
//     "irene",
//     "isis",
//     "italmar",
//     "jcraft",
//     "j-boats",
//     "jaguar",
//     "janmor",
//     "jansentry",
//     "JARRACK",
//     "jeanneau",
//     "jeppe",
//     "joda",
//     "johnson",
//     "joker",
//     "jolly",
//     "junker",
//     "jupiter",
//     "jypling",
//     "kb snekke",
//     "kmv",
//     "kaasbøll",
//     "karmoy",
//     "karnic",
//     "KAVALER",
//     "kawasaki",
//     "kayot",
//     "kelt",
//     "killing",
//     "kimple",
//     "kloster",
//     "knarr",
//     "korsfjord",
//     "korso",
//     "kragerø",
//     "kral",
//     "Krash",
//     "krona",
//     "Kulla",
//     "kværnø",
//     "lm",
//     "ln",
//     "lagoon",
//     "laguna",
//     "Lami",
//     "larsen",
//     "larson",
//     "laser",
//     "lasse",
//     "Lema",
//     "LEXTOR_MARIN",
//     "libra",
//     "lima",
//     "Limbo",
//     "limestone",
//     "linder",
//     "linjett",
//     "linssen",
//     "lodestar",
//     "lomac",
//     "los",
//     "luna",
//     "lyngør",
//     "lærling",
//     "M_SPORT",
//     "MAR_CO",
//     "msboat",
//     "mtx",
//     "mv",
//     "mvyachts",
//     "mab",
//     "mabi yachts",
//     "macgregor",
//     "maestral",
//     "maestro",
//     "magnum",
//     "mainship",
//     "majesty",
//     "mako",
//     "malibu",
//     "malo",
//     "malø yachts",
//     "mamba",
//     "mantra",
//     "marex",
//     "mariah",
//     "marieholm",
//     "Maril",
//     "marina",
//     "MARINE TECHNOLOGY INC",
//     "mariner",
//     "marino",
//     "Marion",
//     "Maritimo",
//     "marlin",
//     "marquisyachts",
//     "mascot",
//     "master",
//     "mastercraft",
//     "matrix boats",
//     "maxi",
//     "maxum",
//     "maxus",
//     "mazury",
//     "mekling",
//     "melges",
//     "menken maritiem",
//     "mercury",
//     "meridian yachts",
//     "mery nautic",
//     "micora",
//     "mikkel",
//     "milia",
//     "millenium",
//     "minor",
//     "mirage",
//     "mixer",
//     "modesty",
//     "modius",
//     "monaco",
//     "monark",
//     "monterey",
//     "moody",
//     "morgan",
//     "mosquito",
//     "motiva",
//     "motorscoop",
//     "musling",
//     "mustang",
//     "myra",
//     "maløy",
//     "møn",
//     "mørebas",
//     "mørejet",
//     "nb",
//     "neel",
//     "NF",
//     "nabcat",
//     "nabwork",
//     "najad",
//     "narwhal",
//     "nauticat",
//     "nautico",
//     "nautor",
//     "NAUTUS",
//     "naver",
//     "navette",
//     "neptim",
//     "neptunkrysser",
//     "neptunus",
//     "nessy",
//     "NEWCLASSIC",
//     "nicholson",
//     "nidelv",
//     "nilsen",
//     "nimbus",
//     "nimo",
//     "nisamirib",
//     "Nitra",
//     "nor sea",
//     "nor star",
//     "nor-dan",
//     "nor-tech",
//     "norplast",
//     "Nortech",
//     "Noratlantis",
//     "norbas",
//     "norbelle",
//     "norbåt",
//     "norcraft",
//     "nord west",
//     "nordstar",
//     "nordic marine",
//     "nordic",
//     "nordic star",
//     "nordis folkebat",
//     "nordkapp",
//     "nordship",
//     "norfun",
//     "norlin",
//     "norline",
//     "norman",
//     "Norrib",
//     "norsafe",
//     "northatlanticrib",
//     "North Pacific",
//     "north ribs",
//     "northstar",
//     "NorthMaster",
//     "nova",
//     "NOVURANIA",
//     "nuovajolly",
//     "nymar",
//     "OX",
//     "obsession",
//     "ocean alexander",
//     "oceanmarin",
//     "oceancraft",
//     "oceanfarer",
//     "oceanis",
//     "ocean master",
//     "oceanrunner",
//     "ockelbo",
//     "ocqueteau",
//     "olson 88",
//     "olympic",
//     "ombrine",
//     "omega",
//     "optima",
//     "orrskär",
//     "oselvar",
//     "Otasport",
//     "oyster",
//     "pakri",
//     "paragon",
//     "Paramount Powerboats",
//     "parent",
//     "partizan",
//     "Pearlsea",
//     "PEGAZUS",
//     "pershing",
//     "pettersson",
//     "piet hein sloep",
//     "pilot",
//     "pioner",
//     "plattgatter",
//     "pogo",
//     "polar",
//     "polarcirkel",
//     "polaris",
//     "polarkraft",
//     "polo",
//     "poseidon",
//     "powerquest",
//     "powercraft",
//     "predator",
//     "prestige",
//     "princess",
//     "pro rib",
//     "pro-line",
//     "PROWORKER",
//     "pronavia",
//     "protector",
//     "puma",
//     "pursuit",
//     "quicksilver",
//     "ris",
//     "rm_yachts",
//     "racemaster",
//     "racingsailboats",
//     "ram-marine",
//     "ramin",
//     "ran craft",
//     "rana",
//     "rand",
//     "ranger",
//     "ranger tugs",
//     "ranieri",
//     "rascala",
//     "rasmus",
//     "ravage",
//     "real",
//     "REALCRAFT",
//     "regal",
//     "reinell",
//     "REMUS",
//     "revenger",
//     "rheamarine",
//     "riblimited",
//     "ribcraft",
//     "ribeye",
//     "Ridas",
//     "rinker",
//     "risør",
//     "riva",
//     "rival",
//     "rivena yachts",
//     "river",
//     "riviera",
//     "robalo",
//     "rodman",
//     "rondo",
//     "royalcruiser",
//     "royalmarine",
//     "runn37",
//     "rupert",
//     "rush",
//     "ryds",
//     "sacs",
//     "Seacore",
//     "SF_MARITIM",
//     "sk",
//     "sl",
//     "SPX RIB",
//     "sun",
//     "SAFIR",
//     "Safter",
//     "saga",
//     "SAGEN",
//     "sagitta",
//     "salman theri",
//     "salona",
//     "salpa",
//     "sand",
//     "sandström",
//     "sandstø",
//     "Sandtangen",
//     "sandvik",
//     "sandøy",
//     "sargo",
//     "saver",
//     "SAXDOR",
//     "saxe",
//     "scampi",
//     "scand",
//     "scandica",
//     "SCANDINAVAL",
//     "scandinavien",
//     "scanmar",
//     "scanner",
//     "scarab",
//     "scarani",
//     "schaefer yachts",
//     "scorpion",
//     "sea finn",
//     "seaforceone",
//     "sea hawk",
//     "sea jeep",
//     "sea master",
//     "seapro",
//     "sea queen",
//     "sea ray",
//     "seastar",
//     "SEABOY",
//     "seadan",
//     "seadoo",
//     "seahunter",
//     "searider",
//     "seawave",
//     "seabird",
//     "seabraker",
//     "seaco",
//     "seacraft",
//     "seaking",
//     "Seaking Dominator",
//     "sealegs",
//     "sealine",
//     "sealver",
//     "seamarin",
//     "seaqrest",
//     "seaside",
//     "SEASPIRIT",
//     "seaswirl",
//     "seaviking",
//     "seawings",
//     "seigur",
//     "selby",
//     "selco",
//     "selfa",
//     "selmar",
//     "selva",
//     "Serecraft",
//     "sessa",
//     "shakespeare",
//     "sheriff",
//     "shimano",
//     "shipman",
//     "sigma",
//     "sillinger",
//     "silver",
//     "silver viking",
//     "silverton",
//     "SIMMS",
//     "simppu",
//     "sirena",
//     "sisumar",
//     "sjark",
//     "sjomann",
//     "SJOTROLL",
//     "skaar",
//     "skager",
//     "skagerak",
//     "skarpnes",
//     "skarsvaag",
//     "skater",
//     "skibsplast",
//     "skilsø",
//     "Skipper",
//     "skipperyachts",
//     "skorgenes",
//     "skøyte",
//     "smart rib",
//     "smartliner",
//     "smiling",
//     "smuggler",
//     "snipe",
//     "solaris",
//     "soling",
//     "sollux",
//     "spornes",
//     "sportique",
//     "sporty",
//     "sprint",
//     "starcraft",
//     "starfisher",
//     "statesman",
//     "steady",
//     "STIGFJORD",
//     "sting",
//     "stingher",
//     "stingray",
//     "storebro",
//     "storm",
//     "stormway",
//     "Stormy",
//     "storø",
//     "strandebarmer",
//     "straholmen",
//     "Strømøy Rib",
//     "style",
//     "sugarsand",
//     "summer dreams",
//     "summer family",
//     "summersport",
//     "sun fast",
//     "sun marine",
//     "sun odyssey",
//     "sun quest",
//     "sun tracker",
//     "sunbeam",
//     "sunbird",
//     "sunchaser",
//     "sunline",
//     "sunrider",
//     "sunsation",
//     "sunseeker",
//     "sunwind",
//     "SUOMI",
//     "Sur Marine",
//     "surplus",
//     "suvi",
//     "suzumar",
//     "swan",
//     "swede",
//     "swedestar",
//     "sweden yachts",
//     "sørlandssnekka",
//     "tb",
//     "Teknomar",
//     "tg-boat",
//     "tpmarine",
//     "Tahoe",
//     "TAKACAT",
//     "targa",
//     "tartan",
//     "tayana",
//     "teal",
//     "TECHNOHULL",
//     "TECHNORIB",
//     "terhi",
//     "TETHYS_YACHTS",
//     "tetis",
//     "thunderbird",
//     "tiara",
//     "tiger marine",
//     "tobias",
//     "topaz",
//     "topcat",
//     "tornado",
//     "toy",
//     "tracker",
//     "trader",
//     "trapper",
//     "trawler",
//     "tresfjord",
//     "TRINELLA",
//     "trintella",
//     "trio",
//     "tristan",
//     "triton",
//     "troll",
//     "tromøy",
//     "trophy",
//     "trønder&#39;n",
//     "tullioabbate",
//     "tune-jolle",
//     "TUSTNA",
//     "twostar",
//     "UMSBOATS",
//     "uniesse",
//     "unique",
//     "uttern",
//     "Ventum",
//     "vaagen",
//     "valiant",
//     "Van Der Heijden",
//     "venus",
//     "vermøy",
//     "vernøy",
//     "verøy",
//     "veslefrikk",
//     "vest",
//     "vestfjord",
//     "VIGGO",
//     "vikfjord",
//     "viking",
//     "viknes",
//     "viko",
//     "viksfjord",
//     "viksund",
//     "vindø",
//     "viper",
//     "VIRUS",
//     "vista",
//     "Westplast",
//     "walker bay",
//     "wally",
//     "wasa",
//     "waterfun",
//     "watercraft",
//     "WATERWISH",
//     "wauquiez",
//     "wayfarer",
//     "wellcraft",
//     "wesling",
//     "WEST",
//     "westbas",
//     "westcruiser",
//     "wester",
//     "WESTERLY",
//     "westfjord",
//     "westline",
//     "wharran",
//     "white shark",
//     "wiking",
//     "williams",
//     "willing",
//     "windy",
//     "winga",
//     "winrace",
//     "with",
//     "x-yachts",
//     "xo",
//     "XS Ribs",
//     "XSHORE",
//     "yam",
//     "yamaha",
//     "yamarin",
//     "yngling",
//     "zar",
//     "zebec",
//     "zee marine",
//     "zephyr",
//     "zitarra",
//     "zodiac",
//     "ribtec",
//     "årø",
//     "øien",
//     "ørnvik",
//     "5,5 meter",
//     "8 meter",
//   ]),
//   boatModel: PropTypes.string,
//   enginIncluded: PropTypes.bool,
//   engineType: PropTypes.string,
//   engineBrand: PropTypes.string,
//   enginPorwre: PropTypes.number, //In Hosre Power
//   maxSpeed: PropTypes.number, // in knots (optional)
//   fuel: PropTypes.string,
//   length: PropTypes.number, // in feet
//   width: PropTypes.number, // in cm (optional)
//   depth: PropTypes.number, // in cm (optional)
//   weight: PropTypes.number, // in kg (optional)
//   material: PropTypes.oneOf(["wood", "aluminium", "plastic", "fiberglass", "other"]),
//   color: PropTypes.string,
//   numberOfSeats: PropTypes.number,
//   numberOfBeds: PropTypes.number,
//   lightNumbers: PropTypes.number, // (only for sailboats)
//   equipment: PropTypes.string,
// };
